import { message } from 'antd';
import i18next from 'i18next';

import client from '../api/client';

export interface ExportRequest {
  serviceName: string;
  exportType: 'allRecords' | 'selectedRecords';
  filters: string;
}

const exportRecords = (data: ExportRequest, setSelectedRowKeys?: any) => {
  // console.log('exportRecords = ', data);
  client
    .service('data-export')
    .create(data)
    .then(() => {
      message.info({
        content: i18next.t('exportRecords.ExportMsg'),
        duration: 3,
      });
      if (setSelectedRowKeys) {
        setSelectedRowKeys([]);
      }
    })
    .catch((error: Error) => {
      console.log('Error in exporting data: ', error);
      message.error(i18next.t('exportRecords.ErrExporting'));
    });
};

export default exportRecords;
