import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { arePackagingFiltersEmpty } from '../Packaging/PackagingFilters';

export interface BulkPackagingListFilters {
  searchTerm?: string;
  statuses?: string[];
  unAudited?: boolean;
}
export const areBulkPackagingFiltersEmpty = (
  { searchTerm = '' } = {} as BulkPackagingListFilters,
) => {
  return !searchTerm;
};
interface BulkPackagingFiltersProps {
  filters: BulkPackagingListFilters;
  setFilters: (filters: BulkPackagingListFilters) => void;

  className?: string;
}

const BulkPackagingFilters = ({
  filters,
  setFilters,

  className = 'py-1 mt-1 border-t border-b',
}: BulkPackagingFiltersProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<BulkPackagingListFilters>();
  const clearFilters = () => {
    form.resetFields();
    setFilters({} as BulkPackagingListFilters);
  };

  useEffect(() => {
    form.setFieldsValue(filters);
  }, [filters]);

  return (
    <div className={className}>
      <Form
        form={form}
        onValuesChange={debounce(
          (change) => setFilters({ ...filters, ...change }),
          300,
        )}
        layout="horizontal"
      >
        <Row gutter={8}>
          <Col span={6}>
            <Form.Item className="label-mb-0" name="searchTerm">
              <Input
                placeholder={t('packagingPage.filter.placeholder')}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Select
              mode={'multiple'}
              value={filters.statuses || []}
              placeholder={'Status'}
              onChange={(value: string[]) =>
                setFilters({
                  ...filters,
                  statuses: value,
                })
              }
              style={{ width: '100%' }}
            >
              <Select.Option value="redFlag">Red flag</Select.Option>
              <Select.Option value="draft">Incomplete</Select.Option>
              <Select.Option value="proxy">Proxy</Select.Option>
              <Select.Option value="assumptions">Assumptions</Select.Option>
            </Select>
          </Col>
          <Col span="4">
            <Checkbox
              className="mx-2"
              checked={filters.unAudited}
              onClick={() =>
                setFilters({
                  ...filters,
                  unAudited: !filters.unAudited,
                })
              }
            >
              Hide checked by ZWC
            </Checkbox>
          </Col>
          <Col span={10}>
            <Button
              type="primary"
              className="mr-2"
              onClick={clearFilters}
              disabled={arePackagingFiltersEmpty(filters)}
              danger
            >
              {t('packagingPage.filter.clear')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BulkPackagingFilters;
