import { useQuery } from 'react-query';

import { User } from '../types';
import { findUsers } from './findUsers';
import { UsersHookType } from './UsersHookType';

export function useZWCUsers(options = {}): UsersHookType {
  const { data, ...rest } = useQuery<User[]>(
    ['users', { viewAs: 'zwc' }],
    findUsers,
    {
      staleTime: 5 * 60 * 1000,
      ...options,
    },
  );

  const usersById = (data || []).reduce(
    (a: { [index: string]: User }, c: User) => ({ ...a, [c._id]: c }),
    {} as { [index: string]: User },
  );

  const activeOnly = data?.filter((i) => !i.isArchived);
  const archivedOnly = data?.filter((i) => i.isArchived);

  const getUserById = (id: string) => usersById[id] ?? ({} as User);

  return { ...rest, data, usersById, getUserById, activeOnly, archivedOnly };
}
