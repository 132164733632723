import { PropsWithChildren } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { useActiveReportingPeriod } from '../hooks/useActiveReportingPeriod';
import { useAuth } from '../hooks/useAuth';
import { LoadingPage } from '../pages/reporting-periods/LoadingPage';
import { isClientUser, isMasterUser, switchedMasterAccount } from '../utils';
import { loadViewAs } from './loadViewAs';

function isReportingPeriodURL(path: string) {
  return ['/create-reporting-period', '/reporting-periods'].includes(path);
}

export default function ProtectedRoute({
  component: Component,
  companyOnly,
  ...rest
}: PropsWithChildren<{
  exact?: boolean;
  path: string;
  component: any;
  companyOnly?: boolean;
}>) {
  const auth = useAuth();
  const { hasActiveReportingPeriod } = useActiveReportingPeriod();
  if (auth.isLoading) {
    return <LoadingPage></LoadingPage>;
  }

  const viewAs = loadViewAs();

  if (viewAs) {
    if (rest.path === '/companies') {
      console.log('not allowed to visit companies in viewAs');
      return <Redirect to="/" />;
    }
  }

  if (auth.isLoggedIn) {
    if (companyOnly && !(switchedMasterAccount(auth) || isClientUser(auth))) {
      return <Redirect to={'/'} />;
    }

    if (
      !hasActiveReportingPeriod &&
      !isReportingPeriodURL(rest.path) &&
      ((isMasterUser(auth) && viewAs) || isClientUser(auth))
    ) {
      console.log('redirecting to reporting-periods');
      return <Redirect to="/reporting-periods"></Redirect>;
    }
    return (
      <Route
        {...rest}
        render={(props) => <Component {...rest} {...props} auth={auth} />}
      ></Route>
    );
  }

  return (
    <Route>
      <Redirect to={'/login'}></Redirect>;
    </Route>
  );
}
