import { KeyOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, message } from 'antd';
import { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { UserAccountService } from '../../api/services';
import logo from '../../assets/logo/zwc_logo.svg';
import { useAuth } from '../../hooks/useAuth';

const VerifyAccount = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams<{ userId: string; token: string }>();
  if (auth.isLoggedIn) {
    return <Redirect to="/" />;
  }

  const handleSubmit = (values: { password: string }) => {
    const { userId, token: activationCode } = params;
    const { password } = values;
    if (
      !!userId &&
      !!activationCode &&
      userId !== 'undefined' &&
      activationCode !== 'undefined'
    ) {
      setIsLoading(true);
      UserAccountService.patch(userId, {
        action: 'verifyEmail',
        password,
        activationCode,
      })
        .then(
          (res: any) => {
            message.success('Email verified, redirecting...');
            setTimeout(() => {
              window.location.replace('/login');
            }, 2000);
          },
          (error: Error) => {
            message.error('Error in verifying email');
            console.log('Error in verifying email: ', error);
          },
        )
        .finally(() => setIsLoading(false));
    } else {
      message.error('Invalid link');
    }
  };

  return (
    <div className="relative min-h-screen">
      <Row align="middle" justify="center" style={{ height: '100%' }}>
        <Col span={24}>
          <Row justify="center">
            <Col
              xs={23}
              md={18}
              xl={16}
              style={{ margin: '2rem 0', textAlign: 'center' }}
            >
              <Row align="middle">
                <Col span={4}>
                  <img src={logo} alt="ZWC Logo" width="100px" height="100px" />
                </Col>
                <Col span={20}>
                  <h1
                    className="sm:text-base md:text-2xl lg:text-3xl uppercase font-bold font-sans"
                    style={{ color: '#5d96e9' }}
                  >
                    Mandatory Packaging Reporting Portal
                  </h1>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={20} md={12} xl={7}>
              <div
                className="p-4 rounded text-white my-10"
                style={{
                  backgroundColor: '#6ac7bd',
                  boxShadow:
                    'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px',
                }}
              >
                <h1 className="font-bold text-white text-center text-3xl mb-4">
                  Verify Your Account
                </h1>
                <Form
                  onFinish={handleSubmit}
                  layout="vertical"
                  requiredMark={false}
                >
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: 'Password is required' },
                      {
                        min: 8,
                        message:
                          'Password should be at least 8 characters long',
                      },
                    ]}
                    label={
                      <span className="text-white font-semibold tracking-wider">
                        Password
                      </span>
                    }
                  >
                    <Input.Password
                      placeholder="Password"
                      prefix={<KeyOutlined />}
                    />
                  </Form.Item>
                  <Form.Item
                    name="confirmPassword"
                    dependencies={['password']}
                    label={
                      <span className="text-white font-semibold tracking-wider">
                        Confirm password
                      </span>
                    }
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Passwords do not match');
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      placeholder="Enter password again"
                      prefix={<KeyOutlined />}
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="font-bold uppercase mt-4"
                    loading={isLoading}
                    disabled={isLoading}
                    block
                  >
                    Activate account
                  </Button>
                  <Row justify="center" align="middle">
                    <Col>
                      <Button type="link" href="/login" className="px-0 mt-2">
                        <span className="text-white font-bold text-base">
                          Login
                        </span>
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        style={{
          bottom: '10px',
          position: 'absolute',
          color: '#989898',
          width: '100%',
        }}
        justify="center"
      >
        <Col xs={8} md={4} lg={2}>
          &copy; ZWC {new Date().getFullYear()}
        </Col>
      </Row>
    </div>
  );
};

export default VerifyAccount;
