import { Spin } from 'antd';

export function LoadingPage() {
  return (
    <>
      <h1>Loading...</h1>
      <Spin></Spin>
    </>
  );
}
