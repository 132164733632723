import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

export interface GraphData {
  id: number;
  count: number;
  title: string;
  color: string;
}

interface GraphProps {
  data: GraphData[];
  title: string;
}

const CustomPieChart = ({ data, title }: GraphProps) => {
  return (
    <div className="bg-white w-full h-full">
      <h2 className="p-2">{title}</h2>
      <ResponsiveContainer width="100%" maxHeight={250}>
        <PieChart>
          <Pie
            data={data}
            dataKey="count"
            nameKey="title"
            cx="50%"
            cy="50%"
            outerRadius="60%"
            innerRadius="45%"
            legendType="circle"
            startAngle={90}
            endAngle={450}
            label
          >
            {data.map((item) => (
              <Cell key="id" fill={item.color} />
            ))}
          </Pie>
          <Tooltip />
          <Legend layout="horizontal" align="center" verticalAlign="bottom" />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomPieChart;
