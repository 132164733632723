import { PropsWithChildren, useEffect } from 'react';

import client from '../api/client';
import { useAuthDispatch } from '../hooks/useAuth';

export default function ReauthenticateProvider({
  children,
}: PropsWithChildren<{}>) {
  const dispatch = useAuthDispatch();

  useEffect(() => {
    client
      .reAuthenticate()
      .then((res) => {
        dispatch({ type: 'login success', payload: res });
      })
      .catch((err) => {
        const authReloadOnce = sessionStorage.getItem('authReloadOnce');
        if (authReloadOnce !== 'yes') {
          sessionStorage.setItem('authReloadOnce', 'yes');
          window.location.reload();
        }
        //NOTE: this is commented because it cause error msg to display when user is not logged in
        // dispatch({ type: 'login fail', error: err });
      });
  }, [dispatch]);

  return <>{children}</>;
}
