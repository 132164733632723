import { useQuery } from 'react-query';

import { ReportingPeriodsService } from '../api/services';
import { Paginated, ReportingPeriod } from '../types';

function getAllReportingPeriods() {
  return ReportingPeriodsService.find({
    query: { $sort: { createdAt: -1 } },
  }).then((res: Paginated<ReportingPeriod>) => res.data);
}

export function useReportingPeriods() {
  const { data = [], ...rest } = useQuery<ReportingPeriod[]>(
    ['get-all-reporting-periods'],
    getAllReportingPeriods,
  );

  const reportingPeriodsById = data?.reduce((acc, rp) => {
    acc[rp._id] = rp;
    return acc;
  }, {} as Record<string, ReportingPeriod>);

  const getReportingPeriodById = (id: string) => {
    return reportingPeriodsById[id];
  };

  return { ...rest, data, getReportingPeriodById, reportingPeriodsById };
}
