import client from './client';

interface Credentials {
  email: string;
  password: string;
}

export function login(credentials: Credentials) {
  console.log('calling login');
  return client
    .authenticate({ ...credentials, strategy: 'local' })
    .then((res: any) => {
      console.log('authenticated: ', res);
      return res;
    });
}

export function logout() {
  return client
    .logout()
    .then(() => {
      console.log('logged out');
    })
    .catch((err: Error) => {
      console.log('error in logout: ', err);
    });
}
