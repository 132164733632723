import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import DownloadAllData from '../DownloadAllData';
import CustomPieChart from './CustomPieChart';
import PackagingOverviewStats from './PackagingOverviewStats';
import ProductOverviewStats from './ProductOverviewStats';
import SupplierPortfolio from './SupplierPortfolio';
import { usePackagingsStats } from './usePackagingsStats';
import { useProductStats } from './useProductStats';

const CompanyDashboard = () => {
  const { t } = useTranslation();
  const { data: productsStats, isLoading, sumAllSuppliers } = useProductStats();
  const { data: packagingsStats, isLoading: packagingStatsLoading } =
    usePackagingsStats();

  const packagingsGraphData = [
    {
      id: 1,
      title: 'Packaging complete (with evidence)',
      count: packagingsStats?.completeWithEvidence || 0,
      color: 'green',
    },
    {
      id: 2,
      title: 'Packaging complete (without evidence)',
      count: packagingsStats?.completeWithoutEvidence || 0,
      color: 'blue',
    },
    {
      id: 3,
      title: 'Packaging in progress',
      count: packagingsStats?.inProgress || 0,
      color: 'red',
    },
  ];

  const productLinkChartData = [
    {
      id: 1,
      title: t('dashboard.companyPage.productSummary.linkedPackage'),
      count:
        (sumAllSuppliers?.totalProducts || 0) -
        (sumAllSuppliers?.totalProductsWith0Packaging || 0),
      color: 'green',
    },
    {
      id: 2,
      title: t('dashboard.companyPage.productSummary.toBeLinked'),
      count: sumAllSuppliers?.totalProductsWith0Packaging || 0,
      color: 'red',
    },
  ];

  return (
    <div className="h-full bg-gray-100">
      <Row gutter={16}>
        <Col span={8}>
          <div>
            <ProductOverviewStats
              isLoading={isLoading}
              productsStats={sumAllSuppliers}
            />
          </div>
          <div className="h-2/6">
            {!packagingStatsLoading && (
              <CustomPieChart title={'Packaging'} data={packagingsGraphData} />
            )}
          </div>
          <div className="h-2/6">
            {!isLoading && (
              <CustomPieChart title={'Product'} data={productLinkChartData} />
            )}
          </div>
          <div className="mt-2">
            <DownloadAllData />
          </div>
        </Col>
        <Col span={16}>
          <div>
            <PackagingOverviewStats
              isLoading={packagingStatsLoading}
              data={packagingsStats}
            />
          </div>
          <div className="mt-4">
            <SupplierPortfolio
              data={productsStats || []}
              isLoading={isLoading}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CompanyDashboard;
