import { Alert, Col, Form, Input, Modal, Row, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UsersService } from '../../api/services';
import { useAuth } from '../../hooks/useAuth';
import { User } from '../../types';
import { isAdmin, isMasterAccount } from '../../utils';

interface UserEditProps {
  isEditing: boolean;
  editedRecord: User;
  handleClose: () => void;
}

const twoColGrid = { xs: 24, md: 12 };

const UserEdit = ({ isEditing, editedRecord, handleClose }: UserEditProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<User>();
  const auth = useAuth();
  const [state, setState] = useState({
    loading: false,
    error: null as null | Error,
  });

  const handleSubmit = () => {
    form.validateFields().then(
      (values) => {
        setState((old) => ({ ...old, loading: true, error: null }));
        const { _id, ...rest } = values;
        if (_id) {
          UsersService.patch(_id, rest)
            .then(
              () => handleClose(),
              (error: Error) => {
                message.error(t('usersPage.edit.messages.update'));
                if (error?.name?.toLowerCase() === 'conflict') {
                  error.message = t('usersPage.edit.messages.emailTaken');
                } else {
                  error.message = t('usersPage.edit.messages.updateUser');
                }
                setState((old) => ({ ...old, error }));
              },
            )
            .finally(() => setState((old) => ({ ...old, loading: false })));
        } else {
          UsersService.create(rest)
            .then(
              () => handleClose(),
              (error: Error) => {
                if (error.name === 'Conflict') {
                  error.message = t('usersPage.edit.messages.emailTaken');
                } else {
                  error.message = t('usersPage.edit.messages.createUser');
                }
                setState((old) => ({ ...old, error }));
                message.error(t('usersPage.edit.messages.create'));
                console.log('Error in creating user: ', error);
              },
            )
            .finally(() => setState((old) => ({ ...old, loading: false })));
        }
      },
      () => null,
    );
  };

  const closeModal = () => {
    setState(() => ({ error: null, loading: false }));
    handleClose();
  };

  useEffect(() => {
    if (isEditing) {
      const { _id, firstName, lastName, email, phone, role, title } =
        editedRecord;

      form.setFieldsValue({
        _id,
        firstName,
        lastName,
        email,
        phone,
        role,
        title,
      });
    }
  }, [isEditing, editedRecord, form]);

  return (
    <Modal
      title={
        editedRecord._id
          ? t('usersPage.edit.update')
          : t('usersPage.edit.newUser')
      }
      visible={isEditing}
      onOk={handleSubmit}
      onCancel={closeModal}
      okButtonProps={{
        style: { marginRight: '0.5rem' },
        loading: state.loading,
        disabled: state.loading,
      }}
    >
      {state.error ? (
        <Alert
          message={state?.error?.message}
          style={{ marginBottom: '1.25rem' }}
          type="error"
          showIcon
        />
      ) : null}
      <Form layout="vertical" form={form}>
        <Form.Item name="_id" hidden noStyle>
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col {...twoColGrid}>
            <Form.Item
              label={t('usersPage.edit.form.firstNameLabel')}
              name="firstName"
              rules={[
                {
                  required: true,
                  message: t('usersPage.edit.form.firstNameMessage'),
                },
              ]}
            >
              <Input
                placeholder={t('usersPage.edit.form.firstNamePlaceholder')}
              />
            </Form.Item>
          </Col>
          <Col {...twoColGrid}>
            <Form.Item
              label={t('usersPage.edit.form.lastNameLabel')}
              name="lastName"
              rules={[
                {
                  required: true,
                  message: t('usersPage.edit.form.lastNameMessage'),
                },
              ]}
            >
              <Input
                placeholder={t('usersPage.edit.form.lastNamePlaceholder')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col {...twoColGrid}>
            <Form.Item
              label={t('usersPage.edit.form.emailLabel')}
              name="email"
              rules={[
                {
                  required: true,
                  message: t('usersPage.edit.form.emailMessage'),
                },
                { type: 'email', message: t('usersPage.edit.form.validEmail') },
              ]}
            >
              <Input placeholder={t('usersPage.edit.form.emailPlaceholder')} />
            </Form.Item>
          </Col>
          <Col {...twoColGrid}>
            <Form.Item
              label={t('usersPage.edit.form.phoneLabel')}
              name="phone"
              rules={[
                {
                  message: t('usersPage.edit.form.phoneMessage'),
                },
              ]}
            >
              <Input placeholder={t('usersPage.edit.form.phonePlaceholder')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col {...twoColGrid}>
            <Form.Item
              label={t('usersPage.edit.form.jobLabel')}
              name="title"
              rules={[
                {
                  required: true,
                  message: t('usersPage.edit.form.jobMessage'),
                },
              ]}
            >
              <Input placeholder={t('usersPage.edit.form.jobPlaceholder')} />
            </Form.Item>
          </Col>
          {!editedRecord._id ||
          (editedRecord._id !== auth.user?._id && isAdmin(auth)) ? (
            <Col {...twoColGrid}>
              <Form.Item
                label={t('usersPage.edit.form.roleLabel')}
                name="role"
                rules={[
                  {
                    required: true,
                    message: t('usersPage.edit.form.roleMessage'),
                  },
                ]}
              >
                <Select placeholder={t('usersPage.edit.form.rolePlaceholder')}>
                  {isMasterAccount(auth) ? (
                    <>
                      <Select.Option value="zwc-admin">
                        {t('usersPage.edit.form.admin')}
                      </Select.Option>
                      <Select.Option value="zwc-user">
                        {t('usersPage.edit.form.staff')}
                      </Select.Option>
                    </>
                  ) : (
                    <>
                      <Select.Option value="company-admin">
                        {t('usersPage.edit.form.admin')}
                      </Select.Option>
                      <Select.Option value="company-user">
                        {t('usersPage.edit.form.staff')}
                      </Select.Option>
                      <Select.Option value="company-observer">
                        {t('usersPage.edit.form.observer')}
                      </Select.Option>
                    </>
                  )}
                </Select>
              </Form.Item>
            </Col>
          ) : null}
        </Row>
      </Form>
    </Modal>
  );
};

export default UserEdit;
