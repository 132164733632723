import './OldReportingPeriodWarning.css';

import { Card } from 'antd';
import { AiOutlineWarning } from 'react-icons/ai';

const ReportingWarning = () => {
  return (
    <div>
      <Card bordered>
        <div className="flex p-2">
          <div className="flex justify-center items-center ml-4 mb-5 ">
            <span className="bg-red">
              <AiOutlineWarning className="text-3xl text-blue-500" />
            </span>
          </div>
          <div className="leading-normal space-x-0 ml-4 ">
            <p className="font-medium text-lg">
              Warning: This is not the latest reporting period, make sure you
              are editing the correct reporting period.
            </p>
            <p className="font-medium text-lg -mt-3">
              Beware that changes in previous reporting year will not transfer
              to the more recent one.
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ReportingWarning;
