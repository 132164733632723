import PageContainer from '../Layout';
import UsersList from './UsersList';

const Users = () => (
  <PageContainer activeTab="users">
    <UsersList />
  </PageContainer>
);

export default Users;
