import { KeyOutlined } from '@ant-design/icons';
import { Form, Input, Modal, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UsersService } from '../../api/services';
import { User } from '../../types';

interface PasswordUpdateProps {
  isEditing: boolean;
  userId: string;
  handleClose: () => void;
}

const PasswordUpdate = ({
  isEditing,
  userId,
  handleClose,
}: PasswordUpdateProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<User>();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    form.validateFields().then(
      (values) => {
        const { _id, password } = values;
        if (_id) {
          setIsLoading(true);
          UsersService.patch(_id, { password })
            .then(
              () => handleClose(),
              (error: Error) => {
                message.error(
                  t('usersPage.passwordUpdate.messages.updatePass'),
                );
                console.log('Error in updating user: ', error.message);
              },
            )
            .finally(() => setIsLoading(false));
        }
      },
      () => null,
    );
  };

  useEffect(() => {
    if (isEditing) {
      form.setFieldsValue({
        _id: userId,
      });
    }
  }, [isEditing, userId, form]);

  return (
    <Modal
      title={t('usersPage.passwordUpdate.messages.changesUserPass')}
      visible={isEditing}
      onOk={handleSubmit}
      onCancel={handleClose}
      okButtonProps={{
        style: { marginRight: '0.5rem' },
        loading: isLoading,
        disabled: isLoading,
      }}
    >
      <Form layout="vertical" form={form}>
        <Form.Item name="_id" hidden noStyle rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: t('usersPage.passwordUpdate.messages.passReq'),
            },
            {
              min: 8,
              message: t('usersPage.passwordUpdate.messages.passLength'),
            },
          ]}
        >
          <Input.Password placeholder="Password" prefix={<KeyOutlined />} />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={['password']}
          label={t('usersPage.passwordUpdate.messages.confirmPass')}
          rules={[
            {
              required: true,
              message: t('usersPage.passwordUpdate.messages.conPass'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  t('usersPage.passwordUpdate.messages.passNotMatch'),
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder={t('usersPage.passwordUpdate.inputPlaceholder')}
            prefix={<KeyOutlined />}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PasswordUpdate;
