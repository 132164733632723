import { Packaging } from '../../../../../types/index';
import AuditTrail from './AuditTrail';

interface EvidenceAuditProps {
  packaging: Packaging;
  readOnly: boolean;
}

const EvidenceAudit = ({ packaging, readOnly }: EvidenceAuditProps) => {
  const { _id: packagingId } = packaging;

  return (
    <div className="mt-4">
      <AuditTrail packagingId={packagingId} />
    </div>
  );
};

export default EvidenceAudit;
