import PageContainer from '../Layout';
import ProductList from './ProductList';

const Product = () => {
  return (
    <PageContainer activeTab="product">
      <ProductList />
    </PageContainer>
  );
};

export default Product;
