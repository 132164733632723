import './PageNotFound.css';

import { webBaseURL } from '../../utils';

export default function PageNotFound() {
  return (
    <div>
      <div id="error-page">
        <div className="content">
          <h2 className="header" data-text="404">
            404
          </h2>
          <h4 data-text="Opps! Page not found">Opps! Page not found</h4>
          <p>Sorry, the page you are looking for does not exist.</p>
          <div className="btns">
            <a href={webBaseURL()}>return home</a>
          </div>
        </div>
      </div>
    </div>
  );
}
