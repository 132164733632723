export function getUserRoleLabel(role = '') {
  if (role.endsWith('admin')) {
    return 'Admin';
  } else if (role.endsWith('user')) {
    return 'Staff';
  } else if (role.endsWith('observer')) {
    return 'Observer';
  } else {
    return 'Unknown role';
  }
}
