import PageContainer from '../Layout';
import PackagingList from './PackagingList';

const Packaging = () => {
  return (
    <PageContainer activeTab="packaging">
      <PackagingList />
    </PageContainer>
  );
};

export default Packaging;
