import { Col, List, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { FiPackage } from 'react-icons/fi';

import { PackagingsStatsReport } from './usePackagingsStats';

interface PackagingStatsProps {
  isLoading: boolean;
  data?: PackagingsStatsReport;
}

const PackagingOverviewStats = (props: PackagingStatsProps) => {
  const { t } = useTranslation();
  const { isLoading, data } = props;
  return (
    <List
      header={
        <div className="flex justify-between text-lg font-bold">
          <span>{t('dashboard.companyPage.stats.packaging.title')}</span>
          <FiPackage style={{ color: 'blue' }} />
        </div>
      }
      rowKey="_id"
      className="bg-white m-list-bgc"
      loading={isLoading}
      bordered
    >
      <List.Item>
        {(data?.inProgress || 0) +
          (data?.completeWithEvidence || 0) +
          (data?.completeWithoutEvidence || 0)}{' '}
        {t('dashboard.companyPage.stats.packaging.totalPackaging')}
      </List.Item>
      <List.Item>
        <div className="w-full">
          <Row justify="start">
            <Col span={8}></Col>
            <Col span={3} className="font-bold text-center">
              Total
            </Col>
            <Col span={3} className="font-bold text-center">
              Not Linked
            </Col>
            <Col span={3} className="font-bold text-center">
              Red Flag
            </Col>
            <Col span={3} className="font-bold text-center">
              Proxy
            </Col>
            <Col span={4} className="font-bold text-center">
              Assumption
            </Col>
          </Row>
          <Row className="border-t-2 p-2" justify="center">
            <Col span={8}>Packaging in progress</Col>
            <Col span={3} className={'text-center'}>
              {data?.inProgress || 0}
            </Col>
            <Col span={3} className={'text-center'}>
              {(data?.inProgress || 0) - (data?.inProgressTotalLinked || 0)}
            </Col>
            <Col span={3} className={'text-center'}>
              {data?.inProgressRedFlag || 0}
            </Col>
            <Col span={3} className={'text-center'}>
              {data?.inProgressProxy || 0}
            </Col>
            <Col span={4} className={'text-center'}>
              {data?.inProgressAssumptions || 0}
            </Col>
          </Row>
          <Row className="border-t-2 p-2" justify="center">
            <Col span={8}>Packaging complete (without evidence)</Col>
            <Col span={3} className={'text-center'}>
              {data?.completeWithoutEvidence || 0}
            </Col>
            <Col span={3} className={'text-center'}>
              {(data?.completeWithoutEvidence || 0) -
                (data?.completeWithoutEvidenceTotalLinked || 0)}
            </Col>
            <Col span={3} className={'text-center'}>
              {data?.completeWithoutEvidenceRedFlag || 0}
            </Col>
            <Col span={3} className={'text-center'}>
              {data?.completeWithoutEvidenceProxy || 0}
            </Col>
            <Col span={4} className={'text-center'}>
              {data?.completeWithoutEvidenceAssumptions || 0}
            </Col>
          </Row>
          <Row className="border-t-2 border-b-2 p-2" justify="start">
            <Col span={8}>Packaging complete (with evidence)</Col>
            <Col span={3} className={'text-center'}>
              {data?.completeWithEvidence || 0}
            </Col>
            <Col span={3} className={'text-center'}>
              {' '}
              {(data?.completeWithEvidence || 0) -
                (data?.completeWithEvidenceTotalLinked || 0)}
            </Col>
            <Col span={3} className={'text-center'}>
              {data?.completeWithEvidenceRedFlag || 0}
            </Col>
            <Col span={3} className={'text-center'}>
              {data?.completeWithEvidenceProxy || 0}
            </Col>
            <Col span={4} className={'text-center'}>
              {data?.completeWithEvidenceAssumptions || 0}
            </Col>
          </Row>
        </div>
      </List.Item>
    </List>
  );
};

export default PackagingOverviewStats;
