import { useQuery } from 'react-query';

import { ProductsService } from '../../../api/services';
import { useActiveReportingPeriod } from '../../../hooks/useActiveReportingPeriod';
import { calcPercentage } from './calcPercentage';

export interface ProductsStatsReport {
  supplier: string;
  totalProducts: number;
  totalProductsWith0Packaging: number;
  totalProductsWith1Packaging: number;
  totalProductsWith2Packaging: number;
  totalProductsWith3PlusPackaging: number;
  percentage: number;
}

function getProductStats({ queryKey }: any): Promise<ProductsStatsReport[]> {
  const [_, query = {}] = queryKey;
  return ProductsService.find({
    query: {
      ...query,
      reportName: 'productsStats',
    },
  });
}

export function useProductStats() {
  const { reportingPeriod } = useActiveReportingPeriod();

  const { data, ...restQuery } = useQuery(
    [
      'company-dashboard-products-stats',
      {
        reportingPeriodId: reportingPeriod?._id,
      },
    ],
    getProductStats,
  );

  const sumAllSuppliers = (data || []).reduce(
    (a, c) => ({
      supplier: 'all',
      percentage: 0,
      totalProducts: (a.totalProducts || 0) + (c.totalProducts || 0),
      totalProductsWith0Packaging:
        (a.totalProductsWith0Packaging || 0) +
        (c.totalProductsWith0Packaging || 0),
      totalProductsWith1Packaging:
        (a.totalProductsWith1Packaging || 0) +
        (c.totalProductsWith1Packaging || 0),
      totalProductsWith2Packaging:
        (a.totalProductsWith2Packaging || 0) +
        (c.totalProductsWith2Packaging || 0),
      totalProductsWith3PlusPackaging:
        (a.totalProductsWith3PlusPackaging || 0) +
        (c.totalProductsWith3PlusPackaging || 0),
    }),
    {
      supplier: 'all',
      totalProducts: 0,
      totalProductsWith0Packaging: 0,
      totalProductsWith1Packaging: 0,
      totalProductsWith2Packaging: 0,
      totalProductsWith3PlusPackaging: 0,
      percentage: 0,
    },
  );

  const dataWithPercentages = data?.map((record) => {
    const percentage = calcPercentage(
      record.totalProducts - record.totalProductsWith0Packaging,
      record.totalProducts,
    );

    record.percentage = percentage;

    return record;
  });

  return { ...restQuery, data: dataWithPercentages, sumAllSuppliers };
}
