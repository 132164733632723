import io from 'socket.io-client';

import { isEnv } from './../utils';

function getSocket() {
  if (isEnv('production')) {
    return io('https://mpr.zerowastecity.com', {
      path: '/api/socket.io',
    });
  }

  if (isEnv('test')) {
    return io('https://devmpr.zerowastecity.com', {
      path: '/api/socket.io',
    });
  }

  return io('http://localhost:3030');
}

export default getSocket();
