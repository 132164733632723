import { Route, Switch } from 'react-router-dom';

import ProtectedRoute from './components/ProtectedRoute';
import ForgotPassword from './pages/Auth/ForgotPassword';
import Login from './pages/Auth/Login';
import ResetPassword from './pages/Auth/ResetPassword';
import VerifyAccount from './pages/Auth/VerifyAccount';
import BulkPackaging from './pages/BulkPackaging';
import Companies from './pages/Companies';
import Dashboard from './pages/Dashboard';
import Lists from './pages/Lists';
import Packaging from './pages/Packaging';
import EditPackaging from './pages/Packaging/edit-packaging/edit-packaging';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import Products from './pages/Product';
import CreateReportingPeriod from './pages/reporting-periods/create-reporting-period';
import ReportingPeriods from './pages/reporting-periods/reporting-periods';
import Users from './pages/Users';

const Router = () => {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/verify-account/:userId/:token" component={VerifyAccount} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password/:userId/:token" component={ResetPassword} />

      <ProtectedRoute path="/dashboard" component={Dashboard} />
      <ProtectedRoute path="/lists" component={Lists} />
      <ProtectedRoute path="/users" component={Users} />
      <ProtectedRoute path="/companies" component={Companies} />

      <ProtectedRoute
        companyOnly
        path="/reporting-periods"
        component={ReportingPeriods}
      />

      <ProtectedRoute
        companyOnly
        path="/edit-packaging/:packagingId"
        component={EditPackaging}
      />

      <ProtectedRoute
        companyOnly
        path="/create-reporting-period"
        component={CreateReportingPeriod}
      />

      <ProtectedRoute companyOnly path="/packagings" component={Packaging} />

      <ProtectedRoute companyOnly path="/products" component={Products} />

      <ProtectedRoute
        companyOnly
        path="/bulk-packagings"
        component={BulkPackaging}
      />

      <ProtectedRoute exact path="/" component={Dashboard} />
      <Route path="*" component={PageNotFound} />
    </Switch>
  );
};

export default Router;
