import './App.css';
import './i18n';

import AllProviders from './components/AllProviders';
import RoutesList from './RoutesList';

// Create a client

function App() {
  return (
    // Provide the client to your App

    <AllProviders>
      <RoutesList />
    </AllProviders>
  );
}

export default App;
