import { Select } from 'antd';
import { useState } from 'react';
import { QueryFunction, useQuery } from 'react-query';

import useDebounce from '../../hooks/useDebounce';

interface CustomAutoCompleteProps {
  queryFn: QueryFunction;
  placeholder?: string;
  mode?: 'multiple' | 'tags';
}
interface CustomAutoCompleteOptions {
  id: string;
  value: string;
}

export function CustomAutoComplete(props: CustomAutoCompleteProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const { queryFn, ...restProps } = props;

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  const { isLoading, data } = useQuery(
    ['custom-auto-complete-search', debouncedSearchTerm],
    queryFn,
  );

  const uniqueOptions = (
    res: Array<CustomAutoCompleteOptions>,
  ): Array<CustomAutoCompleteOptions> =>
    Object.values(res.reduce((a, c) => ({ ...a, [c.id]: c }), {}));

  let optionsToRender: Array<CustomAutoCompleteOptions> = [];

  if (!isLoading && Array.isArray(data)) {
    optionsToRender = uniqueOptions(data);
  }

  return (
    <Select
      {...restProps}
      onSearch={(v) => setSearchTerm(v)}
      allowClear
      showSearch
      onFocus={() => setSearchTerm('')}
    >
      {optionsToRender.map((item: CustomAutoCompleteOptions) => (
        <Select.Option key={item.id} value={item.id}>
          {item.id}
        </Select.Option>
      ))}
    </Select>
  );
}
