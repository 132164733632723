import { NotificationType } from '../types';

export const notificationSettings: Array<{
  label: string;
  type: NotificationType;
  description: string;
}> = [
  {
    label: 'Mention',
    description: 'when somebody mentions you in packaging comment',
    type: 'mention',
  },
  {
    label: 'Product',
    description: 'when somebody makes changes to a product',
    type: 'product',
  },
  {
    label: 'Packaging',
    description: 'when somebody makes changes to a regular packaging',
    type: 'packaging',
  },
  {
    label: 'Bulk packaging',
    description: 'when somebody makes changes to a bulk packaging',
    type: 'bulkPackaging',
  },
  {
    label: 'User role',
    description: 'when your user role is changed between admin/staff/observer',
    type: 'userRole',
  },
  {
    label: 'New login',
    description: 'when your account is logged in with username/password',
    type: 'newLogin',
  },
];
