import { PackagingCommentsService } from '../../../../../api/services';
import { PackagingComment, Paginated } from '../../../../../types';

export const fetchPackagingComments = ({
  queryKey,
}: any): Promise<Paginated<PackagingComment>> => {
  const [, packagingId] = queryKey;
  return PackagingCommentsService.find({
    query: { packagingId, $sort: { createdAt: -1 } },
  });
};

export const createPackagingComment = (
  comment: PackagingComment,
): Promise<PackagingComment> => {
  return PackagingCommentsService.create(comment);
};
