import { InboxOutlined } from '@ant-design/icons';
import { Button, Modal, Spin, Table, Upload, message } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import XLSX from 'xlsx';

import { PackagingsService, ProductsService } from '../../api/services';
import { useActiveReportingPeriod } from '../../hooks/useActiveReportingPeriod';
import { useAssessmentMethods } from '../../pages/BulkPackaging/BulkPackagingEditor/Components/useAssessmentMethods';
import { useMaterialItems } from '../../pages/Packaging/edit-packaging/PackagingEditor/Components/useMaterialItems';
import { Packaging, PackagingLink, Product } from '../../types';
import extractData from './extractData';

const { Dragger } = Upload;
type ImportProductsProps = {
  visible: boolean;
  handleClose: (reload: boolean) => void;
};

export type colMapping = {
  [userKey in keyof Product]: string;
};

interface PreviewDataRequestProps {
  records: Array<{
    packaging: Packaging;
    link: PackagingLink;
    product: Product;
  }>;
}

function PreviewDataRequest(props: PreviewDataRequestProps) {
  const columns = [
    {
      dataIndex: 'product',
      title: 'Product',
      render: (value: Product) => {
        return (
          <>
            {value.description} ({value._id || ''})
          </>
        );
      },
    },

    {
      dataIndex: 'packaging',
      title: 'Packaging',
      render: (packaging: Packaging) => {
        return (
          <>
            {packaging.title} {packaging._id ? `(${packaging?._id})` : ''}
          </>
        );
      },
    },

    {
      dataIndex: 'packaging',
      title: 'Action',
      render: (packaging: Packaging) => {
        if (packaging._id) {
          return 'To be updated';
        }
        return 'To be created';
      },
    },
  ];

  return (
    <Table
      rowKey={() => Math.random()}
      dataSource={props.records}
      columns={columns}
    />
  );
}

const ImportPackagingDataRequests = ({
  visible,
  handleClose,
}: ImportProductsProps) => {
  const { t } = useTranslation();
  const [state, setState] = useState<{
    uploadSuccess?: boolean;
    isLoading?: boolean;
    data?: any;
  }>({
    uploadSuccess: false,
    isLoading: false,
    data: {},
  });

  const { reportingPeriod } = useActiveReportingPeriod();

  const { data: assessmentMethods } = useAssessmentMethods();
  const { data: materialItems } = useMaterialItems();

  const uploadProps: UploadProps = {
    showUploadList: false,
    name: 'file',
    accept:
      '.xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    onChange(info) {
      const { file = {} as UploadFile } = info;
      const { status } = file;
      if (status === 'uploading') {
        setState((old) => ({ ...old, isLoading: true }));
        message.loading({
          content: t('ImportProduct.uploadingFileMsg'),
          duration: 0,
          key: 'productsImport',
        });
      } else if (status === 'done') {
        message.loading({
          content: t('ImportProduct.readingFileMsg'),
          duration: 0,
          key: 'productsImport',
        });
      } else if (status === 'error') {
        setState((old) => ({ ...old, isLoading: false }));
        message.error({
          content: t('ImportProduct.errReadingFile'),
          key: 'productsImport',
        });
      }
    },
    customRequest({ file, onSuccess, onError }) {
      const fileReader = new FileReader();
      fileReader.onload = (e: ProgressEvent<FileReader>) => {
        const bufferString = e?.target?.result;
        const wb = XLSX.read(bufferString, { type: 'array' });
        const extractedRecords: any[] = [];
        wb.SheetNames.slice(1).forEach((sheetName) => {
          /* Convert to array of arrays */
          const ws = wb.Sheets[sheetName];
          const rawSheetDataAsAoA = XLSX.utils.sheet_to_json(ws, { header: 1 });
          const extractedRecord = extractData(
            rawSheetDataAsAoA as any,
            (assessmentMethods || []).reduce(
              (a, c) => ({ ...a, [c.title]: c }),
              {},
            ),
            materialItems as any,
          );

          extractedRecords.push(extractedRecord);
        });

        message.success({
          content: t('ImportProduct.uploadComplete'),
          key: 'productsImport',
        });
        setState((old) => ({
          ...old,
          uploadSuccess: true,
          isLoading: false,
          data: extractedRecords,
        }));
      };

      fileReader.onerror = function (
        this: FileReader,
        ev: ProgressEvent<FileReader>,
      ) {
        setState((old) => ({ ...old, isLoading: false, uploadSuccess: false }));
        message.error({
          content: t('ImportProduct.errReadingFile'),
          key: 'productsImport',
        });
        onError?.(ev);
      };

      fileReader.readAsArrayBuffer(file as Blob);
    },
  };

  const importRequestedData = () => {
    setState((old) => ({ ...old, isLoading: true }));
    message.loading({
      content: 'Requested data is being imported',
      duration: 0,
      key: 'importing',
    });

    PackagingsService.create({
      action: 'importDataRequest',
      records: state.data,
      reportingPeriodId: reportingPeriod?._id,
    })
      .then(
        () => {
          message.success({
            content: t('Requested data has been imported'),
            key: 'importing',
          });
          closeModal(true);
        },
        (e: Error) => {
          message.error({
            content: t('ImportProduct.errSave'),
            key: 'importing',
          });
          console.log('Error occurred in saving products: ', e);
        },
      )
      .finally(() => setState((old) => ({ ...old, isLoading: false })));
  };

  const closeModal = (shouldReload: boolean) => {
    setState({
      isLoading: false,
      uploadSuccess: false,
    });
    handleClose(shouldReload);
  };

  return (
    <Modal
      title={
        <div>
          <div>{t('Import Data Request')}</div>
        </div>
      }
      width="800px"
      visible={visible}
      onCancel={() => closeModal(false)}
      footer={
        <div className="pr-2">
          <Button key="back" onClick={() => closeModal(false)}>
            {t('ImportProduct.cancel')}
          </Button>

          <Button
            type="primary"
            onClick={importRequestedData}
            disabled={state.isLoading}
            loading={state.isLoading}
          >
            {t('ImportProduct.importData')}
          </Button>
        </div>
      }
    >
      {!state.uploadSuccess ? (
        <Spin spinning={state.isLoading}>
          <Dragger
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            {...uploadProps}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('ImportProduct.dragMsg')}</p>
            <p className="ant-upload-hint">{t('ImportProduct.dragInfo')}</p>
          </Dragger>
        </Spin>
      ) : (
        <PreviewDataRequest records={state.data} />
      )}

      {/* {state.uploadSuccess && (
        <>
          <pre>{JSON.stringify(state.data, null, 2)}</pre>
        </>
      )} */}
    </Modal>
  );
};

export default ImportPackagingDataRequests;
