import { Button, Col, Form, Input, Row } from 'antd';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

export interface CompanyListFilters {
  searchTerm: string;
}
export const areCompanyFiltersEmpty = (
  { searchTerm = '' } = {} as CompanyListFilters,
) => {
  return !searchTerm;
};
interface CompanyFiltersProps {
  filters: CompanyListFilters;
  setFilters: (filters: CompanyListFilters) => void;
  className?: string;
}

const CompanyFilters = ({
  filters,
  setFilters,
  className = 'py-1 mt-1 border-t border-b',
}: CompanyFiltersProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<CompanyListFilters>();
  const clearFilters = () => {
    form.resetFields();
    setFilters({} as CompanyListFilters);
  };
  return (
    <div className={className}>
      <Form
        form={form}
        onValuesChange={debounce(
          (change) => setFilters({ ...filters, ...change }),
          300,
        )}
        layout="horizontal"
      >
        <Row gutter={8}>
          <Col span={10}>
            <Form.Item className="label-mb-0" name="searchTerm">
              <Input
                placeholder={t('companyPage.list.filterPlaceholder')}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Button
              type="primary"
              onClick={clearFilters}
              disabled={areCompanyFiltersEmpty(filters)}
              danger
            >
              {t('companyPage.list.filterBtnText')}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CompanyFilters;
