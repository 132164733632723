import { useQuery } from 'react-query';

import { getCurrentCompanyId } from '../../components/getCurrentCompanyId';
import { useAuth } from '../../hooks/useAuth';
import { PageProps } from '../../types';
import PageContainer from '../Layout';
import CreateReportingPeriodForm from './CreateReportingPeriodForm';
import { getAllReportingPeriods } from './getAllReportingPeriods';
import { LoadingPage } from './LoadingPage';

const CreateReportingPeriod = (props: PageProps) => {
  console.log('create props', props);
  const { user } = props.auth;

  const { data, isLoading, isError } = useQuery(
    ['reporting-periods', getCurrentCompanyId(user)],
    getAllReportingPeriods,
  );

  if (isError) {
    //TODO: add a proper error page
    throw new Error('Oops! there was some error in fetching data!');
  }

  return (
    <PageContainer activeTab="reporting-periods">
      <div className="p-4 bg-white">
        {isLoading ? (
          <LoadingPage />
        ) : (
          <CreateReportingPeriodForm reportingPeriods={data.data} />
        )}
      </div>
    </PageContainer>
  );
};

export default CreateReportingPeriod;
