import React from 'react';

import AccountStats from './AccountStats';
import CompanyOverviewStats from './CompanyOverviewStats';

const MasterDashboard = () => {
  return (
    <div>
      <AccountStats />
      <div className="mt-5">
        <CompanyOverviewStats />
      </div>
    </div>
  );
};

export default MasterDashboard;
