import { Alert, Col, Form, Input, Modal, Row, Select, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CompaniesService, UsersService } from '../../api/services';
import CountrySelect from '../../components/CountriesSelect';
import { Company } from '../../types';

interface CompanyEditProps {
  isEditing: boolean;
  editedRecord: Company;
  handleClose: () => void;
}

const twoColGrid = { xs: 24, md: 12 };

const CompanyEdit = ({
  isEditing,
  editedRecord,
  handleClose,
}: CompanyEditProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<Company>();
  const [state, setState] = useState({
    loading: false,
    error: null as null | Error,
  });

  const handleSubmit = () => {
    form.validateFields().then(
      (values) => {
        setState((old) => ({ ...old, loading: true, error: null }));
        const { _id, ...rest } = values;
        if (_id) {
          CompaniesService.patch(_id, rest)
            .then(
              () => handleClose(),
              (error: Error) => {
                setState((old) => ({ ...old, error }));
                if (error?.name?.toLowerCase() === 'conflict') {
                  error.message = t('companyPage.emailTaken');
                } else {
                  error.message = t('companyPage.updateErrCompany');
                }
                console.log('Error in creating company: ', error);
              },
            )
            .finally(() => setState((old) => ({ ...old, loading: false })));
        } else {
          //@ts-ignore
          const { email, ...company } = rest;
          UsersService.create({
            email,
            company,
            role: 'company-admin',
          })
            .then(
              () => handleClose(),
              (error: Error) => {
                setState((old) => ({ ...old, error }));
                if (error?.name?.toLowerCase() === 'conflict') {
                  error.message = t('companyPage.emailTaken');
                } else {
                  error.message = t('companyPage.createErrCompany');
                }
                message.error(t('companyPage.saveErrCompany'));
                console.log('Error in creating company: ', error);
              },
            )
            .finally(() => setState((old) => ({ ...old, loading: false })));
        }
      },
      () => null,
    );
  };
  const closeModal = () => {
    setState(() => ({ error: null, loading: false }));
    form.resetFields();
    handleClose();
  };
  useEffect(() => {
    if (isEditing) {
      const {
        _id,
        businessName,
        description,
        country,
        category = 'client',
      } = editedRecord;

      form.setFieldsValue({
        _id,
        businessName,
        description,
        country,
        category,
      });
    }
  }, [isEditing, editedRecord, form]);

  return (
    <Modal
      title={
        editedRecord._id
          ? t('companyPage.editCompany')
          : t('companyPage.addCompany')
      }
      visible={isEditing}
      onOk={handleSubmit}
      onCancel={closeModal}
      okButtonProps={{
        style: { marginRight: '0.5rem' },
        loading: state.loading,
        disabled: state.loading,
      }}
    >
      {state.error ? (
        <Alert
          message={state?.error?.message}
          style={{ marginBottom: '1.25rem' }}
          type="error"
          showIcon
        />
      ) : null}
      <Form layout="vertical" form={form}>
        <Form.Item name="_id" hidden noStyle>
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="businessName"
              label={t('companyPage.form.name')}
              rules={[
                { required: true, message: t('companyPage.form.nameMessage') },
              ]}
            >
              <Input placeholder={t('companyPage.form.namePlaceholder')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="description" label={t('companyPage.form.desc')}>
              <Input.TextArea
                placeholder={t('companyPage.form.descPlaceholder')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col {...twoColGrid}>
            <Form.Item
              name="country"
              label={t('companyPage.form.country')}
              rules={[
                {
                  required: true,
                  message: t('companyPage.form.countryMessage'),
                },
              ]}
            >
              <CountrySelect
                placeholder={t('companyPage.form.countryPlaceholder')}
              />
            </Form.Item>
          </Col>
          <Col {...twoColGrid}>
            <Form.Item
              name="category"
              label={t('companyPage.form.category')}
              rules={[
                {
                  required: true,
                  message: t('companyPage.form.categoryMessage'),
                },
              ]}
            >
              <Select placeholder={t('companyPage.form.categoryPlaceholder')}>
                <Select.Option value="client">
                  {t('companyPage.form.client')}
                </Select.Option>
                <Select.Option value="supplier">
                  {t('companyPage.form.supplier')}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {!editedRecord._id ? (
          <Row gutter={16}>
            <Col {...twoColGrid}>
              <Form.Item
                name="email"
                label={t('companyPage.form.emailLabel')}
                rules={[
                  {
                    required: true,
                    message: t('companyPage.form.emailMessage'),
                  },
                  { type: 'email', message: t('companyPage.form.validEmail') },
                ]}
              >
                <Input placeholder={t('companyPage.form.emailPlaceholder')} />
              </Form.Item>
            </Col>
          </Row>
        ) : null}
      </Form>
    </Modal>
  );
};

export default CompanyEdit;
