import { Button, Modal, Popconfirm, Spin, message } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ExportService } from '../../api/services';
import { useAuth } from '../../hooks/useAuth';
import { Paginated } from '../../types';
import { isObserverUser } from '../../utils';

interface ExportListProps {
  visible: boolean;
  handleClose: () => void;
  serviceName: 'products' | 'packagings' | 'productPackagingDataRequest';
}
interface ExportRecord {
  _id: string;
  status: 'done' | 'pending';
  createdAt: Moment;
  file: UploadFile;
}

const DownloadExports = ({
  visible,
  handleClose,
  serviceName,
}: ExportListProps) => {
  const { t } = useTranslation();
  const [list, setList] = useState([] as ExportRecord[]);
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  useEffect(() => {
    const handleUpdate = (res: ExportRecord) => {
      setList((list) => {
        let index = list.findIndex((item) => item._id === res._id);
        let newList = [];
        if (index === -1) {
          newList = [res, ...list];
        } else {
          newList = list.map((item) => (item._id === res._id ? res : item));
        }

        return newList;
      });
    };

    if (visible) {
      setLoading(true);
      ExportService.find({
        query: { $sort: { createdAt: -1 }, serviceName },
      })
        .then(
          (res: Paginated<ExportRecord>) => setList(res.data),
          (e: Error) => {
            message.error(t('productPage.downloadExport.messages.fetch'));
            console.log(t('productPage.downloadExport.messages.fetch'), e);
          },
        )
        .finally(() => setLoading(false));
    }

    ExportService.on('created', handleUpdate);

    return () => {
      ExportService.off('created', handleUpdate);
    };
  }, [visible]);

  const deleteExport = (id: string) => {
    setLoading(true);
    ExportService.remove(id).then(
      (res: ExportRecord) => {
        setList(list.filter((item) => item._id !== res._id));
        setLoading(false);
      },
      () => {
        message.error(t('productPage.downloadExport.messages.deleting'));
        setLoading(false);
      },
    );
  };

  return (
    <Modal
      title={t('productPage.downloadExport.listOfDownloads')}
      visible={visible}
      onCancel={handleClose}
      footer={
        <div className="flex justify-end px-2">
          <Button type="default" onClick={handleClose}>
            {t('productPage.downloadExport.close')}
          </Button>
        </div>
      }
      bodyStyle={{ padding: '24px' }}
    >
      <Spin spinning={loading}>
        {list.length > 0 ? (
          list.map((item) => (
            <div
              className="flex items-center justify-between s-hover-parent"
              key={item._id}
            >
              <div className="truncate">
                {moment(item.createdAt).format('DD MMM YYYY - HH:mm')}
              </div>
              <div className="flex justify-between w-40">
                {item.status === 'done' && (
                  <Button
                    type="link"
                    className="px-0"
                    href={item?.file?.url}
                    download
                  >
                    {t('productPage.downloadExport.download')}
                  </Button>
                )}
                {item.status === 'pending' && (
                  <Button type="link" className="px-0" disabled>
                    {t('productPage.downloadExport.pending')}
                  </Button>
                )}
                {item.status !== 'pending' && item.status !== 'done' && (
                  <Button type="link" className="px-0" disabled>
                    {t('productPage.downloadExport.failed')}
                  </Button>
                )}
                {isObserverUser(auth) ? null : (
                  <Popconfirm
                    title={t('productPage.downloadExport.popConfirmText')}
                    onConfirm={() => deleteExport(item._id)}
                    placement="topRight"
                    okText="Yes"
                    okButtonProps={{ danger: true }}
                    disabled={loading}
                  >
                    <Button
                      type="text"
                      className="text-red-500 s-hover-target"
                      disabled={loading}
                    >
                      {t('productPage.downloadExport.deleteTxt')}
                    </Button>
                  </Popconfirm>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className="font-bold text-center text-gray-400">
            {t('productPage.downloadExport.text01')}
            <p>{t('productPage.downloadExport.text02')}</p>
          </div>
        )}
      </Spin>
    </Modal>
  );
};

export default DownloadExports;
