import { User } from '../types';
import { useCompanyUsers } from './useCompanyUsers';
import { useZWCUsers } from './useZWCUsers';

export default function useAllUsers() {
  const { data: allCompanyUsers, isLoading: companyLoading } =
    useCompanyUsers();
  const { data: zwcUsers, isLoading: zwcLoading } = useZWCUsers();

  const data = [...(allCompanyUsers || []), ...(zwcUsers || [])];

  const usersById = (data || []).reduce(
    (a: { [index: string]: User }, c: User) => ({ ...a, [c._id]: c }),
    {} as { [index: string]: User },
  );

  return {
    data,
    usersById,
    isLoading: zwcLoading || companyLoading,
  };
}
