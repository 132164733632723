import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import { getPercentage, getRandomAlphaNumericString } from '../../../utils';
import { ProductsStatsReport } from './useProductStats';

interface SupplierPortfolioProps {
  data: ProductsStatsReport[];
  isLoading: boolean;
}

const SupplierPortfolio = ({ data, isLoading }: SupplierPortfolioProps) => {
  const [t] = useTranslation();

  const columns: ColumnProps<ProductsStatsReport>[] = [
    {
      title: t('Supplier'),
      dataIndex: 'supplier',
      width: '40%',
      sorter: (a: any, b: any) => a.supplier - b.supplier,
    },
    {
      title: t('Total products'),
      className: 'text-center',
      dataIndex: 'totalProducts',
      width: '20%',
      sorter: (a: any, b: any) =>
        (a.totalProducts || 0) - (b.totalProducts || 0),
    },
    {
      title: 'No of product linked to any packaging',
      className: 'text-center',
      dataIndex: 'totalProductsWithPackaging',
      sorter: (a: any, b: any) =>
        (a.totalProductsWithPackaging || 0) -
        (b.totalProductsWithPackaging || 0),
      render: (_, record) =>
        (record.totalProducts || 0) - (record.totalProductsWith0Packaging || 0),
      width: '20%',
    },
    {
      title: 'No of product not linked to any packaging',
      className: 'text-center',
      dataIndex: 'totalProductsWith0Packaging',
      width: '20%',
      sorter: (a: any, b: any) =>
        (a.totalProductsWith0Packaging || 0) -
        (b.totalProductsWith0Packaging || 0),
    },
    {
      title: t('Percentage of products with packaging'),
      dataIndex: 'percentage',
      width: '20%',
      sorter: (a: any, b: any) => (a.percentage || 0) - (b.percentage || 0),
      render: (text) => {
        return (
          <div className="relative w-full text-center">
            <div className="relative z-50">{text.toFixed()}%</div>
            <div
              className="absolute top-0 h-full bg-green-500 border border-green-600"
              style={{
                width: `${text.toFixed()}%`,
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div className="h-full bg-white">
      <div className="px-4 py-1 text-lg font-bold">
        {t(`Supplier portfolio (${data.length} in total)`)}
      </div>
      <Table
        rowKey={() => getRandomAlphaNumericString(24)}
        columns={columns}
        dataSource={data.slice().sort((a, b) => a.percentage - b.percentage)}
        loading={isLoading}
        summary={(data) => {
          const tallies = data.reduce(
            (acc, curr) => ({
              totalProducts: acc.totalProducts + (curr.totalProducts || 0),
              totalProductsWith0Packaging:
                acc.totalProductsWith0Packaging +
                (curr.totalProductsWith0Packaging || 0),
            }),
            {
              totalProducts: 0,
              totalProductsWith0Packaging: 0,
            },
          );
          const { totalProducts = 0, totalProductsWith0Packaging = 0 } =
            tallies;
          const percentage = getPercentage(
            totalProducts - totalProductsWith0Packaging,
            totalProducts,
          );

          return (
            <Table.Summary.Row
              className="font-bold"
              style={{ backgroundColor: '#fafafa' }}
            >
              <Table.Summary.Cell index={0}>Summary</Table.Summary.Cell>
              <Table.Summary.Cell index={1} className="text-center">
                {totalProducts || 0}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} className="text-center">
                {(totalProducts || 0) - (totalProductsWith0Packaging || 0)}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} className="text-center">
                {totalProductsWith0Packaging || 0}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <div className="relative w-full text-center">
                  <div className="relative z-50">{percentage}%</div>
                  <div
                    className="absolute top-0 h-full bg-green-500 border border-green-600"
                    style={{ width: `${percentage}%` }}
                  />
                </div>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ['10', '100', '250', '500'],
          style: { marginRight: '4px' },
        }}
        scroll={{ x: true }}
      />
    </div>
  );
};

export default SupplierPortfolio;
