import PageContainer from '../Layout';
import BulkPackagingList from './BulkPackagingList';

const BulkPackaging = () => {
  return (
    <PageContainer activeTab="bulk-packaging">
      <BulkPackagingList />
    </PageContainer>
  );
};

export default BulkPackaging;
