import { InfoCircleFilled } from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Popconfirm,
  Popover,
  Row,
  Select,
  Space,
  Switch,
  message,
} from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsTrash } from 'react-icons/bs';

import { PackagingItemsService } from '../../../../../api/services';
import { useAuth } from '../../../../../hooks/useAuth';
import {
  CategoryType,
  MaterialItem,
  Packaging,
  SeparableItem,
} from '../../../../../types';
import {
  MaterialCategories,
  PackagingStatuses,
  getAccountTitle,
  getNEACategories,
  getRandomAlphaNumericString,
} from '../../../../../utils';
import { useAssessmentMethods } from '../../../../BulkPackaging/BulkPackagingEditor/Components/useAssessmentMethods';
import PackagingComments from './Comments';
import PackagingEvidenceFiles from './Evidence';
import { useMaterialItems } from './useMaterialItems';

type CategoryState = {
  [category in CategoryType]: MaterialItem[];
};

interface OverviewProps {
  editedRecord: Packaging;
  readOnly: boolean;
  handleSave: (packaging: Partial<Packaging>) => void;
  tab: string;
}

const getEmptySeparableItem = () =>
  ({
    _id: `NEW-${getRandomAlphaNumericString(20)}`,
    description: undefined,
    category: undefined,
    materialItemId: undefined,
    neaPackagingForm: undefined,
    subitemsCount: 1,
    isReportable: true,
  } as unknown as SeparableItem);

const Overview = ({
  editedRecord,
  readOnly,
  tab,
  handleSave,
}: OverviewProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const [packagingForm] = Form.useForm<Packaging>();
  const [itemsForm] = Form.useForm<SeparableItem>();

  const { data: materialItems } = useMaterialItems();
  const { data: methodologies } = useAssessmentMethods();

  const [state, setState] = useState({
    items: [] as SeparableItem[],
    showEvidenceNeededInfo: false,
  });

  useEffect(() => {
    const {
      _id,
      title,
      description,
      statuses = [],
      assessmentComment,
      assessmentMethodId,
      originalSources,
      otherSource,
    } = editedRecord;
    packagingForm.setFieldsValue({
      _id,
      title,
      description,
      statuses,
      assessmentComment,
      assessmentMethodId,
      originalSources,
      otherSource,
    });
  }, [tab]);

  useEffect(() => {
    let { separableItems = [] } = editedRecord;
    if (separableItems?.length === 0) {
      separableItems = [getEmptySeparableItem()];
    }
    const items = separableItems.reduce(
      (acc, curr) => ({ ...acc, [curr._id]: curr }),
      {},
    );
    itemsForm.setFieldsValue(items);
    setState((old) => ({ ...old, items: separableItems }));
  }, [editedRecord._id]);

  const handleSubmit = () => {
    packagingForm.validateFields().then((generalFormData) => {
      // console.log('generalFormData', generalFormData);
      itemsForm
        .validateFields()
        .then((items) => {
          console.log('items', items);
          handleSave({
            ...generalFormData,
            separableItems: Object.values(items).map(
              ({ _id, ...restOfItem }) => restOfItem,
            ),
          });
        })
        .catch(() => {
          console.log('save form data only, because items are invalid');
          handleSave({
            ...generalFormData,
          });
        });
    });
  };

  const handleCategoryChange = (itemId: string) => {
    const value = itemsForm.getFieldsValue([itemId]) || {};
    itemsForm.setFieldsValue({
      [itemId]: {
        ...value,
        neaPackagingForm: undefined,
        materialItemId: undefined,
      } as SeparableItem,
    });
  };

  const addSeparableItem = () => {
    const newItem = getEmptySeparableItem();
    const { _id, ...rest } = newItem;
    itemsForm.setFieldsValue({ [_id]: rest });
    setState((old) => ({
      ...old,
      items: [...old.items, newItem],
    }));
  };
  const removeSeparableItem = (itemId: string) => {
    if (readOnly) {
      return;
    }
    if (itemId.startsWith('NEW')) {
      setState((old) => ({
        ...old,
        items: old.items.filter((item) => item._id !== itemId),
      }));
    } else {
      PackagingItemsService.remove(itemId).then(
        () => {
          setState((old) => ({
            ...old,
            items: old.items.filter((item) => item._id !== itemId),
          }));
        },
        (error: Error) => {
          message.error(t('packagingEditor.overview.removeErr'));
          console.log('Error in removing packaging item: ', error);
        },
      );
    }
  };
  const handleItemsChange = (changes: any, allFields: any) => {
    if (readOnly || !editedRecord._id) {
      handleSubmit();
      console.log('ignored items change');
      return;
    }
    const [fieldId = ''] = Object.entries(changes)?.[0];
    const mustBeValid = Object.keys(getEmptySeparableItem()).map(
      (fieldName) => [fieldId, fieldName],
    );
    itemsForm
      .validateFields(mustBeValid)
      .then((values) => {
        const { _id, ...rest } = Object.values(values)[0] as SeparableItem;
        if (fieldId.startsWith('NEW')) {
          PackagingItemsService.create({
            ...rest,
            packagingId: editedRecord._id,
          }).then(
            (res: SeparableItem) => {
              itemsForm.setFieldsValue({ [res._id]: res });
              setState((old) => ({
                ...old,
                items: old.items.map((item) =>
                  item._id === fieldId ? res : item,
                ),
              }));
            },
            (e: Error) => {
              console.log('Error in creating packaging item');
              message.error(t('packagingEditor.overview.creatingErr'));
            },
          );
        } else {
          PackagingItemsService.patch(fieldId, rest).then(
            (res: SeparableItem) => {
              setState((old) => ({
                ...old,
                items: old.items.map((item) =>
                  item._id === res._id ? res : item,
                ),
              }));
            },
            (error: Error) => {
              console.log('Error in saving packaging item: ', error);
              message.error(t('packagingEditor.overview.savingErr'));
            },
          );
        }
      })
      .catch(() => console.log('Field invalid to quick save'));
  };

  return (
    <>
      <section>
        <Row gutter={16}>
          <Col span={editedRecord._id ? 14 : 24}>
            <Form
              layout="vertical"
              form={packagingForm}
              name="packaging-form"
              onValuesChange={debounce(handleSubmit, 500)}
              requiredMark={false}
            >
              <Form.Item name="_id" hidden noStyle>
                <Input />
              </Form.Item>
              <Row gutter={16}>
                <Col span={16}>
                  <Form.Item
                    label={t('packagingEditor.overview.form.name')}
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: t('packagingEditor.overview.form.nameMsg'),
                      },
                    ]}
                  >
                    <Input
                      disabled={readOnly}
                      placeholder={t(
                        'packagingEditor.overview.form.namePlaceholder',
                      )}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('packagingEditor.overview.form.desc')}
                    name="description"
                  >
                    <Input.TextArea
                      disabled={readOnly}
                      placeholder={t(
                        'packagingEditor.overview.form.descPlaceholder',
                      )}
                      rows={7}
                      style={{ resize: 'none' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={t('packagingEditor.overview.form.status')}
                    labelCol={{ span: 24 }}
                    name="statuses"
                  >
                    <Checkbox.Group
                      onChange={(checkedValues: CheckboxValueType[]) => {
                        if (
                          checkedValues.length === 0 ||
                          (checkedValues.length === 1 &&
                            checkedValues.includes('redFlag'))
                        ) {
                          setState((old) => ({
                            ...old,
                            showEvidenceNeededInfo: false,
                          }));
                        } else {
                          setState((old) => ({
                            ...old,
                            showEvidenceNeededInfo: true,
                          }));
                        }
                      }}
                    >
                      <Space direction="vertical">
                        {PackagingStatuses.map((item) => (
                          <Checkbox
                            disabled={readOnly}
                            key={item.value}
                            value={item.value}
                          >
                            {item.label}
                          </Checkbox>
                        ))}
                        {state.showEvidenceNeededInfo && (
                          <p className="text-red">
                            Please make sure that details for proxies and
                            assumptions are provided in the evidence section
                            below
                          </p>
                        )}
                      </Space>
                    </Checkbox.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          {editedRecord._id ? (
            <Col span={10}>
              <PackagingComments
                packagingId={editedRecord?._id}
                readOnly={readOnly}
              />
            </Col>
          ) : null}
        </Row>
      </section>

      <section className="mt-5">
        <div className="mb-3 align-btn-icon">
          {t('packagingEditor.overview.separableItems')}
          <Popover
            title={
              <div className="font-bold">
                {t('packagingEditor.overview.separableItemsTitle')}
              </div>
            }
            content={
              <div style={{ maxWidth: '300px' }}>
                <p>{t('packagingEditor.overview.separableItemsTxt-1')}</p>
                <p>{t('packagingEditor.overview.separableItemsTxt-2')}</p>
              </div>
            }
            placement="top"
            autoAdjustOverflow
          >
            <InfoCircleFilled
              className="ml-2 text-lg cursor-pointer"
              style={{ color: '#92d050' }}
            />
          </Popover>
        </div>
        <Row gutter={16} className="mb-2">
          <Col span={3}>{t('packagingEditor.overview.materialCateg')}</Col>
          <Col span={3} className="align-btn-icon">
            <Button
              type="link"
              href="https://zerowastecity.com/wp-content/uploads/2021/09/MPR-Portal-Item-names.pdf"
              target="_blank"
              rel="noreferrer"
              style={{ padding: 0, lineHeight: 'unset' }}
            >
              {t('packagingEditor.overview.item')}
            </Button>
            <Popover
              title={
                <div className="font-bold">
                  {t('packagingEditor.overview.item')}
                </div>
              }
              content={
                <div style={{ maxWidth: '300px' }}>
                  <p>{t('packagingEditor.overview.txt-1')}</p>
                </div>
              }
              placement="top"
              autoAdjustOverflow
            >
              <InfoCircleFilled
                className="ml-2 cursor-pointer"
                style={{ color: '#92d050' }}
              />
            </Popover>
          </Col>
          <Col span={5}>{t('packagingEditor.overview.neaPkg')}</Col>
          <Col span={4} className="align-btn-icon">
            <Popover
              title={
                <div className="font-bold">
                  {t('packagingEditor.overview.subItems')}
                </div>
              }
              content={
                <div style={{ maxWidth: '300px' }}>
                  <p>{t('packagingEditor.overview.text-2')}</p>
                  <span>{t('packagingEditor.overview.text-3')}</span>
                  <ul style={{ listStyleType: 'disc', marginLeft: '16px' }}>
                    <li>{t('packagingEditor.overview.6-bottles')}</li>
                    <li>{t('packagingEditor.overview.6-caps')}</li>
                    <li>{t('packagingEditor.overview.1-box')}</li>
                  </ul>
                </div>
              }
              placement="top"
              autoAdjustOverflow
            >
              {t('packagingEditor.overview.subItems')}
              <InfoCircleFilled
                className="ml-2 cursor-pointer"
                style={{ color: '#92d050' }}
              />
            </Popover>
          </Col>
          <Col span={6}>{t('packagingEditor.overview.description')}</Col>
          <Col span={2}>{t('Reportable')}</Col>
        </Row>
        <Form
          form={itemsForm}
          onValuesChange={(changes, all) => {
            // console.log('Changes: ', changes);
            //TODO we  can move  this debounce elsewhere
            debounce(handleItemsChange, 500)(changes, all);
          }}
          scrollToFirstError
        >
          {state.items.map(({ _id }) => (
            <Row gutter={16} key={_id}>
              <Form.Item name={[_id, '_id']} hidden noStyle>
                <Input />
              </Form.Item>
              <Col span={3}>
                <Form.Item
                  className="mb-0"
                  name={[_id, 'category']}
                  rules={[
                    {
                      required: true,
                      message: t('packagingEditor.overview.form.categoryMsg'),
                    },
                  ]}
                >
                  <Select
                    placeholder={t('packagingEditor.overview.form.category')}
                    onChange={() => handleCategoryChange(_id)}
                    optionFilterProp="children"
                    className="m-capitalize"
                    showSearch
                    disabled={readOnly}
                  >
                    {MaterialCategories.map((item) => (
                      <Select.Option
                        value={item}
                        key={item}
                        className="m-capitalize"
                      >
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item className="mb-1" noStyle shouldUpdate>
                  {({ getFieldValue }) => (
                    <Form.Item
                      className="mb-0"
                      name={[_id, 'materialItemId']}
                      rules={[
                        {
                          required: true,
                          message: t('packagingEditor.overview.form.itemMsg'),
                        },
                      ]}
                    >
                      <Select
                        placeholder={t('packagingEditor.overview.form.item')}
                        optionFilterProp="children"
                        showSearch
                        disabled={readOnly}
                      >
                        {(
                          materialItems?.[
                            getFieldValue([_id, 'category']) as CategoryType
                          ] || []
                        )
                          .sort()
                          .map((materialItem) => (
                            <Select.Option
                              key={materialItem._id}
                              value={materialItem._id}
                            >
                              {materialItem.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item className="mb-1" shouldUpdate>
                  {({ getFieldValue }) => (
                    <Form.Item
                      className="mb-0"
                      name={[_id, 'neaPackagingForm']}
                      rules={[
                        {
                          required: true,
                          message: t('packagingEditor.overview.form.neaMsg'),
                        },
                      ]}
                      noStyle
                    >
                      <Select
                        placeholder={t('packagingEditor.overview.form.nea')}
                        showSearch
                        disabled={readOnly}
                      >
                        {getNEACategories(getFieldValue([_id, 'category'])).map(
                          (item: string) => (
                            <Select.Option
                              key={item.toLowerCase()}
                              value={item.toLowerCase()}
                            >
                              {item}
                            </Select.Option>
                          ),
                        )}
                      </Select>
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  className="mb-1"
                  name={[_id, 'subitemsCount']}
                  rules={[
                    {
                      required: true,
                      message: t('packagingEditor.overview.form.subItemsMsg'),
                    },
                  ]}
                  initialValue={1}
                >
                  <InputNumber
                    disabled={readOnly}
                    min={1}
                    style={{ width: '100%' }}
                    placeholder={t('packagingEditor.overview.form.subItems')}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item className="mb-1" name={[_id, 'description']}>
                  <Input
                    disabled={readOnly}
                    placeholder={t(
                      'packagingEditor.overview.form.optionalDesc',
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item
                  className="mb-1"
                  name={[_id, 'isReportable']}
                  valuePropName="checked"
                >
                  <Switch
                    disabled={readOnly}
                    checkedChildren="Yes"
                    unCheckedChildren="No"
                  />
                </Form.Item>
              </Col>
              <Col span={1}>
                <Popconfirm
                  title={t('packagingEditor.overview.form.popConfirm')}
                  onConfirm={() => removeSeparableItem(_id)}
                  placement="topLeft"
                  disabled={readOnly}
                >
                  <Button
                    type="text"
                    hidden={state.items.length === 1}
                    disabled={readOnly}
                  >
                    <BsTrash />
                  </Button>
                </Popconfirm>
              </Col>
            </Row>
          ))}
          <Button
            type="primary"
            onClick={addSeparableItem}
            className="mt-4"
            disabled={readOnly}
          >
            {t('packagingEditor.overview.form.addMore')}
          </Button>
        </Form>
      </section>
      <section className="mt-5">
        <h2 className="text-lg font-bold">
          {t('packagingEditor.overview.evidenceSection')}
          <span
            style={{
              backgroundColor:
                editedRecord.assessmentMethodId &&
                (editedRecord.evidenceCount > 0 ||
                  editedRecord.assessmentComment)
                  ? '#0b0'
                  : '#e00',
            }}
            className="px-3 py-1 ml-4 text-white rounded-2xl"
          >
            {editedRecord.assessmentMethodId &&
            (editedRecord.evidenceCount > 0 || editedRecord.assessmentComment)
              ? t('packagingEditor.hasEvidence')
              : t('packagingEditor.missingEvidence')}
          </span>
        </h2>
        <Row gutter={16}>
          <Col span={14}>
            <Form
              layout="vertical"
              form={packagingForm}
              name="packaging-form"
              onValuesChange={debounce(handleSubmit, 500)}
              requiredMark={false}
            >
              <Form.Item
                label={
                  <>
                    {t('packagingEditor.overview.assessmentMethodLabel')} &nbsp;
                    <Button
                      type="link"
                      href="https://zerowastecity.com/wp-content/uploads/2021/09/MPR-Portal-Assessment-method.pdf"
                      target="_blank"
                      style={{ padding: 0 }}
                      className="flex items-center align-btn-icon"
                    >
                      {t('packagingEditor.overview.assessmentMethod')}{' '}
                      <InfoCircleFilled style={{ color: '#92d050' }} />
                    </Button>
                  </>
                }
                name="assessmentMethodId"
              >
                <Select
                  placeholder={t(
                    'packagingEditor.overview.form.selectAssessmentPlaceholder',
                  )}
                  optionFilterProp="children"
                  showSearch
                  allowClear
                  disabled={readOnly}
                  onClear={() => {
                    console.log('clear assessment method');
                    handleSave({
                      assessmentMethodId: null,
                    });
                  }}
                >
                  {methodologies?.map((item: any) => (
                    <Select.Option key={item._id} value={item._id}>
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={t('packagingEditor.overview.form.comment')}
                name="assessmentComment"
              >
                <Input.TextArea
                  disabled={readOnly}
                  placeholder={t(
                    'packagingEditor.overview.form.commentPlaceholder',
                  )}
                  allowClear
                />
              </Form.Item>
              <div className="mb-2">
                What is the original source of the information (aka. where do
                the evidence come from)?
              </div>
              <Form.Item name="originalSources" noStyle>
                <Checkbox.Group
                  disabled={readOnly}
                  options={[
                    { label: getAccountTitle(auth), value: 'owner' },
                    {
                      label: 'Supplier or Manufacturer',
                      value: 'supplier',
                    },
                    { label: 'ZWC', value: 'zwc' },
                    { label: 'Third party', value: 'otherSource' },
                  ]}
                />
              </Form.Item>
              <Form.Item name="otherSource" noStyle>
                <Input
                  disabled={readOnly}
                  style={{ display: 'inline', width: '190px' }}
                  placeholder="Enter third party's name"
                />
              </Form.Item>
            </Form>
          </Col>
          <Col span={10}>
            <PackagingEvidenceFiles
              handleSave={() => {
                console.log('handle evidence save');
              }}
              readOnly={readOnly}
              packaging={editedRecord}
            />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Overview;
