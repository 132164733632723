function extractMentions(str: string) {
  const re = /\B@\w+/g;
  const res = str.match(re);
  return res;
}

export function findAndReplaceMentions(str: string, usersMap: any) {
  let output = str.slice();

  const res = extractMentions(str);

  if (res?.length) {
    res.forEach((term) => {
      let replacement = (usersMap[term.slice(1)] || {}).firstName;
      if (replacement) {
        replacement = `@${replacement}`;
      } else {
        replacement = term;
      }
      output = output.replaceAll(term, replacement);
    });
  }

  return output;
}
