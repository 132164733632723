import React from 'react';

import { useAuth } from '../../hooks/useAuth';
import { isMasterAccount } from '../../utils';
import PageContainer from '../Layout';
import CompanyDashboard from './CompanyDashboard';
import MasterDashboard from './MasterDashboard';

const Dashboard = () => {
  const auth = useAuth();

  return (
    <PageContainer activeTab="dashboard">
      {isMasterAccount(auth) ? <MasterDashboard /> : <CompanyDashboard />}
    </PageContainer>
  );
};

export default Dashboard;
