import { Select } from 'antd';
import { SelectProps } from 'antd/es/select';
import { countries } from 'countries-list';
import React, { useEffect, useState } from 'react';

interface CountrySelectProps extends SelectProps<string> {}

const CountrySelect = (props: CountrySelectProps) => {
  return (
    <Select className="w-full" {...props} showSearch>
      {Object.values(countries).map((country) => (
        <Select.Option key={country.name} value={country.name}>
          <span className="mr-3">{country.emoji}</span>
          <span>{country.name}</span>
        </Select.Option>
      ))}
    </Select>
  );
};

export default CountrySelect;
