import { AppstoreAddOutlined } from '@ant-design/icons';
import { List } from 'antd';
import { useTranslation } from 'react-i18next';

import { calcPercentage } from './calcPercentage';
import { ProductsStatsReport } from './useProductStats';

const ProductOverviewStats = ({
  productsStats,
  isLoading,
}: {
  isLoading: boolean;
  productsStats?: ProductsStatsReport;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <List
        header={
          <div className="flex justify-between text-lg font-bold">
            <span>{t('dashboard.companyPage.stats.product.title')}</span>
            <AppstoreAddOutlined style={{ color: 'blue' }} />
          </div>
        }
        rowKey="_id"
        className="bg-white m-list-bgc"
        loading={isLoading}
        bordered
      >
        <List.Item>
          {`${productsStats?.totalProducts} ${t(
            'dashboard.companyPage.stats.product.totalProducts',
          )}`}
        </List.Item>
        <List.Item>
          {calcPercentage(
            productsStats?.totalProductsWith1Packaging || 0,
            productsStats?.totalProducts || 0,
          )}
          % of products linked to 1 packaging (
          {productsStats?.totalProductsWith1Packaging || 0})
        </List.Item>
        <List.Item>
          {calcPercentage(
            productsStats?.totalProductsWith2Packaging || 0,
            productsStats?.totalProducts || 0,
          )}
          % of products linked to 2 packaging (
          {productsStats?.totalProductsWith2Packaging || 0})
        </List.Item>
        <List.Item>
          {calcPercentage(
            productsStats?.totalProductsWith3PlusPackaging || 0,
            productsStats?.totalProducts || 0,
          )}
          % of products linked to 3+ packaging (
          {productsStats?.totalProductsWith3PlusPackaging || 0})
        </List.Item>
        <List.Item>
          {calcPercentage(
            productsStats?.totalProductsWith0Packaging || 0,
            productsStats?.totalProducts || 0,
          )}
          % of products not linked to packaging (
          {productsStats?.totalProductsWith0Packaging || 0})
        </List.Item>
      </List>
    </>
  );
};

export default ProductOverviewStats;
