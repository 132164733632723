import { ShopOutlined } from '@ant-design/icons';
import { List, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CompaniesService } from '../../../api/services';
import { Company, Paginated, Supplier } from '../../../types';

const statKeys = ['clients', 'suppliers', 'pendingApproval'];

const AccountStats = () => {
  const [state, setState] = useState({
    isLoading: false,
    clients: 0,
    suppliers: 0,
    pendingApproval: 0,
  });
  const { t } = useTranslation();

  useEffect(() => {
    setState((old) => ({ ...old, isLoading: true }));

    let promises = [] as Promise<Paginated<Company | Supplier>>[];

    promises.push(
      CompaniesService.find({
        query: { category: 'client', $limit: 0 },
      }),
    );

    promises.push();

    Promise.allSettled(promises).then(
      (res) => {
        let error = false;
        const stats = res.reduce((acc, curr, index) => {
          if (curr.status === 'fulfilled') {
            return { ...acc, [statKeys[index]]: curr.value.total };
          } else {
            error = true;
            console.log(
              t('dashboard.masterPage.messages.error') + ':',
              curr.reason,
            );
            return { ...acc, [statKeys[index]]: 0 };
          }
        }, {} as typeof state);

        if (error) {
          message.error(t('dashboard.masterPage.messages.error'));
        }
        setState((old) => ({ ...old, ...stats, isLoading: false }));
      },
      () => null,
    );
  }, []);

  return (
    <List
      className="bg-white"
      header={
        <div className="flex justify-between text-lg font-bold">
          <span> {t('dashboard.masterPage.stats.title')}</span>
          <ShopOutlined style={{ color: 'blue' }} />
        </div>
      }
      bordered
      loading={state.isLoading}
    >
      <List.Item>
        {state.clients} {t('dashboard.masterPage.stats.company.client')}{' '}
      </List.Item>

      <List.Item
        style={
          (state?.pendingApproval || 0) > 0
            ? { color: 'red', fontWeight: 'bold' }
            : {}
        }
      >
        {state.pendingApproval}{' '}
        {t('dashboard.masterPage.stats.company.pendingApproval')}
      </List.Item>
    </List>
  );
};

export default AccountStats;
