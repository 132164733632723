import { PropsWithChildren, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { UserReportingPeriodsService } from '../api/services';
import {
  useActiveReportingPeriod,
  useActiveReportingPeriodDispatch,
} from '../hooks/useActiveReportingPeriod';
import { useAuth } from '../hooks/useAuth';
import { getCurrentCompanyId } from './getCurrentCompanyId';

function getUserReportingPeriod({ queryKey }: any) {
  const [, userReportingPeriodId] = queryKey;

  return UserReportingPeriodsService.get(userReportingPeriodId);
}

export function ReportingPeriodProvider({ children }: PropsWithChildren<{}>) {
  const { user, isLoggedIn } = useAuth();

  const [fetchFromServer, setFetchFromServer] = useState(false);
  let companyId = getCurrentCompanyId(user);
  const sessionDispatch = useActiveReportingPeriodDispatch();
  const { reportingPeriod } = useActiveReportingPeriod();
  const history = useHistory();
  const location = useLocation();

  useQuery(
    ['user-reporting-period', `${companyId}:${user && user._id}`],
    getUserReportingPeriod,
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        sessionDispatch({
          type: 'set_session',
          payload: { reportingPeriod: res.reportingPeriod },
        });
        if (location.pathname.startsWith('/reporting-periods')) {
          history.push('/');
        }
      },
      onError: () => {
        console.log(
          'no active reporting period, nor last active reporting period',
        );
        sessionDispatch({
          type: 'reset session',
        });
      },
      retry: false,
      enabled: fetchFromServer && !!companyId,
    },
  );

  useEffect(() => {
    if (!isLoggedIn) {
      sessionDispatch({ type: 'reset session' });
      return;
    }
    const viewAs = localStorage.getItem('view-as');

    if (reportingPeriod?._id) {
      // check if its zwc user then see if viewAs is same
      if (user?.role.startsWith('zwc')) {
        if (!viewAs) {
          console.log('clearing');
          sessionDispatch({ type: 'reset session' });
        } else if (reportingPeriod.companyId !== viewAs) {
          sessionDispatch({ type: 'reset session' });
          setFetchFromServer(true);
        }
      } else {
        console.log('found existing active reporting period', reportingPeriod);
        sessionDispatch({ type: 'set_session', payload: { reportingPeriod } });
      }
    } else {
      console.log(
        'no active reporting period, getting last active reporting period from server',
      );
      if (user?.role.startsWith('zwc') && !viewAs) {
        console.log('no need to fetch rp');
        return;
      }
      setFetchFromServer(true);
    }
  }, [user?._id, companyId, isLoggedIn]);

  return <>{children}</>;
}
