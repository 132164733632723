export function groupSimilar(
  arr: any[] = [],
  isSimilar: (prev: any, curr: any) => boolean,
) {
  return arr.reduce((a = [], c) => {
    const lastElement = a[a.length - 1] || {};
    if (isSimilar(lastElement, c)) {
      if (!lastElement.hasGroup) {
        lastElement.hasGroup = true;
        lastElement.grouped = lastElement.grouped || [];
      }
      if (!lastElement.grouped.includes(c._id)) {
        lastElement['grouped'].push(c._id);
      }
      a.pop();
      return [...a, lastElement];
    }
    return [...a, c];
  }, []);
}
