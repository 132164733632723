import { KeyOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Form, Input, Row } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import { login } from '../../api/authentication';
import logo from '../../assets/logo/zwc_logo.svg';
import { useAuth, useAuthDispatch } from '../../hooks/useAuth';

const Login = () => {
  const { isLoading, isLoggedIn, error } = useAuth();
  const dispatch = useAuthDispatch();
  const { t } = useTranslation();

  const handleFinish = (values: any) => {
    dispatch({ type: 'login start' });
    login(values)
      .then((res) => {
        dispatch({ type: 'login success', payload: res });
      })
      .catch((err) => {
        dispatch({ type: 'login fail', error: err });
      });
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        dispatch({ type: 'reset state' });
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [error, dispatch]);

  if (isLoggedIn) {
    return <Redirect to={'/'} />;
  }

  return (
    <div className="relative min-h-screen">
      <div className="flex pt-4">
        <div className="mx-auto">
          <div>
            <img className="m-auto" src={logo} alt="ZWC Logo" width="100px" />
          </div>
          <h1
            className="font-sans font-bold uppercase sm:text-base md:text-2xl lg:text-3xl"
            style={{ color: '#5d96e9' }}
          >
            {t('loginPage.title')}
          </h1>
        </div>
      </div>

      <Row justify="space-around" align="middle" className="pb-20">
        <Col xs={20} sm={20} md={16} lg={11} xl={7}>
          <div
            className="p-4 mt-5 text-white rounded"
            style={{
              backgroundColor: '#6ac7bd',
              boxShadow:
                'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px',
            }}
          >
            <h1 className="mb-4 text-3xl font-bold text-center text-white">
              {t('loginPage.form.title')}
            </h1>
            <Form onFinish={handleFinish} layout="vertical">
              {error ? (
                <Alert
                  message={t('loginPage.alertMessage.error')}
                  className="mb-5"
                  type="warning"
                  showIcon
                />
              ) : null}
              <Form.Item
                name="email"
                label={
                  <span className="font-semibold tracking-wider text-white">
                    {t('loginPage.form.email')}
                  </span>
                }
              >
                <Input
                  placeholder={t('loginPage.form.email')}
                  prefix={<UserOutlined />}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label={
                  <span className="font-semibold tracking-wider text-white">
                    {t('loginPage.form.password')}
                  </span>
                }
              >
                <Input.Password
                  placeholder={t('loginPage.form.password')}
                  prefix={<KeyOutlined />}
                />
              </Form.Item>
              <Row justify="space-between" align="middle" gutter={16}>
                {/* <Col>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>
                      <span className="font-semibold tracking-wider text-white">
                        Remember me
                      </span>
                    </Checkbox>
                  </Form.Item>
                </Col> */}
                <Col>
                  <Button
                    type="link"
                    href="forgot-password"
                    style={{ padding: 0 }}
                  >
                    <span style={{ fontWeight: 'bold' }}>
                      {t('loginPage.form.forgetPassLink')}
                    </span>
                  </Button>
                </Col>
              </Row>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="mt-2 font-bold uppercase"
                  loading={isLoading}
                  disabled={isLoading}
                  block
                >
                  {t('loginPage.form.title')}
                </Button>
              </Form.Item>
            </Form>

            <p className="font-bold text-center">
              {t('loginPage.form.terms&conditionTxt')} &nbsp;
              <a
                href="https://zerowastecity.com/wp-content/uploads/2021/09/MPR-Portal-Terms-and-Conditions.pdf"
                target="_blank"
                rel="noreferrer"
              >
                {t('loginPage.form.terms&conditionlink')}
              </a>
            </p>
          </div>
        </Col>
        <Col xs={20} sm={20} md={16} lg={11} xl={10} className="m-mt-video">
          <iframe
            width="100%"
            className="m-video"
            src="https://www.youtube.com/embed/Ho4WVnqgSUg"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Col>
      </Row>
      <Row
        style={{
          bottom: '10px',
          color: 'cyan',
          position: 'absolute',
          width: '100%',
        }}
        justify="center"
      >
        <Col xs={8} md={4} lg={2}>
          &copy; ZWC {new Date().getFullYear()}
        </Col>
      </Row>
    </div>
  );
};

export default Login;
