import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Space,
  message,
} from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import { ReportingPeriodsService } from '../../api/services';
import {
  useActiveReportingPeriod,
  useActiveReportingPeriodDispatch,
} from '../../hooks/useActiveReportingPeriod';
import { ReportingPeriod } from '../../types';

export interface CreateReportingPeriodProps {
  reportingPeriods: ReportingPeriod[];
}

const { TextArea } = Input;
interface ReportingPeriodForm extends ReportingPeriod {
  setAsActive: boolean;
}
const CreateReportingPeriodForm = ({
  reportingPeriods,
}: CreateReportingPeriodProps) => {
  const { t } = useTranslation();
  const [form] = useForm<ReportingPeriodForm>();
  const sessionDispatch = useActiveReportingPeriodDispatch();
  const { hasActiveReportingPeriod } = useActiveReportingPeriod();

  const { mutate, isLoading } = useMutation((values: ReportingPeriodForm) => {
    const { setAsActive, ...vals } = values;
    const reportingYear = (vals?.reportingYear as any)?.format('YYYY');
    console.log('reporting year ', reportingYear);

    return ReportingPeriodsService.create(
      { ...vals, reportingYear },
      {
        query: { setAsActive: setAsActive },
      },
    );
  });

  const queryClient = useQueryClient();

  const handleSave = () => {
    form.validateFields().then((values) => {
      mutate(values, {
        onSuccess: (res) => {
          queryClient.invalidateQueries(['latest-reporting-period']);
          console.log('reporting period response', res);
          if (values.setAsActive) {
            sessionDispatch({
              type: 'set_session',
              payload: { reportingPeriod: res as ReportingPeriod },
            });
          }
          message.info({
            content: t('Reporting period successfully created'),
            duration: 3,
          });
        },
        onError: (error) => {
          message.error(t('Error in creating repoting period'));
          console.log(error);
        },
        onSettled: () => {
          form.resetFields();
        },
      });
    });
  };

  const handleCancel = () => {
    form.resetFields();
  };

  const history = useHistory();

  return (
    <div className="p-3">
      {!hasActiveReportingPeriod && (
        <div className="p-5 mb-6 bg-red-500 text-white">
          {t(
            'Your access to portal is limited until you create/activate a reporting period',
          )}
        </div>
      )}
      <header className="mb-8">
        <div className="flex items-center">
          <div className="font-sans text-2xl font-bold md:text-3xl mr-auto">
            {t('Create a new reporting period')}
          </div>
          <Button
            onClick={() => {
              history.push('reporting-periods');
            }}
            type="primary"
            className="font-bold text-white"
          >
            {t('Back to reporting periods')}
          </Button>
        </div>
      </header>
      <main>
        <Form form={form} requiredMark={true}>
          <Row>
            <Col>
              <div className="pr-5 font-sans font-semibold text-base">
                {t('Select the year of reporting period')}:
              </div>
            </Col>
            <Col>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: t('Reporting year is required'),
                  },
                ]}
                name={'reportingYear'}
              >
                <DatePicker picker="year" />
              </Form.Item>
            </Col>
          </Row>
          <div className="mb-2">
            <Form.Item name="setAsActive" valuePropName="checked">
              <Checkbox>{t('Set as current active reporting period')}</Checkbox>
            </Form.Item>
          </div>
          <div className="mb-2">
            <Row>
              <Col>
                <div className="mr-2">{t('Remarks')}</div>
              </Col>
              <Col span={12}>
                <Form.Item name="remarks">
                  <Input.TextArea rows={5} maxLength={100} showCount />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="block font-sans text-1xmd mb-20">
            <Form.Item name="copyFromId">
              <Radio.Group defaultValue="">
                <Space direction="vertical">
                  <Radio value={''}>{t('Select a blank report year')}</Radio>
                  {reportingPeriods ? (
                    reportingPeriods.map((item: any) => (
                      <Radio key={item._id} value={item}>
                        {`Copy all data from
                          ${
                            item?.reportingYear
                          } reporting year (#${item?._id?.substr(-6)})`}
                      </Radio>
                    ))
                  ) : (
                    <></>
                  )}
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>

          <div className="flex items-center justify-end">
            <Button
              type="primary"
              disabled={isLoading}
              onClick={handleSave}
              className="font-bold text-white  mr-5"
            >
              {t('Save')}
            </Button>
            <Button
              type="primary"
              onClick={handleCancel}
              className="font-bold text-white"
            >
              {t('Cancel')}
            </Button>
          </div>
        </Form>
      </main>
    </div>
  );
};

export default CreateReportingPeriodForm;
