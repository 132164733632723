import './layout.css';

import {
  LogoutOutlined,
  MenuOutlined,
  ReadOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { logout } from '../../api/authentication';
import logo from '../../assets/logo/zwc_logo.svg';
import {
  useActiveReportingPeriod,
  useActiveReportingPeriodDispatch,
} from '../../hooks/useActiveReportingPeriod';
import { useAuth, useAuthDispatch } from '../../hooks/useAuth';
import { useLatestReportingPeriod } from '../../hooks/useLatestReportingPeriod';
import { getAccountTitle, switchedMasterAccount } from '../../utils';
import { NotificationsModal } from '../NotificationsModal';

const Navbar = () => {
  const { t } = useTranslation();
  const dispatch = useAuthDispatch();
  const auth = useAuth() || {};
  const sessionDispatch = useActiveReportingPeriodDispatch();
  const switchToMaster = () => {
    sessionDispatch({ type: 'reset session' });
    localStorage.removeItem('view-as');
    localStorage.removeItem('company-name');
    window.location.replace('/');
  };
  const { reportingPeriod } = useActiveReportingPeriod();
  const { reportingPeriod: latestReportingPeriod } = useLatestReportingPeriod();

  const handleLogout = () => {
    dispatch({ type: 'logout start' });
    logout()
      .then(() => {
        localStorage.removeItem('view-as');
        localStorage.removeItem('company-name');
        localStorage.removeItem('feathers-jwt');
        dispatch({ type: 'logout success' });
      })
      .catch((err) => dispatch({ type: 'logout fail', error: err }));
  };

  const lgMenu = (
    <div className="lgMenu">
      {switchedMasterAccount(auth) ? (
        <Button
          onClick={switchToMaster}
          type="link"
          className="m-nav-btns"
          title="Switch to master account"
        >
          {t('navbar.switchBackToMaster')}
        </Button>
      ) : null}
      <NotificationsModal />
      <Button
        type="link"
        className="m-nav-btns"
        title="Support"
        href="https://zerowastecity.com/mpr-portal-support/"
        target="_blank"
      >
        {t('navbar.support')}
      </Button>
      <Button
        onClick={handleLogout}
        type="link"
        className="pr-0 m-nav-btns"
        title="Logout"
      >
        {t('navbar.logout')}
      </Button>
    </div>
  );

  const mdMenu = (
    <div className="mdMenu">
      {switchedMasterAccount(auth) ? (
        <Button
          type="text"
          title="Switch to master account"
          className="m-nav-btns"
          onClick={switchToMaster}
        >
          <UserSwitchOutlined />
        </Button>
      ) : null}
      <NotificationsModal />
      <Button
        type="text"
        title="Support"
        className="m-nav-btns"
        href="https://zerowastecity.com/mpr-portal-support/"
        target="_blank"
      >
        <ReadOutlined />
      </Button>
      <Button
        type="text"
        className="pr-0 m-nav-btns"
        onClick={handleLogout}
        title="Logout"
      >
        <LogoutOutlined />
      </Button>
    </div>
  );

  const smMenu = (
    <div className="smMenu">
      <Dropdown
        overlay={
          <Menu theme="dark">
            {switchedMasterAccount(auth) ? (
              <Menu.Item onClick={switchToMaster} icon={<UserSwitchOutlined />}>
                {t('navbar.switchToMaster')}
              </Menu.Item>
            ) : null}
            <Menu.Item icon={<ReadOutlined />}>
              <a
                href="https://zerowastecity.com/mpr-portal-support/"
                target="_blank"
                rel="noreferrer"
              >
                {t('navbar.support')}
              </a>
            </Menu.Item>
            <Menu.Item onClick={handleLogout} icon={<LogoutOutlined />}>
              {t('navbar.logout')}
            </Menu.Item>
          </Menu>
        }
      >
        <MenuOutlined style={{ fontSize: '1.3rem', color: 'white' }} />
      </Dropdown>
    </div>
  );

  return (
    <>
      <Link to="/">
        <img src={logo} alt="ZWC Logo" width="64px" />
      </Link>
      <div className="nav-title flex">
        <div className="nav-title-text">{getAccountTitle(auth)}</div>
        {reportingPeriod && switchedMasterAccount(auth) && (
          <div className="ml-8 font-thin flex">
            <Tooltip title={reportingPeriod?._id.slice(-6)}>
              <span>
                Reporting Year: {reportingPeriod.reportingYear}{' '}
                {(reportingPeriod?.remarks || '').trim().length > 0
                  ? `(${reportingPeriod?.remarks})`
                  : ''}{' '}
              </span>
            </Tooltip>
            {reportingPeriod.isArchived && (
              <div className="ml-3">
                <Tag color="#f50">View Only Mode</Tag>
              </div>
            )}

            {reportingPeriod?._id === latestReportingPeriod?._id ? (
              <div className="ml-3">
                <Tag color="#0f0" style={{ color: 'black' }}>
                  Current
                </Tag>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
      </div>
      {lgMenu}
      {mdMenu}
      {smMenu}
    </>
  );
};

export default Navbar;
