import { useQuery } from 'react-query';

import { MaterialItemsService } from '../../../../../api/services';
import { MaterialItem, Paginated } from '../../../../../types';
import { MaterialCategories } from '../../../../../utils';

function findMaterialItems() {
  return MaterialItemsService.find().then(
    (res: Paginated<MaterialItem>) => res.data,
  );
}

function getFormMaterialItems(): { [key: string]: MaterialItem[] } {
  return findMaterialItems().then((res: MaterialItem[]) => {
    // make a map of result received
    const resMap = res.reduce(
      (a, c) => ({
        ...a,
        [c.category]: [...(a[c.category as string] || []), c],
      }),
      {} as any,
    );

    return MaterialCategories.reduce(
      (a, c) => ({
        ...a,
        [c]: (resMap[c] || []).sort((a: MaterialItem, b: MaterialItem) =>
          a?.name > b?.name ? 1 : -1,
        ),
      }),
      {},
    );
  });
}

export function useMaterialItems() {
  return useQuery(['material-items'], getFormMaterialItems);
}
