import { BulkPackaging } from '../../../../../types/index';
import EvidenceFileList from './EvidenceFilesList';
import EvidenceUploader from './EvidenceUploader';

interface PackagingEvidenceFilesProps {
  handleSave: (packaging: BulkPackaging) => void;
  packaging: BulkPackaging;
  readOnly: boolean;
}
interface UploadResponse {
  data: any;
}
export interface UploadedFile {
  mimeType?: string;
  url?: string;
  preview?: any;
  originFileObj?: File | Blob;
  status?: any;
  response?: UploadResponse;
  _id?: string;
  originalName?: string;
  uid: string;
  size: number;
  type: string;
  name: string;
  userId?: string;
  companyId?: string;
  id?: string;
}

const PackagingEvidenceFiles = ({
  handleSave,
  readOnly,
  packaging,
}: PackagingEvidenceFilesProps) => {
  return (
    <>
      {!readOnly && (
        <EvidenceUploader
          readOnly={readOnly}
          handleSave={handleSave}
          packaging={packaging}
        />
      )}
      <EvidenceFileList
        readOnly={readOnly}
        handleSave={handleSave}
        packaging={packaging}
      />
    </>
  );
};

export default PackagingEvidenceFiles;
