import { PackagingsService } from '../../../api/services';
import { Packaging } from '../../../types';

export function savePackaging(packaging: Partial<Packaging>) {
  const { _id, ...restData } = packaging;

  if (_id) {
    return PackagingsService.patch(_id, { ...restData });
  } else {
    return PackagingsService.create(restData);
  }
}
export function getPackaging({ queryKey }: any) {
  const [, _id] = queryKey;
  return PackagingsService.get(_id);
}
