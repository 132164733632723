import { useQuery } from 'react-query';

import { AssessmentMethodsService } from '../../../../api/services';
import { AssessmentMethod, Paginated } from '../../../../types';

function findAssessmentMethods(): AssessmentMethod[] {
  return AssessmentMethodsService.find({ query: { $limit: 500 } }).then(
    (res: Paginated<AssessmentMethod>) => res.data,
  );
}
export function useAssessmentMethods() {
  return useQuery(['assessment-methods'], findAssessmentMethods);
}
