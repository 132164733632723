import { useQuery } from 'react-query';

import { PackagingsService } from '../../../api/services';
import { useActiveReportingPeriod } from '../../../hooks/useActiveReportingPeriod';

export interface PackagingsStatsReport {
  inProgress: number;
  inProgressTotalLinked: number;
  inProgressRedFlag: number;
  inProgressProxy: number;
  inProgressAssumptions: number;
  completeWithEvidence: number;
  completeWithEvidenceTotalLinked: number;
  completeWithEvidenceRedFlag: number;
  completeWithEvidenceProxy: number;
  completeWithEvidenceAssumptions: number;
  completeWithoutEvidence: number;
  completeWithoutEvidenceTotalLinked: number;
  completeWithoutEvidenceRedFlag: number;
  completeWithoutEvidenceProxy: number;
  completeWithoutEvidenceAssumptions: number;
}
function getPackagingsStats({ queryKey }: any): PackagingsStatsReport {
  const [_, query = {}] = queryKey;

  return PackagingsService.find({
    query: {
      ...query,
      isArchived: { $ne: true },
      isBulkPackaging: { $ne: true },
      reportName: 'packagingsStats',
    },
  }).then((res: Array<PackagingsStatsReport>) => res[0]);
}

export function usePackagingsStats() {
  const { reportingPeriod } = useActiveReportingPeriod();
  const { data, ...restQuery } = useQuery(
    [
      'dashboard-company-packagings-stats',
      {
        reportingPeriodId: reportingPeriod?._id,
      },
    ],
    getPackagingsStats,
  );

  return { ...restQuery, data };
}
