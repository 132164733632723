import { Button, Col, Input, List, Popconfirm, Row, message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPencil, BsPlus, BsTrash } from 'react-icons/bs';

import { MaterialItemsService } from '../../api/services';
import { CategoryType, MaterialItem, Paginated } from '../../types';
import { MaterialCategories, getRandomAlphaNumericString } from '../../utils';

const INITIAL_STATE: {
  [category in CategoryType]: MaterialItem[];
} = {
  plastic: [],
  glass: [],
  metal: [],
  composite: [],
  wood: [],
  paper: [],
  other: [],
};

const MaterialsCategories = () => {
  const { t } = useTranslation();
  const [categories, setCategories] = useState(INITIAL_STATE);
  const [state, setState] = useState({
    isLoading: false,
    editingId: '',
    editedTitle: '',
  });

  const addNewSubcategory = (category: CategoryType) => {
    const _id = `NEW-${getRandomAlphaNumericString()}`;
    const newSub = { _id, category, subCategory: '' };
    setCategories((old) => ({
      ...old,
      [category]: [...(old?.[category] || []), newSub],
    }));
    setState((old) => ({ ...old, editingId: _id, editedTitle: '' }));
  };
  const cancelEditing = (category: CategoryType) => {
    setState((old) => ({ ...old, editedTitle: '', editingId: '' }));
    setCategories((old) => ({
      ...old,
      [category]: (old?.[category] || []).filter(
        (item) => !item._id.startsWith('NEW'),
      ),
    }));
  };
  const handleSave = (category: CategoryType) => {
    const { editedTitle: subCategory = '', editingId = '' } = state;
    if (editingId && subCategory) {
      if (editingId.startsWith('NEW')) {
        MaterialItemsService.create({ category, name: subCategory })
          .then(() => cancelEditing(category))
          .catch((error: Error) => {
            console.log('Error in creating material category: ', error);
            message.error(t('list.materialCategory.errors.creating'));
          });
      } else {
        MaterialItemsService.patch(editingId, { name: subCategory })
          .then(cancelEditing)
          .catch((error: Error) => {
            console.log('Error in updating material category: ', error);
            message.error(t('list.materialCategory.errors.updating'));
          });
      }
    } else {
      message.info(t('list.materialCategory.errors.info'));
    }
  };

  const removeSubcategory = (methodId: string) => {
    MaterialItemsService.remove(methodId).catch((error: Error) => {
      console.log('Could not detete material category: ', error);
      message.error(t('list.materialCategory.errors.deleting'));
    });
  };

  useEffect(() => {
    setState((old) => ({ ...old, isLoading: true }));
    MaterialItemsService.find({
      query: { $limit: 1000, $sort: { subCategory: 1 } },
    })
      .then(
        (res: Paginated<MaterialItem>) => {
          let data = {} as typeof INITIAL_STATE;
          MaterialCategories.forEach((category) => {
            data = Object.assign({}, data, {
              [category]: res.data.filter((item) => item.category === category),
            });
          });
          setCategories(data);
        },
        (error: Error) => {
          console.log('Error in fetching material categories: ', error);
          message.error(t('list.materialCategory.errors.fetching'));
        },
      )
      .finally(() => setState((old) => ({ ...old, isLoading: false })));
  }, []);
  useEffect(() => {
    const handleCreate = (res: MaterialItem) => {
      const { category } = res;
      setCategories((old) => ({
        ...old,
        [category]: [...(old?.[category] || []), res],
      }));
    };
    const handlePatch = (res: MaterialItem) => {
      const { category } = res;
      setCategories((old) => ({
        ...old,
        [category]: (old?.[category] || []).map((item) =>
          item._id !== res._id ? item : res,
        ),
      }));
    };

    const handleRemove = (res: MaterialItem) => {
      const { category } = res;
      setCategories((old) => ({
        ...old,
        [category]: (old?.[category] || []).filter(
          (item) => item._id !== res._id,
        ),
      }));
    };

    MaterialItemsService.on('created', handleCreate);
    MaterialItemsService.on('patched', handlePatch);
    MaterialItemsService.on('removed', handleRemove);
    return () => {
      MaterialItemsService.off('created', handleCreate);
      MaterialItemsService.off('patched', handlePatch);
      MaterialItemsService.off('removed', handleRemove);
    };
  }, []);

  return (
    <div
      className="flex items-start pb-4"
      style={{
        overflow: 'auto',
        height: 'calc(100vh - 287px)',
      }}
    >
      {MaterialCategories.map((category) => (
        <List
          style={{
            marginRight: '2rem',
          }}
          key={category}
          header={
            <div
              className="font-bold text-center capitalize"
              style={{ width: '20rem' }}
            >
              {category}
            </div>
          }
          dataSource={categories?.[category] || []}
          renderItem={(item) => (
            <List.Item className="flex items-center justify-between s-hover-parent">
              {state.editingId !== item._id ? (
                <>
                  <div className="w-8/12 truncate" title={item.name}>
                    {item.name}
                  </div>
                  <div className="s-hover-target">
                    <Button
                      type="text"
                      onClick={() =>
                        setState((old) => ({
                          ...old,
                          editedTitle: item.name,
                          editingId: item._id,
                        }))
                      }
                    >
                      <BsPencil className="text-blue-600" />
                    </Button>
                    <Popconfirm
                      title={t('list.materialCategory.deletePopupText')}
                      onConfirm={() => removeSubcategory(item._id)}
                      placement="topRight"
                      okText="Yes"
                      okButtonProps={{ type: 'primary', danger: true }}
                    >
                      <Button type="text">
                        <BsTrash className="text-red-500" />
                      </Button>
                    </Popconfirm>
                  </div>
                </>
              ) : (
                <Input
                  placeholder={t('list.materialCategory.inputTxt')}
                  value={state.editedTitle}
                  onChange={(e) =>
                    setState((old) => ({ ...old, editedTitle: e.target.value }))
                  }
                  onBlur={() => cancelEditing(category)}
                  onPressEnter={() => handleSave(category)}
                  autoFocus
                />
              )}
            </List.Item>
          )}
          footer={
            <Button
              type="primary"
              icon={<BsPlus className="inline mr-2 text-xl" />}
              onClick={() => addNewSubcategory(category)}
              block
            >
              {t('list.materialCategory.AddBtnText')}
            </Button>
          }
          loading={state.isLoading}
          bordered
        />
      ))}
    </div>
  );
};

export default MaterialsCategories;
