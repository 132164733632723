import { NotificationsService } from '../api/services';
import { AppNotification, Paginated } from '../types';

export function fetchNotifications({
  queryKey,
}: any): Promise<Array<AppNotification>> {
  const [_, notify] = queryKey;
  const preferredTypes = Object.entries(notify)
    .filter(([key, val]) => !!val)
    .map(([key]) => key);

  return NotificationsService.find({
    query: {
      $sort: { createdAt: -1 },
      $limit: 500,
      type: { $in: preferredTypes },
    },
  }).then((res: Paginated<AppNotification>) => res.data);
}
