import './ProductList.css';

import {
  DownOutlined,
  FilterOutlined,
  InfoCircleFilled,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Input,
  InputNumber,
  Menu,
  Popconfirm,
  Popover,
  Table,
  Tooltip,
  message,
} from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineLink, AiOutlinePlusCircle } from 'react-icons/ai';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import {
  PackagingLinkService,
  PackagingsService,
  ProductsService,
} from '../../api/services';
import exportRecords from '../../components/exportRecords';
import ImportPackagingDataRequests from '../../components/products/ImportPackagingDataRequest';
import ImportProducts from '../../components/products/ImportProducts';
import { useActiveReportingPeriod } from '../../hooks/useActiveReportingPeriod';
import { useAuth } from '../../hooks/useAuth';
import {
  Packaging,
  PackagingLink,
  Paginated,
  Product,
} from '../../types/index';
import {
  handleSorting,
  isMasterAccount,
  isObserverUser,
  setUpSorting,
  switchedMasterAccount,
} from '../../utils';
import DownloadExports from './DownloadExports';
import LinkPackaging from './LinkPackaging';
import ProductFilters, {
  ProductListFilters,
  areProductFiltersEmpty,
} from './ProductFilters';

export interface SelectionFiltersQuery {
  $multi_match?: { $query: string };
  ourSupplier?: string;
  packagingGroup?: string;
  packagingsCount?: number;
}
const getFiltersQuery = (
  filters: ProductListFilters,
): SelectionFiltersQuery => {
  let query = {};
  const {
    searchTerm = '',
    ourSupplier = '',
    packagingGroup = '',
    packagingsCount,
  } = filters;
  query = Object.assign({}, query, {
    $multi_match: { $query: (searchTerm || '').trim() },
  });
  if (ourSupplier.trim()) {
    query = Object.assign({}, query, { ourSupplier });
  }
  if (packagingGroup.trim()) {
    query = Object.assign({}, query, { packagingGroup });
  }
  if (packagingsCount !== null && packagingsCount !== undefined) {
    query = Object.assign({}, query, { packagingsCount });
  }
  return query;
};

const defaultLimit = +(localStorage.getItem('products-pageSize') || 50);
const INITIAL_STATE: Paginated<Product> = {
  data: [],
  total: 0,
  limit: defaultLimit,
  skip: 0,
};

function getTooltip(isCounting: boolean, matchedCount: number) {
  if (isCounting) {
    return 'Counting matched records';
  }

  if (matchedCount > 50) {
    return 'Packaging data request is limited by 50 products at a time';
  }
}

const ProductList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const auth = useAuth();
  const queryClient = useQueryClient();
  const { reportingPeriod, isViewOnlyMode } = useActiveReportingPeriod();

  // show the filter component
  const [showFilter, setShowFilter] = useState(false);
  const [selectedRow, setSelectedRow] = useState('');
  //For update function
  const editedRowID = useRef('');
  // For inputs
  const [totalQtySuppliedToSG, setTotalQtySuppliedToSG] = useState('');
  const [supplier, setSupplier] = useState('');
  const [packagingGroup, setPackagingGroup] = useState('');
  const [internalFieldId, setInternalFieldId] = useState('');
  const [iField1, setIField1] = useState('');
  const [iField2, setIField2] = useState('');
  const [iField3, setIField3] = useState('');

  const setRowValues = (data: any) => {
    editedRowID.current = data?._id;
    setTotalQtySuppliedToSG(data?.totalQtySuppliedToSG);
    setSupplier(data?.internalFields?.supplier);
    setPackagingGroup(data?.internalFields?.packagingGroup);
    setInternalFieldId(data?.internalFields?._id);
    setIField1(data?.internalFields?.field1);
    setIField2(data?.internalFields?.field2);
    setIField3(data?.internalFields?.field3);
  };

  const [state, setState] = useState({
    isImporting: false,
    isExporting: false,
    dataRequestDownloadVisible: false,
    dataRequestImportVisible: false,
    isSharing: false,
    isLoading: false,
    selectedRowKeys: [] as React.Key[],
    selectedRows: [] as Product[],
    editAddress: '',
    editValue: '',
    isLinking: false,
    filters: {} as ProductListFilters,
    selectAll: false,
    sorts: {},
  });

  const [pagination, setPagination] = useState({
    limit: defaultLimit,
    total: 0,
    skip: 0,
  });
  const query = new URLSearchParams(useLocation().search);

  const cancelFieldEditing = () =>
    setState((old) => ({ ...old, editAddress: '', editValue: '' }));
  const editField = (editAddress: string, editValue = '') =>
    setState((old) => ({ ...old, editAddress, editValue }));
  const applyFilters = (filters: ProductListFilters) => {
    setState((old) => ({
      ...old,
      filters,
      selectAll: old.selectAll && !areProductFiltersEmpty(filters),
    }));
  };
  const applySelectAll = () => {
    setState((old) => ({
      ...old,
      selectAll: !old.selectAll,
      selectedRows: !old.selectAll ? data : [],
      selectedRowKeys: !old.selectAll ? data.map((items) => items._id) : [],
    }));
  };
  const updateRecord = () => {
    const { editAddress = '', editValue = '' } = state;
    setState((old) => ({ ...old, isLoading: true }));
    ProductsService.patch(editedRowID.current, {
      // [key]: editValue,
      ['totalQtySuppliedToSG']: totalQtySuppliedToSG,
      ['internalFields.supplier']: supplier,
      ['internalFields.packagingGroup']: packagingGroup,
      ['internalFields._id']: internalFieldId,
      ['internalFields.field1']: iField1,
      ['internalFields.field2']: iField2,
      ['internalFields.field3']: iField3,
    })
      .then(
        () => {
          cancelFieldEditing();
          message.success({
            content: 'Product updated',
            key: 'updatingProduct',
          });
        },
        (error: Error) => {
          message.error(t('productPage.errors.updating'));
          console.log('Error in updating product: ', error);
        },
      )
      .finally(() => setState((old) => ({ ...old, isLoading: false })));
  };

  function createAndLinkPackaging(query = {}) {
    return PackagingsService.create({
      title: 'Edit packaging title',
      reportingPeriodId: reportingPeriod?._id,
    }).then((packaging: Packaging) => {
      console.log('packaging created ', packaging?._id);
      const { _id: packagingId } = packaging;
      return PackagingLinkService.patch(
        null,
        {
          action: 'updatePackaging',
          packagingId,
          subitemsCount: 1,
          isReportable: true,
        },
        {
          query,
        },
      ).then((res: PackagingLink) => {
        console.log('created link', res);
        return packaging;
      });
    });
  }

  const handleLinkingPackaging = (productIds: string[] = []) => {
    setState((old) => ({ ...old, isLinking: false, isLoading: true }));
    message.loading({
      content: 'Creating and linking packaging...',
      duration: 0,
      key: 'createAndAssignNewPackaging',
    });

    createAndLinkPackaging({
      ...(state.selectAll
        ? getFiltersQuery(state.filters)
        : { _id: { $in: [...state.selectedRowKeys, ...productIds] } }),
    })
      .then((packaging: Packaging) => {
        setState((old) => ({ ...old, isLinking: false, isLoading: false }));
        history.push(`/edit-packaging/${packaging?._id}`);
        message.success({
          content: 'Packaging ready for editing...',
          key: 'createAndAssignNewPackaging',
        });
      })
      .catch((e: Error) => {
        console.log('Error in linking new packaging: ', e);
        message.error({
          content: t('productPage.linkPackaging.messages.errAssign'),
          key: 'createAndAssignNewPackaging',
        });
      });
  };

  const rowSelection: TableRowSelection<Product> = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: state.selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) =>
      setState((old) => ({ ...old, selectedRowKeys, selectedRows })),
  };

  const deleteSelectedRecords = () => {
    message.loading({
      content: `${t('productPage.errors.deleting')} ${
        state.selectedRowKeys.length
      } ${t('productPage.errors.products')}`,
      duration: 0,
      key: 'bulkDelete',
    });
    ProductsService.remove(null, {
      query: {
        ...(state.selectAll
          ? getFiltersQuery(state.filters)
          : { _id: { $in: state.selectedRowKeys } }),
        reportingPeriodId: reportingPeriod?._id,
      },
    }).then(
      () => {
        message.success({
          content: `${t('productPage.errors.deleteAll')} ${
            state.selectedRowKeys.length
          } ${t('productPage.errors.products')}`,
          key: 'bulkDelete',
        });
        window.location.reload();
      },
      (error: Error) => {
        console.log('Error in deleting all products: ', error);
        message.error({
          content: `${t('productPage.errors.selectedProducts')}`,
          key: 'bulkDelete',
        });
      },
    );
  };

  const filters = {
    ...getFiltersQuery(state.filters),
    reportingPeriodId: reportingPeriod?._id,
  };

  function getMatchedProductCount({ queryKey }: any) {
    const [_, filters] = queryKey;
    if (filters?._id?.$in) {
      return Promise.resolve(filters?._id?.$in.length);
    }

    return ProductsService.find({ query: { ...filters, $limit: 0 } }).then(
      (res: Paginated<Product>) => res.total,
    );
  }

  const { isLoading: isCounting, data: matchedProductCount } = useQuery(
    ['matched-product-count', filters],
    getMatchedProductCount,
    {
      staleTime: 1 * 60 * 1000,
      enabled: state.selectAll,
    },
  );

  const readOnly = isObserverUser(auth) || isViewOnlyMode;
  const actionMenu = (
    <Menu>
      {!isMasterAccount(auth) && !readOnly ? (
        <Menu.Item
          onClick={() => setState((old) => ({ ...old, isImporting: true }))}
          key="1"
        >
          {t('productPage.importProducts')}
        </Menu.Item>
      ) : null}
      <Menu.Item
        key="2"
        onClick={() =>
          exportRecords({
            exportType: 'allRecords',
            serviceName: 'products',
            filters: JSON.stringify(filters),
          })
        }
      >
        {t('productPage.downloadProducts')}
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => setState((old) => ({ ...old, isExporting: true }))}
      >
        {t('productPage.listOfDownloads')}
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() =>
          setState((old) => ({ ...old, dataRequestDownloadVisible: true }))
        }
      >
        {t('List of data requests')}
      </Menu.Item>
    </Menu>
  );

  const columns: ColumnProps<Product>[] = [
    {
      width: '5%',
      title: (
        <Popover
          title={<div className="font-bold">{t('productPage.table.IAN')}</div>}
          content={
            <div style={{ maxWidth: '300px' }}>
              <p>{t('productPage.table.IANToolTipText')}</p>
            </div>
          }
          placement="bottomRight"
          autoAdjustOverflow
        >
          <div className="align-btn-icon">
            <span>{t('productPage.table.IAN')}</span>{' '}
            <InfoCircleFilled className="ml-1" style={{ color: '#92d050' }} />
          </div>
        </Popover>
      ),
      dataIndex: 'ian',
      className: 'description-col-bg',
      ...setUpSorting(state.sorts, 'ian', 1),
    },
    {
      width: '15%',
      title: (
        <Popover
          title={
            <div className="font-bold">
              {t('productPage.table.description')}
            </div>
          }
          content={
            <div style={{ maxWidth: '300px' }}>
              <p>{t('productPage.table.descriptionToolTipText')}</p>
            </div>
          }
          placement="bottomLeft"
          autoAdjustOverflow
        >
          <div className="align-btn-icon">
            <span>{t('productPage.table.description')}</span>{' '}
            <InfoCircleFilled className="ml-1" style={{ color: '#92d050' }} />
          </div>
        </Popover>
      ),
      dataIndex: 'description',
      className: 'description-col-bg',
      ...setUpSorting(state.sorts, 'description', 2),
      render: (text = '') => (
        <div title={text}>
          {text?.length <= 200 ? text : `${(text || '').slice(0, 200)}...`}
        </div>
      ),
    },
    {
      width: '5%',
      title: (
        <Popover
          title={
            <div className="font-bold">
              {t('productPage.table.packagingLinks')}
            </div>
          }
          content={
            <div style={{ maxWidth: '300px' }}>
              <p>{t('productPage.table.packagingLinksToolTipText')}</p>
            </div>
          }
          placement="bottomRight"
          autoAdjustOverflow
        >
          <div className="align-btn-icon">
            <span>{t('productPage.table.packagingLinks')}</span>{' '}
            <InfoCircleFilled className="ml-1" style={{ color: '#92d050' }} />
          </div>
        </Popover>
      ),
      dataIndex: ['packagingsCount'],
      ...setUpSorting(state.sorts, 'packagingsCount', 3),
      render: (text, record) => (
        <div
          className="px-2 text-center cursor-pointer"
          onClick={() => {
            setState((old) => ({
              ...old,
              selectedRowKeys: [record._id],
              selectedRows: [record],
              isLinking: true,
            }));
          }}
        >
          {text}
        </div>
      ),
    },
    {
      width: '8%',
      title: <p className="text-center ">{t('Actions')}</p>,
      dataIndex: 'actions',
      render: (text, record) => {
        return (
          <div className="text-center">
            {readOnly ? null : (
              <>
                <Tooltip title="Add and Link Package with product">
                  <Button
                    className="mr-1"
                    onClick={() => handleLinkingPackaging([record?._id])}
                  >
                    <AiOutlinePlusCircle className="text-lg" />
                  </Button>
                </Tooltip>
                <Tooltip title="Link Package">
                  <Button
                    onClick={() => {
                      setState((old) => ({ ...old, isLinking: true }));
                      setState((old) => ({
                        ...old,
                        selectedRowKeys: [record?._id],
                        selectedRows: [record],
                      }));
                    }}
                  >
                    <AiOutlineLink className="text-lg" />
                  </Button>
                </Tooltip>
              </>
            )}
          </div>
        );
      },
    },
    {
      title: t('productPage.table.numberOfItems'),
      dataIndex: 'totalQtySuppliedToSG',

      className: 'text-center',
      // ...setUpSorting(state.sorts, 'internalFields.supplier', 5),
      render: (text, record) =>
        selectedRow === record._id ? (
          <InputNumber
            placeholder={t('productPage.inputPlaceholder')}
            onChange={(editValue) => setTotalQtySuppliedToSG(editValue)}
            value={totalQtySuppliedToSG}
            min="0"
            onPressEnter={updateRecord}
            onBlur={cancelFieldEditing}
          />
        ) : (
          <span
            className="cursor-pointer"
            onClick={() => {
              if (readOnly) {
                return;
              }
              editField(`totalQtySuppliedToSG-${record._id}`, text);
            }}
          >
            {text === 0 || text > 0
              ? Number(Number(text || 0).toFixed(1)).toLocaleString()
              : 'N/A'}
          </span>
        ),
    },
    {
      title: (
        <Popover
          title={
            <div className="font-bold">{t('productPage.table.Supplier')}</div>
          }
          content={
            <div style={{ maxWidth: '300px' }}>
              <p>{t('productPage.table.SupplierToolTipText')}</p>
            </div>
          }
          placement="bottomRight"
          autoAdjustOverflow
        >
          <div className="align-btn-icon">
            <span>{t('productPage.table.Supplier')}</span>
            <InfoCircleFilled className="ml-1" style={{ color: '#92d050' }} />
          </div>
        </Popover>
      ),
      dataIndex: ['internalFields', 'supplier'],
      ...setUpSorting(state.sorts, 'internalFields.supplier', 5),
      render: (text, record) =>
        selectedRow === record._id ? (
          <Input
            placeholder={t('productPage.inputPlaceholder')}
            onChange={(e) => setSupplier(e.target.value)}
            value={supplier}
            onPressEnter={updateRecord}
            onBlur={cancelFieldEditing}
          />
        ) : (
          <span
            className="cursor-pointer"
            onClick={() => {
              if (readOnly) {
                return;
              }
              editField(`internalFields.supplier-${record._id}`, text);
            }}
          >
            {text || 'N/A'}
          </span>
        ),
    },
    {
      title: (
        <Popover
          title={
            <div className="font-bold">
              {t('productPage.table.PackageGroup')}
            </div>
          }
          content={
            <div style={{ maxWidth: '300px' }}>
              <p>{t('productPage.table.PackageGroupToolTipText')}</p>
              <p>{t('productPage.table.PackageGroupToolTipText-b')}</p>
            </div>
          }
          placement="bottomRight"
          autoAdjustOverflow
        >
          <div className="align-btn-icon">
            <span>{t('productPage.table.PackageGroup')}</span>
            <InfoCircleFilled className="ml-1" style={{ color: '#92d050' }} />
          </div>
        </Popover>
      ),
      dataIndex: ['internalFields', 'packagingGroup'],
      ...setUpSorting(state.sorts, 'internalFields.packagingGroup', 6),
      render: (text, record) =>
        selectedRow === record._id ? (
          <Input
            placeholder={t('productPage.inputPlaceholder')}
            onChange={(e) => setPackagingGroup(e.target.value)}
            value={packagingGroup}
            onPressEnter={updateRecord}
            onBlur={cancelFieldEditing}
          />
        ) : (
          <span
            className="cursor-pointer"
            onClick={() => {
              if (readOnly) {
                return;
              }
              editField(`internalFields.packagingGroup-${record._id}`, text);
            }}
          >
            {text || 'N/A'}
          </span>
        ),
    },
    {
      title: (
        <Popover
          title={
            <div className="font-bold">
              {t('productPage.table.InternalReference')}
            </div>
          }
          content={
            <div style={{ maxWidth: '300px' }}>
              <p>{t('productPage.table.internalReferenceToolTipText')}</p>
            </div>
          }
          placement="bottomRight"
          autoAdjustOverflow
        >
          <div className="align-btn-icon">
            <span>{t('productPage.table.InternalReference')}</span>
            <InfoCircleFilled className="ml-1" style={{ color: '#92d050' }} />
          </div>
        </Popover>
      ),
      dataIndex: ['internalFields', '_id'],
      render: (text, record) =>
        selectedRow === record._id ? (
          <Input
            placeholder={t('productPage.inputPlaceholder')}
            onChange={(e) => setInternalFieldId(e.target.value)}
            value={internalFieldId}
            onPressEnter={updateRecord}
            onBlur={cancelFieldEditing}
          />
        ) : (
          <span
            className="cursor-pointer"
            onClick={() => {
              if (readOnly) {
                return;
              }
              editField(`internalFields._id-${record._id}`, text);
            }}
          >
            {text || 'N/A'}
          </span>
        ),
    },
    {
      title: (
        <Popover
          title={
            <div className="font-bold">{t('productPage.table.ourInfo1-3')}</div>
          }
          content={
            <div style={{ maxWidth: '300px' }}>
              <p>{t('productPage.table.toolTipInfoText')}</p>
            </div>
          }
          placement="bottomRight"
          autoAdjustOverflow
        >
          <div className="align-btn-icon">
            <span>{t('productPage.table.ourInfo-1')}</span>
            <InfoCircleFilled className="ml-1" style={{ color: '#92d050' }} />
          </div>
        </Popover>
      ),
      dataIndex: ['internalFields', 'field1'],
      ...setUpSorting(state.sorts, 'internalFields.field1', 7),
      render: (text, record) =>
        selectedRow === record._id ? (
          <Input
            placeholder={t('productPage.inputPlaceholder')}
            onChange={(e) => setIField1(e.target.value)}
            value={iField1}
            onPressEnter={updateRecord}
            onBlur={cancelFieldEditing}
          />
        ) : (
          <span
            className="cursor-pointer"
            onClick={() => {
              if (readOnly) {
                return;
              }
              editField(`internalFields.field1-${record._id}`, text);
            }}
          >
            {text || 'N/A'}
          </span>
        ),
    },
    {
      title: t('productPage.table.ourInfo-2'),
      dataIndex: ['internalFields', 'field2'],
      ...setUpSorting(state.sorts, 'internalFields.field2', 8),
      render: (text, record) =>
        selectedRow === record._id ? (
          <Input
            placeholder={t('productPage.inputPlaceholder')}
            onChange={(e) => setIField2(e.target.value)}
            value={iField2}
            onPressEnter={updateRecord}
            onBlur={cancelFieldEditing}
            className="pointer"
          />
        ) : (
          <span
            className="cursor-pointer"
            onClick={() => {
              if (readOnly) {
                return;
              }
              editField(`internalFields.field2-${record._id}`, text);
            }}
          >
            {text || 'N/A'}
          </span>
        ),
    },
    {
      title: t('productPage.table.ourInfo-3'),
      dataIndex: ['internalFields', 'field3'],
      ...setUpSorting(state.sorts, 'internalFields.field3', 9),
      render: (text, record) =>
        selectedRow === record._id ? (
          <Input
            placeholder={t('productPage.inputPlaceholder')}
            onChange={(e) => setIField3(e.target.value)}
            value={iField3}
            onPressEnter={updateRecord}
            onBlur={cancelFieldEditing}
            className="pointer"
          />
        ) : (
          <span
            className="cursor-pointer"
            onClick={() => {
              if (readOnly) {
                return;
              }
              editField(`internalFields.field3-${record._id}`, text);
            }}
          >
            {text || 'N/A'}
          </span>
        ),
    },
  ];

  const fetchProducts = (options = {} as any) => {
    console.log('Fetching...');
    const { queryKey } = options;
    const [, pagination, filters, sorts] = queryKey;
    const { limit = defaultLimit, skip = 0 } = pagination;
    const filtersQuery = getFiltersQuery(filters);
    const defaultSorting = { ['description']: 1 };
    const $sort = Object.keys(sorts)?.length > 0 ? sorts : defaultSorting;
    return ProductsService.find({
      query: {
        $limit: limit,
        $skip: skip,
        ...filtersQuery,
        $sort,
        reportingPeriodId: reportingPeriod?._id,
      },
    });
  };
  const {
    data: products = INITIAL_STATE,
    isLoading,
    isFetching,
  } = useQuery(
    ['products', pagination, state.filters, state.sorts],
    fetchProducts,
    {
      enabled: !!reportingPeriod?._id,
      refetchOnWindowFocus: false,
      onError: (e: Error) => {
        console.log('Error in fetching products: ', e);
        message.error('Error in fetching products');
      },
    },
  );

  const {
    data = [],
    total = 0,
    limit = defaultLimit,
    skip = 0,
  } = products as Paginated<Product>;
  useEffect(() => {
    const handleCreate = () => queryClient.invalidateQueries('products');
    const handlePatch = () => queryClient.invalidateQueries('products');
    const handleRemove = () => queryClient.invalidateQueries('products');

    ProductsService.on('created', handleCreate);
    ProductsService.on('updated', handlePatch);
    ProductsService.on('patched', handlePatch);
    ProductsService.on('removed', handleRemove);
    return () => {
      ProductsService.off('created', handleCreate);
      ProductsService.off('updated', handlePatch);
      ProductsService.off('patched', handlePatch);
      ProductsService.off('removed', handleRemove);
    };
  }, []);
  useEffect(() => {
    const queryParams = query.get('filters') ?? '';

    setState((old) => ({
      ...old,
      filters: {
        searchTerm: queryParams,
      } as ProductListFilters,
    }));
  }, []);

  return (
    <div className="p-4 bg-white">
      <header className="mb-4">
        <div className="flex items-center mb-2">
          <span className="mr-auto font-sans text-2xl font-bold md:text-3xl">
            {t('productPage.title')}
          </span>

          {!readOnly && (
            <Button
              onClick={() =>
                setState((old) => ({
                  ...old,
                  dataRequestImportVisible: true,
                }))
              }
              className="mr-2"
              danger
              ghost
            >
              Import Data Request
            </Button>
          )}
          {state.selectedRowKeys.length > 0 || state.selectAll ? (
            <>
              <Button
                onClick={() =>
                  setState((old) => ({
                    ...old,
                    selectAll: false,
                    selectedRowKeys: [],
                    selectedRows: [],
                  }))
                }
                className="mr-2"
                danger
                ghost
              >
                Clear{' '}
                {state.selectAll
                  ? products.total
                  : state.selectedRowKeys.length}{' '}
                selections
              </Button>
              {switchedMasterAccount(auth) && !isViewOnlyMode ? (
                <Popconfirm
                  title={t('productPage.confirmMsg')}
                  placement="bottom"
                  okText="Yes"
                  onConfirm={deleteSelectedRecords}
                >
                  <Button type="primary" danger>
                    {t('productPage.deleteBtn')}
                  </Button>
                </Popconfirm>
              ) : null}
              {!readOnly && (
                <>
                  <Button
                    type="dashed"
                    className="ml-1"
                    onClick={() =>
                      setState((old) => ({ ...old, isLinking: true }))
                    }
                  >
                    {t('productPage.linkPackaging.linkBtnLabel')}
                  </Button>
                </>
              )}
              <Button
                type="dashed"
                className="ml-2 font-bold"
                disabled={isCounting || matchedProductCount > 50}
                title={getTooltip(isCounting, matchedProductCount)}
                onClick={() =>
                  exportRecords(
                    {
                      exportType: 'allRecords',
                      serviceName: 'productPackagingDataRequest',
                      filters: JSON.stringify({
                        ...(state.selectAll
                          ? getFiltersQuery(state.filters)
                          : { _id: { $in: state.selectedRowKeys } }),
                        reportingPeriodId: reportingPeriod?._id,
                      }),
                    },
                    () =>
                      setState((old) => ({
                        ...old,
                        selectedRowKeys: [],
                        selectedRows: [],
                        selectAll: false,
                      })),
                  )
                }
              >
                {t('productPage.downloadProductPackagingDataRequest')}
              </Button>
              <Button
                type="dashed"
                className="ml-2 font-bold"
                onClick={() =>
                  exportRecords(
                    {
                      exportType: 'selectedRecords',
                      serviceName: 'products',
                      filters: JSON.stringify({
                        _id: { $in: state.selectedRowKeys },
                        reportingPeriodId: reportingPeriod?._id,
                      }),
                    },
                    () =>
                      setState((old) => ({
                        ...old,
                        selectedRowKeys: [],
                        selectedRows: [],
                        selectAll: false,
                      })),
                  )
                }
              >
                {t('productPage.downloadProduct')}
              </Button>
            </>
          ) : null}
          {showFilter ? null : (
            <Button
              className="ml-1"
              type="primary"
              ghost
              onClick={() => setShowFilter(true)}
            >
              <FilterOutlined />
            </Button>
          )}

          <Dropdown overlay={actionMenu} className="ml-1" arrow>
            <Button>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        {showFilter ? (
          <ProductFilters
            filters={state.filters}
            selectAll={state.selectAll}
            setFilters={applyFilters}
            onSelectAll={applySelectAll}
            selectionCount={products.total}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
          />
        ) : null}
      </header>
      <main>
        {!isMasterAccount(auth) && !readOnly ? (
          <Table
            loading={isLoading || isFetching}
            rowKey="_id"
            columns={columns}
            dataSource={data}
            rowSelection={rowSelection}
            pagination={{
              total,
              pageSize: limit,
              current: skip / limit + 1,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '250', '500'],
              onShowSizeChange: (page, size = limit) => {
                localStorage.setItem('products-pageSize', size.toString());
                setPagination((old) => ({
                  ...old,
                  skip: (page - 1) * size,
                  limit: size,
                }));
              },
              onChange: (page, size = limit) =>
                setPagination((old) => ({
                  ...old,
                  skip: (page - 1) * size,
                  limit: size,
                })),
            }}
            onChange={(a, b, sort) => {
              handleSorting(
                sort as SorterResult<Product>[],
                state.sorts,
                (sorts) => setState((old) => ({ ...old, sorts })),
              );
            }}
            scroll={{ x: 2000, y: window.innerHeight - 388 }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {}, // click row
                onDoubleClick: (event) => {}, // double click row
                onContextMenu: (event) => {}, // right button click row
                onMouseEnter: (event) => {
                  setSelectedRow(record._id);
                  setRowValues(record);
                  // console.log(rowIndex, selectedRow);
                }, // mouse enter row
                onMouseLeave: (event) => {
                  setTotalQtySuppliedToSG('');
                  setSupplier('');
                  setPackagingGroup('');
                  setInternalFieldId('');
                  setIField1('');
                  setIField2('');
                  setIField3('');
                  setSelectedRow('');
                }, // mouse leave row
              };
            }}
          />
        ) : (
          <Table
            loading={isLoading || isFetching}
            rowKey="_id"
            columns={columns}
            dataSource={data}
            rowSelection={rowSelection}
            pagination={{
              total,
              pageSize: limit,
              current: skip / limit + 1,
              showSizeChanger: true,
              pageSizeOptions: ['50', '100', '250', '500'],
              onShowSizeChange: (page, size = limit) => {
                localStorage.setItem('products-pageSize', size.toString());
                setPagination((old) => ({
                  ...old,
                  skip: (page - 1) * size,
                  limit: size,
                }));
              },
              onChange: (page, size = limit) =>
                setPagination((old) => ({
                  ...old,
                  skip: (page - 1) * size,
                  limit: size,
                })),
            }}
            onChange={(a, b, sort) => {
              handleSorting(
                sort as SorterResult<Product>[],
                state.sorts,
                (sorts) => setState((old) => ({ ...old, sorts })),
              );
            }}
            scroll={{ y: window.innerHeight - 388 }}
          />
        )}
      </main>
      <ImportProducts
        visible={state.isImporting}
        handleClose={(reload) => {
          setState((old) => ({ ...old, isImporting: false }));
          if (reload) {
            queryClient.invalidateQueries('products');
          }
        }}
      />
      <ImportPackagingDataRequests
        visible={state.dataRequestImportVisible}
        handleClose={(reload) => {
          setState((old) => ({ ...old, dataRequestImportVisible: false }));
          if (reload) {
            queryClient.invalidateQueries('products');
          }
        }}
      />
      <DownloadExports
        serviceName="products"
        visible={state.isExporting}
        handleClose={() =>
          setState((old) => ({
            ...old,
            isExporting: false,
          }))
        }
      />

      <DownloadExports
        serviceName="productPackagingDataRequest"
        visible={state.dataRequestDownloadVisible}
        handleClose={() =>
          setState((old) => ({
            ...old,
            dataRequestDownloadVisible: false,
          }))
        }
      />

      <LinkPackaging
        readOnly={readOnly}
        visible={state.isLinking}
        handleClose={(shouldReload) => {
          setState((old) => ({
            ...old,
            isLinking: false,
          }));
          if (shouldReload) {
            queryClient.invalidateQueries('products');
          }
        }}
        products={state.selectedRows}
        selectAll={state.selectAll}
        selectionFilters={getFiltersQuery(state.filters)}
        createAndAssignNewPackaging={() => handleLinkingPackaging()}
      />
    </div>
  );
};

export default ProductList;
