import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DashboardReportingService } from '../../../api/services';
import { useActiveReportingPeriod } from '../../../hooks/useActiveReportingPeriod';
import { useAuth } from '../../../hooks/useAuth';
import { Company } from '../../../types';
import {
  getPercentage,
  getRandomAlphaNumericString,
  isMasterAccount,
} from '../../../utils';

interface CompanyStatsReport {
  suppliersList: Array<string>;
  totalProducts: number;
  totalProductsWithPackaging: number;
  companyId: string;
  company: Company;
  totalPackagings: number;
  completeWithEvidence: number;
  completeWithoutEvidence: number;
  bulk: {
    totalPackagings: number;
    completeWithEvidence: number;
    completeWithoutEvidence: number;
  };
}

const CompanyOverviewStats = () => {
  const auth = useAuth();
  const [state, setState] = useState({
    isLoading: false,
    stats: [] as CompanyStatsReport[],
  });
  const { t } = useTranslation();

  const columns: ColumnProps<CompanyStatsReport>[] = [
    {
      title: t('dashboard.masterPage.tableColumns.Clients'),
      dataIndex: ['company', 'businessName'],
      sorter: (a, b) =>
        a.company.businessName > b.company.businessName ? 1 : -1,
      onCell: (record) => ({
        className: 'text-blue-400 cursor-pointer',
        onClick: () => {
          if (isMasterAccount(auth)) {
            localStorage.setItem('view-as', record?.company?._id);
            localStorage.setItem('company-name', record?.company?.businessName);
            window.location.reload();
          }
        },
      }),
    },
    {
      title: t('dashboard.masterPage.tableColumns.totalProducts'),
      dataIndex: 'totalProducts',
      sorter: (a, b) => (a.totalProducts > b.totalProducts ? 1 : -1),
    },
    {
      // TODO: make this column display appropriate data, API not yet implemented
      title: t('Number of suppliers'),
      dataIndex: 'noOfSuppliers',
      render: (_, record) => getSuppliersCount(record),
    },
    {
      title: t(
        'dashboard.masterPage.tableColumns.percentageOfLinkedProductsToPackaging',
      ),
      dataIndex: 'totalProducts',
      sorter: (a, b) => {
        const percentageA = +getPercentage(
          a.totalProductsWithPackaging,
          a.totalProducts,
        ).toFixed(2);
        const percentageB = +getPercentage(
          b.totalProductsWithPackaging,
          b.totalProducts,
        ).toFixed(2);
        return percentageA > percentageB ? 1 : -1;
      },
      render: (totalProducts = 0, record) => {
        const percentage = Number(
          getPercentage(
            record.totalProductsWithPackaging,
            totalProducts,
          ).toFixed(2),
        );

        return (
          <div className="relative w-full text-center">
            <div className="relative z-50">{percentage}%</div>
            <div
              className="absolute top-0 h-full bg-green-500 border border-green-600"
              style={{ width: `${percentage}%` }}
            />
          </div>
        );
      },
    },
    {
      title: t('dashboard.masterPage.tableColumns.totalPackaging'),
      dataIndex: 'totalPackagings',
      sorter: (a, b) => (a.totalPackagings > b.totalPackagings ? 1 : -1),
    },
    {
      title: t('dashboard.masterPage.tableColumns.percentCompletePkg'),
      dataIndex: 'totalPackagings',
      sorter: (a, b) => {
        const totalPartialA =
          (a.completeWithEvidence || 0) + (a.completeWithoutEvidence || 0);
        const totalPartialB =
          (b.completeWithEvidence || 0) + (b.completeWithoutEvidence || 0);
        const percentageA = +getPercentage(
          totalPartialA,
          a.totalProducts,
        ).toFixed(2);
        const percentageB = +getPercentage(
          totalPartialB,
          b.totalProducts,
        ).toFixed(2);
        return percentageA > percentageB ? 1 : -1;
      },
      render: (totalProducts = 0, record) => {
        const total =
          (record.completeWithEvidence || 0) +
          (record.completeWithoutEvidence || 0);
        const percentage = Number(
          getPercentage(total, totalProducts).toFixed(2),
        );

        return (
          <div className="relative w-full text-center">
            <div className="relative z-50">{percentage}%</div>
            <div
              className="absolute top-0 h-full bg-green-500 border border-green-600"
              style={{ width: `${percentage}%` }}
            />
          </div>
        );
      },
    },
    {
      title: t('dashboard.masterPage.tableColumns.totalBulkPackaging'),
      dataIndex: ['bulk', 'totalPackagings'],
      sorter: (a, b) =>
        a.bulk.totalPackagings > b.bulk.totalPackagings ? 1 : -1,
    },
    {
      title: t(
        'dashboard.masterPage.tableColumns.percentageOfCompletePackagingWithOrWithoutEvidence',
      ),
      dataIndex: 'percentageOfCompletePackaging',
      sorter: (a, b) => {
        const percentageA = +getPercentage(
          a.bulk.completeWithEvidence,
          a.bulk.totalPackagings,
        ).toFixed(2);
        const percentageB = +getPercentage(
          b.bulk.completeWithoutEvidence,
          b.bulk.totalPackagings,
        ).toFixed(2);
        return percentageA > percentageB ? 1 : -1;
      },

      render: (text, record) => {
        const percentage = Number(
          getPercentage(
            record.bulk.completeWithEvidence +
              record.bulk.completeWithoutEvidence,
            record.bulk.totalPackagings,
          ).toFixed(2),
        );

        return (
          <div className="relative w-full text-center">
            <div className="relative z-50">{percentage}%</div>
            <div
              className="absolute top-0 h-full bg-green-500 border border-green-600"
              style={{ width: `${percentage}%` }}
            />
          </div>
        );
      },
    },
  ];

  const { reportingPeriod } = useActiveReportingPeriod();

  useEffect(() => {
    setState((old) => ({ ...old, isLoading: true }));
    DashboardReportingService.find({
      query: {
        action: 'prepareCompanyData',
        reportingPeriodId: reportingPeriod?._id,
      },
    }).then(
      (data: CompanyStatsReport[]) => {
        const stats = data
          .concat()
          .sort((a, b) =>
            a?.company?.businessName > b?.company?.businessName ? 1 : -1,
          );
        setState((old) => ({ ...old, isLoading: false, stats }));
      },
      (err: Error) => {
        setState((old) => ({ ...old, isLoading: false }));
        console.log('Error in fetching company stats: ', err);
      },
    );
  }, []);

  return (
    <Table
      rowKey={() => getRandomAlphaNumericString(24)}
      dataSource={state.stats}
      columns={columns}
      loading={state.isLoading}
      className="pb-2 bg-white"
      pagination={{
        style: { marginRight: '4px' },
        defaultPageSize: 25,
        showSizeChanger: true,
        pageSizeOptions: ['5', '10', '25', '50', '100'],
      }}
    />
  );
};

export default CompanyOverviewStats;
function getSuppliersCount(record: CompanyStatsReport) {
  return (record.suppliersList || []).filter(
    (i) => (i || '').trim().length !== 0,
  ).length;
}
