import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';

import { PackagingsService } from '../../../api/services';
import { useActiveReportingPeriod } from '../../../hooks/useActiveReportingPeriod';
import { useAuth } from '../../../hooks/useAuth';
import { Packaging } from '../../../types';
import PageContainer from './../../Layout';
import { LoadingPage } from '../../reporting-periods/LoadingPage';
import PackagingEditor from './PackagingEditor/Components/PackagingEditor';
import { getPackaging } from './packagings.api';

const EditPackaging = () => {
  const { packagingId } = useParams<{ packagingId: string }>();
  const history = useHistory();
  const client = useQueryClient();

  const { isObserver, user } = useAuth();
  const { isViewOnlyMode } = useActiveReportingPeriod();
  const [editedRecord, setEditedRecord] = useState({} as Packaging);

  const { isLoading, isError } = useQuery(
    ['packaging', packagingId],
    getPackaging,
    {
      enabled: (packagingId || '').startsWith('PKG'),
      onSuccess: (saved) => setEditedRecord(saved),
    },
  );

  const [removedMention, setRemovedMention] = useState(false);

  useEffect(() => {
    const handlePatch = (res: Packaging) => {
      if (res._id === packagingId) {
        setEditedRecord(res);
      }
    };
    PackagingsService.on('patched', handlePatch);

    return () => {
      PackagingsService.off('patched', handlePatch);
    };
  }, []);

  useEffect(() => {
    if (!(editedRecord?._id || '').startsWith('PKG')) {
      // console.log('not removing mention, unsaved');
      return;
    }

    if (
      !user?._id ||
      !(editedRecord?.unreadMentions || []).includes(user?._id)
    ) {
      // console.log('not removing mention, no mentions');
      return;
    }

    if (removedMention) {
      // console.log('not removing mention, already removed');
      return;
    }
    // console.log('removing mention');

    setRemovedMention(true);

    PackagingsService.patch(editedRecord._id, {
      $pull: { unreadMentions: user._id },
    }).then((res: Packaging) => {
      // console.log('removing mention - done');
      setEditedRecord((old) => ({
        ...old,
        unreadMentions: res.unreadMentions,
      }));
    });
  }, [editedRecord, user, removedMention]);

  const updateEditedRecord = (savedRecord: Packaging) => {
    const key = ['packaging', savedRecord._id];
    client.setQueryData(key, savedRecord);
    setEditedRecord({ ...editedRecord, ...savedRecord });

    if (packagingId === 'new') {
      history.push(`/edit-packaging/${savedRecord._id}`);
    }
  };

  const handleClose = () => {
    history.push('/packagings');
  };

  if (isError) {
    //TODO: add error page here
    throw new Error('There was error in fetching data');
  }

  const observerMode = isObserver || isViewOnlyMode;

  return (
    <PageContainer activeTab="packagings">
      {isLoading && packagingId.startsWith('PKG') ? (
        <LoadingPage />
      ) : (
        <PackagingEditor
          editedRecord={editedRecord as Packaging}
          updateEditedRecord={updateEditedRecord}
          handleClose={handleClose}
          readOnly={!!observerMode}
        />
      )}
    </PageContainer>
  );
};

export default EditPackaging;
