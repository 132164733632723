import './layout.css';

import { Layout } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import OldReportingPeriodWarning from '../../components/OldReportingPeriodWarning/OldReportingPeriodWarning';
import { useActiveReportingPeriod } from '../../hooks/useActiveReportingPeriod';
import { useAuth } from '../../hooks/useAuth';
import { useLatestReportingPeriod } from '../../hooks/useLatestReportingPeriod';
import { isMasterAccount, isObserverUser } from '../../utils';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const { Header, Content, Footer } = Layout;

interface PageContainerProps {
  children: ReactNode;
  activeTab: string;
}

const PageContainer = ({ children, activeTab }: PageContainerProps) => {
  const { t } = useTranslation();
  const menuCollapsed = localStorage.getItem('sidebarState') === 'collapsed';
  const [sidebarCollapse, setSidebarCollapse] = useState(menuCollapsed);

  const collapseMenu = (value: boolean) => {
    localStorage.setItem('sidebarState', value ? 'collapsed' : 'open');
    setSidebarCollapse(value);
  };
  // to get what's latest reporting period of a user
  const { reportingPeriod: latest, isLoading } = useLatestReportingPeriod();
  // to get what's activated reporting period of a user
  const { reportingPeriod: active } = useActiveReportingPeriod();
  //For isMasteraccount
  const auth = useAuth();

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className="header" style={{ zIndex: 1000 }}>
        <Navbar />
      </Header>
      <Layout>
        <Sidebar
          activeTab={activeTab}
          sidebarCollapse={sidebarCollapse}
          setSidebarCollapse={collapseMenu}
        />
        <Layout
          style={{
            marginLeft: sidebarCollapse ? '80px' : '200px',
            transition: 'all 200ms',
          }}
        >
          <Content style={{ marginTop: '64px', padding: '0.8rem 0.8rem 0px' }}>
            {!!active?._id &&
              active?._id !== latest?._id &&
              !active?.isArchived &&
              !isMasterAccount(auth) &&
              !isObserverUser(auth) &&
              !isLoading && <OldReportingPeriodWarning />}
            {children}
          </Content>
          <Footer style={{ marginTop: '-0.8rem' }}>
            <div style={{ textAlign: 'center' }}>&copy; {t('footer.text')}</div>
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default PageContainer;
