import React, {
  Dispatch,
  PropsWithChildren,
  createContext,
  useEffect,
  useReducer,
} from 'react';

import { User } from '../types';
import { isObserverUser } from '../utils';

interface AuthResponse {
  accessToken?: string;
  user?: User;
}

export interface AuthState extends AuthResponse {
  isLoading?: boolean;
  isLoggedIn?: boolean;
  error?: null | Error;
  isObserver?: boolean;
}

const AuthStateContext = createContext<AuthState | undefined>(undefined);
const AuthDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined,
);

function getDefaultState() {
  let result = {
    isLoading: false,
    isLoggedIn: false,
    accessToken: undefined,
    user: undefined,
    error: undefined,
  };

  return result;
}

function getInitialState() {
  let result;
  let savedState = localStorage.getItem('auth');
  if (savedState) {
    result = JSON.parse(savedState);
    result.isLoading = false;
  } else {
    result = getDefaultState();
  }
  return result;
}

type Action =
  | { type: 'login start' }
  | { type: 'user update'; payload: User }
  | { type: 'login success'; payload: AuthResponse }
  | { type: 'login fail'; error: null | Error }
  | { type: 'logout start' }
  | { type: 'logout success' }
  | { type: 'logout fail'; error: null | Error }
  | { type: 'reset state' };

function authReducer(_state: AuthState, action: Action) {
  switch (action.type) {
    case 'user update': {
      return { ..._state, user: { ..._state.user, ...action.payload } };
    }
    case 'login start': {
      return { ..._state, isLoading: true, isLoggedIn: false, error: null };
    }
    case 'login success': {
      return {
        ..._state,
        isLoading: false,
        isLoggedIn: true,
        error: null,
        ...action.payload,
      };
    }
    case 'login fail': {
      return {
        ..._state,
        isLoading: false,
        isLoggedIn: false,
        error: action.error,
      };
    }
    case 'logout start': {
      return { ..._state, isLoading: true, error: null };
    }
    case 'logout success': {
      return { ..._state, isLoading: false, isLoggedIn: false, error: null };
    }
    case 'logout fail': {
      return { ..._state, isLoading: false, error: action.error };
    }
    case 'reset state': {
      return { ...getDefaultState() };
    }

    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

export function AuthProvider({ children }: PropsWithChildren<{}>) {
  const [auth, dispatch] = useReducer(authReducer, getInitialState());

  useEffect(() => {
    localStorage.setItem('auth', JSON.stringify(auth));
  }, [auth]);

  const isObserver = isObserverUser(auth);

  return (
    <AuthStateContext.Provider value={{ ...auth, isObserver }}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
}

export function useAuth() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }
  return context;
}
