import { InboxOutlined } from '@ant-design/icons';
import { Button, Modal, Spin, Table, Upload, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { UploadProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import i18next from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import XLSX from 'xlsx';

import { PackagingsService } from '../../../api/services';
import { useActiveReportingPeriod } from '../../../hooks/useActiveReportingPeriod';
import { useAuth } from '../../../hooks/useAuth';
import { Packaging } from '../../../types';
import { switchedMasterAccount } from '../../../utils';
import ComparePackagings from './ComparePackaging';

const { Dragger } = Upload;
type ImportPackagingsProps = {
  visible: boolean;
  handleClose: (reload: boolean) => void;
};

export type colMapping = {
  [userKey in keyof Packaging]: string;
};

const tableColumns: ColumnProps<Packaging>[] = [
  {
    title: i18next.t('packagingPage.container.table.name'),
    dataIndex: 'title',
  },
  {
    title: i18next.t('packagingPage.container.table.description'),
    dataIndex: 'description',
  },
];

const ImportPackagings = ({ visible, handleClose }: ImportPackagingsProps) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const [state, setState] = useState({
    uploadSuccess: false,
    isLoading: false,
    mappingComplete: false,
  });

  const [importData, setImportData] = useState({
    rawData: [] as { [userKey: string]: string }[],
    mappedData: [] as Packaging[],
    colMapping: {} as colMapping,
    userColumns: [] as string[],
  });
  const { reportingPeriod } = useActiveReportingPeriod();

  const uploadProps: UploadProps = {
    showUploadList: false,
    name: 'file',
    accept:
      '.xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    onChange(info) {
      const { file = {} as UploadFile } = info;
      const { status } = file;
      if (status === 'uploading') {
        setState((old) => ({ ...old, isLoading: true }));
        message.loading({
          content: t('importPackagings.uploadingFileMsg'),
          duration: 0,
          key: 'packagingsImport',
        });
      } else if (status === 'done') {
        message.loading({
          content: t('importPackagings.readingFileMsg'),
          duration: 0,
          key: 'packagingsImport',
        });
      } else if (status === 'error') {
        setState((old) => ({ ...old, isLoading: false }));
        message.error({
          content: t('importPackagings.errReadingFile'),
          key: 'packagingsImport',
        });
      }
    },
    customRequest({ file, onSuccess, onError }) {
      const fileReader = new FileReader();
      fileReader.onload = (e: ProgressEvent<FileReader>) => {
        const bstr = e?.target?.result;
        const wb = XLSX.read(bstr, { type: 'array' });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const inputData = XLSX.utils.sheet_to_json(ws) as {
          [userKey: string]: string;
        }[];
        //@ts-ignore
        onSuccess?.(inputData, file);
        const readData = (inputData || []).slice(0, 10000);
        let userColumnSet = new Set<string>();

        readData.forEach((item) => {
          const cols = Object.keys(item)
            .map((item = '') => item.trim())
            .filter((item) => !!item && item !== '__EMPTY');
          cols.forEach((col) => userColumnSet.add(col));
        });
        const userColumns = Array.from(userColumnSet);

        const temp = userColumns.map((item = '') => item.toLowerCase());

        const matchedColumns = tableColumns.reduce((acc, curr) => {
          //@ts-ignore
          const title = curr.title.trim().toLowerCase();
          if (temp.includes(title)) {
            return {
              ...acc,
              [curr.dataIndex as string]: title,
            } as Partial<colMapping>;
          }
          return acc;
        }, {} as Partial<colMapping>);

        message.success({
          content: t('importPackagings.uploadComplete'),
          key: 'packagingsImport',
        });
        setState((old) => ({ ...old, uploadSuccess: true, isLoading: false }));
        setImportData((old) => ({
          ...old,
          rawData: readData,
          mappedData: [],
          userColumns,
          colMapping: matchedColumns as colMapping,
        }));
      };

      fileReader.onerror = function (
        this: FileReader,
        ev: ProgressEvent<FileReader>,
      ) {
        setState((old) => ({ ...old, isLoading: false, uploadSuccess: false }));
        message.error({
          content: t('importPackagings.errReadingFile'),
          key: 'packagingsImport',
        });
        onError?.(ev);
      };

      fileReader.readAsArrayBuffer(file as Blob);
    },
  };

  const mapData = () => {
    const { colMapping = {} } = importData;
    let revertColMapping = {} as { [key: string]: keyof Packaging };
    for (const [key, value] of Object.entries(colMapping)) {
      revertColMapping = {
        ...revertColMapping,
        [value as string]: key as keyof Packaging,
      };
    }

    let mappedData = importData?.rawData?.reduce((acc, curr) => {
      let record = {} as Packaging;
      for (const [key, value] of Object.entries(curr)) {
        const lowerColName = key.trim().toLowerCase();
        record = {
          ...record,
          [revertColMapping[lowerColName]]: value,
        };
      }

      return [...acc, record];
    }, [] as Packaging[]);

    if (!switchedMasterAccount(auth)) {
      mappedData = mappedData.filter((item) => !!item._id);
    }

    setImportData((old) => ({ ...old, mappedData }));
    setState((old) => ({ ...old, mappingComplete: true }));
  };

  const saveData = () => {
    setState((old) => ({ ...old, isLoading: true }));
    message.loading({
      content: 'Saving packagings...',
      duration: 0,
      key: 'importing',
    });
    const { mappedData = [] } = importData;
    PackagingsService.create({
      action: 'importRecords',
      reportingPeriodId: reportingPeriod?._id,
      records: mappedData,
    })
      .then(
        () => {
          message.success({
            content: t('importPackagings.packagingsSaved'),
            key: 'importing',
          });
          closeModal(true);
        },
        (e: Error) => {
          message.error({
            content: t('importPackagings.errSave'),
            key: 'importing',
          });
          console.log('Error occured in saving packagings: ', e);
        },
      )
      .finally(() => setState((old) => ({ ...old, isLoading: false })));
  };

  const closeModal = (shouldReload: boolean) => {
    setImportData({
      colMapping: {} as colMapping,
      mappedData: [],
      rawData: [],
      userColumns: [],
    });
    setState({
      isLoading: false,
      mappingComplete: false,
      uploadSuccess: false,
    });
    handleClose(shouldReload);
  };

  return (
    <Modal
      title={
        <div>
          <div>{t('importPackagings.ModalTitle')}</div>
          {state.uploadSuccess && !state.mappingComplete ? (
            <p className="mb-0 text-sm text-gray-500">
              {t('importPackagings.modalInfo-1')}
              <br />
              {t('importPackagings.modalInfo-2')}
            </p>
          ) : null}
          {state.uploadSuccess && state.mappingComplete ? (
            <p className="mb-0 text-sm text-gray-500">
              {t('importPackagings.modalInfo-3')}
              <br />
              {t('importPackagings.modalInfo-4')}{' '}
              <q> {t('importPackagings.importData')}</q>
            </p>
          ) : null}
        </div>
      }
      width="800px"
      visible={visible}
      onCancel={() => closeModal(false)}
      footer={
        <div className="pr-2">
          <Button key="back" onClick={() => closeModal(false)}>
            {t('importPackagings.cancel')}
          </Button>
          {state.uploadSuccess && !state.mappingComplete ? (
            <Button
              onClick={mapData}
              type="primary"
              disabled={Object.keys(importData.colMapping).length == 0}
            >
              {t('importPackagings.preview')}
            </Button>
          ) : null}
          {state.mappingComplete ? (
            <>
              <Button
                type="dashed"
                onClick={() => {
                  setState((old) => ({ ...old, mappingComplete: false }));
                  setImportData((old) => ({ ...old, mappedData: [] }));
                }}
              >
                {t('importPackagings.back')}
              </Button>
              <Button
                type="primary"
                onClick={saveData}
                disabled={
                  state.isLoading || importData?.mappedData?.length === 0
                }
                loading={state.isLoading}
              >
                {t('importPackagings.importData')}
              </Button>
            </>
          ) : null}
        </div>
      }
    >
      {!state.uploadSuccess ? (
        <Spin spinning={state.isLoading}>
          <Dragger
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            {...uploadProps}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{t('importPackagings.dragMsg')}</p>
            <p className="ant-upload-hint">{t('importPackagings.dragInfo')}</p>
          </Dragger>
        </Spin>
      ) : null}
      {state.uploadSuccess && !state.mappingComplete ? (
        <ComparePackagings
          tableColumns={tableColumns}
          userColumns={importData.userColumns}
          colMapping={importData.colMapping}
          updateColMapping={(changes: colMapping) =>
            setImportData((old) => ({
              ...old,
              colMapping: { ...old.colMapping, ...changes },
            }))
          }
          removeMapping={(dataIndex) =>
            setImportData((old) => {
              const { colMapping } = old;
              const { [dataIndex]: omitted, ...newMapping } = colMapping;
              return { ...old, colMapping: newMapping as colMapping };
            })
          }
        />
      ) : null}
      {state.uploadSuccess && state.mappingComplete ? (
        <>
          {importData.mappedData.length > 0 ? (
            <Table
              rowKey={`${Math.floor(Math.random() * 1000000)}`}
              columns={tableColumns}
              dataSource={importData.mappedData}
              pagination={{
                pageSize: 50,
                showSizeChanger: true,
                pageSizeOptions: ['50', '100', '250', '500'],
              }}
              scroll={{ x: true, y: 350 }}
            />
          ) : (
            <h1 className="my-2 text-lg font-bold text-center">
              {t('importPackagings.noUpdateData')}
            </h1>
          )}
        </>
      ) : null}
    </Modal>
  );
};

export default ImportPackagings;
