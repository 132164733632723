import PageContainer from '../Layout';
import CompaniesContainer from './CompaniesList';

const Companies = () => (
  <PageContainer activeTab="companies">
    <CompaniesContainer />
  </PageContainer>
);

export default Companies;
