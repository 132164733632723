import { InboxOutlined } from '@ant-design/icons';
import { Spin, Upload, message } from 'antd';
import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import restClient from '../../../../../api/rest.client';
import { BulkPackaging } from '../../../../../types';
import { apiBaseURL } from '../../../../../utils';
import { createEvidence } from '../evidence.api';
import { UploadedFile } from '.';

interface EvidenceUploaderProps {
  packaging: BulkPackaging;
  readOnly: boolean;
  handleSave: (packaging: BulkPackaging) => void;
}
const EvidenceUploader = ({
  readOnly,
  handleSave,
  packaging,
}: EvidenceUploaderProps) => {
  const queryClient = useQueryClient();
  const { _id: packagingId } = packaging;
  const { Dragger } = Upload;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const draggerProps = {
    showUploadList: false,
    action: apiBaseURL('uploads'),
    name: 'file',
    multiple: true,
    disabled: readOnly || isLoading,
    onSuccess: (res: any, file: any) => {
      const { data = {} } = res;
      const { id: url, originalName, mimeType, size } = data;
      createMutation.mutate({ url, originalName, mimeType, size, packagingId });
    },

    beforeUpload(file: UploadedFile) {
      const isValidSize = file.size / 1024 / 1024 < 50;
      if (!isValidSize) {
        message.error(t('packagingEditor.evidenceAudit.fileBigErr'));
      }
      setIsLoading(false);
      return isValidSize;
    },

    customRequest({
      action,
      file,
      onSuccess,
      onProgress = () => null,
      onError,
    }: any) {
      restClient.reAuthenticate().then((auth: any) => {
        setIsLoading(true);
        let formData = new FormData();
        formData.append('uri', file);
        axios
          .post(action, formData, {
            headers: {
              Authorization: auth.accessToken,
            },
            onUploadProgress: ({ loaded, total }) =>
              onProgress
                ? // @ts-ignore
                  onProgress({
                    percent: Math.round((loaded / total) * 100),
                  })
                : null,
          })
          //@ts-ignore
          .then((res) => (onSuccess ? onSuccess(res, file) : null))
          .catch(onError);
      });
    },
  };

  const createMutation = useMutation(createEvidence, {
    onSuccess: () => {
      handleSave({
        evidenceCount: (packaging.evidenceCount || 0) + 1,
      } as BulkPackaging);
      message.success(t('packagingEditor.evidenceAudit.uploadSuccess'));
      queryClient.invalidateQueries('evidence');
      setIsLoading(false);
    },
    onError: (e: Error) => {
      message.error(t('packagingEditor.evidenceAudit.uploadingErr'));
      setIsLoading(false);
      console.log('Error in saving evidence: ', e);
    },
  });
  return (
    <div className="mb-5">
      <Spin spinning={isLoading}>
        <Dragger {...draggerProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            {t('packagingEditor.evidenceAudit.dragText')}
          </p>
          <p className="font-bold text-gray-500 ant-upload-hint">
            {t('packagingEditor.evidenceAudit.dragInfo')}
          </p>
        </Dragger>
      </Spin>
    </div>
  );
};

export default EvidenceUploader;
