import {
  AssessmentMethod,
  MaterialComposition,
  MaterialItem,
  Packaging,
  Product,
  SeparableItem,
} from '../../types';

function lookupMaterialItem(
  category: string,
  name: string,
  materialItems: any,
) {
  const subCategories =
    materialItems[(category || '').trim().toLowerCase()] || [];
  const materialItem = subCategories.find(
    (item: MaterialItem) =>
      (item.name || '').trim().toLowerCase() ===
      (name || '').trim().toLocaleLowerCase(),
  );
  let result = { _id: '' };
  if (materialItem !== -1) {
    result = materialItem;
  }
  return result;
}

export default function extractData(
  rows: Array<any[]>,
  assessmentMethods: { [index: string]: AssessmentMethod },
  materialItems: { [index: string]: MaterialItem },
) {
  const assessmentMethodId = (assessmentMethods[rows[8][3]] || {})._id;

  const packaging: Partial<Packaging> = {
    _id: rows[5][9],
    title: rows[5][3],
    description: rows[6][3],
    assessmentMethodId,
    assessmentComment: rows[9][3],
  };

  const product: Partial<Product> = {
    _id: rows[2][9],
    description: rows[2][3],
  };

  const link = {
    _id: `${rows[2][9] || ''}:`,
    subItemsCount: rows[3][3],
  };

  const separableITems = [];

  for (let i = 0; i < 10; i++) {
    const firstSeparableItemIndex = 13;
    const basicInfoRowIndex = firstSeparableItemIndex + i;

    const subItem: Partial<SeparableItem> = {};

    const [
      _id,
      description,
      _,
      materialCategory,
      materialItem,
      neaPackagingForm,
      subitemsCount,
      isValid,
    ] = rows[basicInfoRowIndex];

    if (!materialCategory && !materialItem && !neaPackagingForm) {
      continue;
    }

    link._id = `${rows[2][9] || ''}:${_id || ''}`;

    subItem._id = _id;
    subItem.description = description;
    subItem.category = materialCategory;
    subItem.materialItemId = lookupMaterialItem(
      materialCategory,
      materialItem,
      materialItems || {},
    )._id;
    subItem.neaPackagingForm = neaPackagingForm;
    subItem.subitemsCount = subitemsCount;

    const rowsBetweenNextItemDetails = 6;
    const firstItemsDetailIndex = 30;
    const itemsDetailsRowIndex =
      firstItemsDetailIndex + i * rowsBetweenNextItemDetails;

    const primaryMaterial: Partial<MaterialComposition> = {};
    const secondaryMaterial1: Partial<MaterialComposition> = {};
    const secondaryMaterial2: Partial<MaterialComposition> = {};
    const secondaryMaterial3: Partial<MaterialComposition> = {};
    const secondaryMaterial4: Partial<MaterialComposition> = {};
    const secondaryMaterial5: Partial<MaterialComposition> = {};

    for (let j = 0; j < 3; j++) {
      primaryMaterial.material = rows[itemsDetailsRowIndex + 1][4];
      primaryMaterial.weightInGrams = rows[itemsDetailsRowIndex + 2][4];

      secondaryMaterial1.description = rows[itemsDetailsRowIndex][5];
      secondaryMaterial1.material = rows[itemsDetailsRowIndex + 1][5];
      secondaryMaterial1.weightInGrams = rows[itemsDetailsRowIndex + 2][5];

      secondaryMaterial2.description = rows[itemsDetailsRowIndex][6];
      secondaryMaterial2.material = rows[itemsDetailsRowIndex + 1][6];
      secondaryMaterial2.weightInGrams = rows[itemsDetailsRowIndex + 2][6];

      secondaryMaterial3.description = rows[itemsDetailsRowIndex][7];
      secondaryMaterial3.material = rows[itemsDetailsRowIndex + 1][7];
      secondaryMaterial3.weightInGrams = rows[itemsDetailsRowIndex + 2][7];

      secondaryMaterial4.description = rows[itemsDetailsRowIndex][8];
      secondaryMaterial4.material = rows[itemsDetailsRowIndex + 1][8];
      secondaryMaterial4.weightInGrams = rows[itemsDetailsRowIndex + 2][8];

      secondaryMaterial5.description = rows[itemsDetailsRowIndex][9];
      secondaryMaterial5.material = rows[itemsDetailsRowIndex + 1][9];
      secondaryMaterial5.weightInGrams = rows[itemsDetailsRowIndex + 2][9];
    }

    subItem.primaryMaterial = primaryMaterial as MaterialComposition;
    subItem.secondaryMaterial1 = secondaryMaterial1 as MaterialComposition;
    subItem.secondaryMaterial2 = secondaryMaterial2 as MaterialComposition;
    subItem.secondaryMaterial3 = secondaryMaterial3 as MaterialComposition;
    subItem.secondaryMaterial4 = secondaryMaterial4 as MaterialComposition;
    subItem.secondaryMaterial5 = secondaryMaterial5 as MaterialComposition;

    separableITems.push(subItem);
  }

  packaging.separableItems = separableITems as SeparableItem[];

  console.log({ packaging, link, product });

  return { packaging, link, product };
}
