import { Table, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AuditTrailService } from '../../../../api/services';
import { AuditTrail, Paginated } from '../../../../types';
import { getFullName } from '../../../Packaging/getFullName';

interface AuditTrailTableProps {
  packagingId: string;
}
const defaultLimit = 10;
const INITIAL_STATE = {
  data: [],
  limit: defaultLimit,
  skip: 0,
  total: 0,
};

const AuditTrailTable = ({ packagingId }: AuditTrailTableProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [auditTrail, setAuditTrail] =
    useState<Paginated<AuditTrail>>(INITIAL_STATE);
  const { data = [], limit = defaultLimit, skip = 0, total = 0 } = auditTrail;

  const columns: ColumnProps<AuditTrail>[] = [
    {
      title: t('packagingEditor.auditTrail.user'),
      dataIndex: 'userId',
      render: (_, record: AuditTrail) =>
        `${getFullName(record?.user)} (${record.user._id})`,
    },
    { title: t('packagingEditor.auditTrail.change'), dataIndex: 'text' },
    {
      title: t('packagingEditor.auditTrail.date'),
      dataIndex: 'createdAt',
      render: (text) =>
        text ? moment(text).format('DD MMM YYYY - HH:mm') : '',
    },
  ];

  useEffect(() => {
    if (packagingId) {
      setIsLoading(true);
      AuditTrailService.find({
        query: {
          packagingId,
          $sort: { createdAt: -1 },
          $skip: skip,
          $limit: limit,
        },
      })
        .then(
          (trail: Paginated<AuditTrail>) => setAuditTrail(trail),
          (e: Error) => {
            console.log('Error in fetching audit trail: ', e);
            message.error(t('packagingEditor.auditTrail.fetchingErr'));
          },
        )
        .finally(() => setIsLoading(false));
    }
  }, [packagingId, skip, limit]);
  useEffect(() => {
    const handleCreate = (res: AuditTrail) =>
      setAuditTrail((old) => ({
        ...old,
        data: [res, ...old.data].slice(0, limit),
        total: old.total + 1,
      }));
    const handlePatch = (res: AuditTrail) =>
      setAuditTrail((old) => ({
        ...old,
        data: old.data.map((item) => (item._id === res._id ? res : item)),
      }));

    const handleRemove = (res: AuditTrail) =>
      setAuditTrail((old) => ({
        ...old,
        data: old.data.filter((item) => item._id !== res._id),
      }));

    AuditTrailService.on('created', handleCreate);
    AuditTrailService.on('patched', handlePatch);
    AuditTrailService.on('removed', handleRemove);
    return () => {
      AuditTrailService.off('created', handleCreate);
      AuditTrailService.off('patched', handlePatch);
      AuditTrailService.off('removed', handleRemove);
    };
  }, []);
  return (
    <div className="mt-4">
      <h2 className="text-sm font-bold">
        {t('packagingEditor.auditTrail.title')}
      </h2>
      <Table
        dataSource={data}
        columns={columns}
        loading={isLoading}
        pagination={{
          total,
          pageSize: limit,
          current: skip / limit + 1,
          onChange: (page) =>
            setAuditTrail((old) => ({ ...old, skip: (page - 1) * limit })),
        }}
      />
    </div>
  );
};

export default AuditTrailTable;
