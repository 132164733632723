import auth from '@feathersjs/authentication-client';
import feathersClient from '@feathersjs/client';

import socket from './socket';

const client = feathersClient();

client.configure(
  feathersClient.socketio(socket, {
    timeout: 180000,
  }),
);
client.configure(auth({ storageKey: 'feathers-jwt' }));

client.on('reauthentication-error', async (e) => {
  try {
    await client.reAuthenticate();
  } catch (error) {
    console.log(error);
  }
});

const viewAs = localStorage.getItem('view-as');
function addClientInfo() {
  return (context: any) => {
    const { query = {} } = context.params;
    const updatedQuery = {
      ...query,
      viewAs: query.viewAs === 'zwc' ? '' : viewAs,
    };
    context.params.query = updatedQuery;

    return context;
  };
}

client.hooks({
  before: {
    all: [
      (context) => {
        context.params.startTime = Date.now();
        return context;
      },
    ],
  },
  after: {
    all: [
      (context) => {
        const end = Date.now();
        const start = context.params.startTime;
        if (process.env.NODE_ENV !== 'production') {
          console.log(
            'Time taken: ',
            context.path,
            context.method,
            end - start,
          );
        }
        return context;
      },
    ],
  },
  error: {
    all: [
      (context) => {
        const { error } = context;
        if (error.name === 'NotAuthenticated') {
          localStorage.removeItem('feathers-jwt');
          localStorage.removeItem('auth');
        }
      },
    ],
  },
});

if (!!viewAs) {
  client.hooks({
    before: {
      all: [addClientInfo()],
    },
  });
}
export default client;
