import { Button, Input, List, Popconfirm, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPencil, BsPlus, BsTrash } from 'react-icons/bs';

//TODO: we can update this page to use useAssessmentMethods hook
import { AssessmentMethodsService } from '../../api/services';
import { AssessmentMethod, Paginated } from '../../types';
import { getRandomAlphaNumericString } from '../../utils';

const INITIAL_STATE: Paginated<AssessmentMethod> = {
  data: [],
  limit: 25,
  skip: 0,
  total: 0,
};
const AssessmentMethodologies = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    isLoading: false,
    editingId: '',
    editedTitle: '',
  });
  const [methods, setMethods] = useState(INITIAL_STATE);

  const { data = [] } = methods;

  const addNewMethod = () => {
    const _id = `NEW-${getRandomAlphaNumericString(10)}`;
    const newMethod = {
      _id,
      title: '',
    } as AssessmentMethod;

    setMethods((old) => ({ ...old, data: [...old.data, newMethod] }));
    setState((old) => ({ ...old, editingId: _id }));
  };
  const cancelEditing = () => {
    setState((old) => ({ ...old, editedTitle: '', editingId: '' }));
    setMethods((old) => ({
      ...old,
      data: old.data.filter((item) => !item._id.startsWith('NEW')),
    }));
  };
  const handleSave = () => {
    const { editedTitle: title = '', editingId = '' } = state;
    if (editingId && title) {
      if (editingId.startsWith('NEW')) {
        AssessmentMethodsService.create({ title })
          .then(cancelEditing)
          .catch((error: Error) => {
            console.log('Error in creating assessment method: ', error);
            message.error(t('list.assessmentMethodology.errors.creating'));
          });
      } else {
        AssessmentMethodsService.patch(editingId, { title })
          .then(cancelEditing)
          .catch((error: Error) => {
            console.log('Error in updating assessment method: ', error);
            message.error(t('list.assessmentMethodology.errors.updating'));
          });
      }
    } else {
      message.info(t('list.assessmentMethodology.errors.info'));
    }
  };

  const removeMethod = (methodId: string) => {
    AssessmentMethodsService.remove(methodId).catch((error: Error) => {
      console.log('Could not delete assessment: ', error);
      message.error(t('list.assessmentMethodology.errors.deleting'));
    });
  };

  useEffect(() => {
    setState((old) => ({ ...old, isLoading: true }));
    AssessmentMethodsService.find({ query: { $limit: 500 } })
      .then(
        (res: Paginated<AssessmentMethod>) => setMethods(res),
        (error: Error) => {
          console.log('Error in fetching assessment methods: ', error);
          message.error(t('list.assessmentMethodology.errors.fetching'));
        },
      )
      .finally(() => setState((old) => ({ ...old, isLoading: false })));
  }, []);
  useEffect(() => {
    const handleCreate = (res: AssessmentMethod) =>
      setMethods((old) => ({
        ...old,
        data: [...old.data, res],
        total: old.total + 1,
      }));
    const handlePatch = (res: AssessmentMethod) =>
      setMethods((old) => ({
        ...old,
        data: old.data.map((item) => (item._id === res._id ? res : item)),
      }));

    const handleRemove = (res: AssessmentMethod) =>
      setMethods((old) => ({
        ...old,
        data: old.data.filter((item) => item._id !== res._id),
      }));

    AssessmentMethodsService.on('created', handleCreate);
    AssessmentMethodsService.on('patched', handlePatch);
    AssessmentMethodsService.on('removed', handleRemove);
    return () => {
      AssessmentMethodsService.off('created', handleCreate);
      AssessmentMethodsService.off('patched', handlePatch);
      AssessmentMethodsService.off('removed', handleRemove);
    };
  }, []);

  return (
    <div
      className="pb-4"
      style={{
        overflowY: 'auto',
        height: 'calc(100vh - 287px)',
      }}
    >
      <List
        header={
          <div className="font-bold text-center">
            {t('list.assessmentMethodology.title')}
          </div>
        }
        dataSource={data}
        renderItem={(item) => (
          <List.Item className="flex items-center justify-between s-hover-parent">
            {state.editingId !== item._id ? (
              <>
                <span>{item.title}</span>
                <span className="s-hover-target">
                  <Button
                    type="text"
                    onClick={() =>
                      setState((old) => ({
                        ...old,
                        editedTitle: item.title,
                        editingId: item._id,
                      }))
                    }
                  >
                    <BsPencil className="text-blue-600" />
                  </Button>
                  <Popconfirm
                    title={t('list.assessmentMethodology.deletePopupText')}
                    onConfirm={() => removeMethod(item._id)}
                    placement="topLeft"
                    okText={t('list.okTxt')}
                    okButtonProps={{ type: 'primary', danger: true }}
                  >
                    <Button type="text">
                      <BsTrash className="text-red-500" />
                    </Button>
                  </Popconfirm>
                </span>
              </>
            ) : (
              <Input
                placeholder={t('list.inpPlaceholder')}
                value={state.editedTitle}
                onChange={(e) =>
                  setState((old) => ({ ...old, editedTitle: e.target.value }))
                }
                onBlur={cancelEditing}
                onPressEnter={handleSave}
                autoFocus
              />
            )}
          </List.Item>
        )}
        footer={
          <Button
            type="primary"
            icon={<BsPlus className="inline mr-2 text-xl" />}
            onClick={addNewMethod}
            block
          >
            {t('list.assessmentMethodology.AddBtnText')}
          </Button>
        }
        loading={state.isLoading}
        bordered
      />
    </div>
  );
};

export default AssessmentMethodologies;
