import { BellOutlined, SettingOutlined } from '@ant-design/icons';
import { Badge, Button, Empty, List, Modal, Switch } from 'antd';
import { useState } from 'react';
import { useQuery } from 'react-query';

import { UsersService } from '../api/services';
import { useAuth, useAuthDispatch } from '../hooks/useAuth';
import { AppNotification, NotifyType, User } from '../types';
import { groupSimilar } from '../utils/groupSimilar';
import { fetchNotifications } from './fetchNotifications';
import { getNotificationRenderer } from './notificationRenderers';
import { notificationSettings } from './notificationSettings';
import { timeDiff } from './timeDiff';
import { useNotificationsCount } from './useNotificationsCount';

export function NotificationsModal() {
  const dispatch = useAuthDispatch();

  const { user } = useAuth();
  const { notify = {} as NotifyType } = user as User;

  const { data } = useQuery(['notifications', notify], fetchNotifications);
  // For Modal
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [settingsVisible, setSettingsVisible] = useState(false);

  const handleChange = (checked: boolean, type: string) => {
    UsersService.patch(user?._id, { [`notify.${type}`]: checked }).then(
      (res: User) => dispatch({ type: 'user update', payload: res }),
    );
  };

  function filterNotificationsWRTPreference(records: Array<AppNotification>) {
    return (records || []).filter(({ type }) => !!notify[type]);
  }

  const notificationsToBeRendered = filterNotificationsWRTPreference(
    data || [],
  );

  const hourInMS = 60 * 60 * 1000;
  const groupedNotification = groupSimilar(
    notificationsToBeRendered,
    (prev, curr) =>
      prev.type === curr.type &&
      prev.senderId === curr.senderId &&
      timeDiff(prev.createdAt, curr.createdAt) < hourInMS &&
      prev.packagingId === curr.packagingId &&
      prev.productId === curr.productId,
  );

  const { data: notificationsCount } = useNotificationsCount();
  const [isCleared, setCleared] = useState(false);

  const areAllNotificationsTurnedOff = !notificationSettings.some(
    ({ type }) => (user?.notify || {})[type] === true,
  );

  return (
    <>
      <Badge count={isCleared ? 0 : notificationsCount} size={'small'}>
        <Button
          type="link"
          icon={<BellOutlined />}
          title="Notification"
          className="m-nav-btns"
          onClick={() => {
            setNotificationVisible(true);
            UsersService.patch(user?._id, {
              notificationReadAt: new Date(),
            }).then((res: User) => {
              dispatch({ type: 'user update', payload: res });
              setCleared(true);
            });
          }}
        ></Button>
      </Badge>

      <Modal
        title={'Notifications'}
        cancelButtonProps={{ style: { display: 'none' } }}
        visible={notificationVisible}
        onCancel={() => setNotificationVisible(false)}
        bodyStyle={{ maxHeight: '70vh', overflow: 'scroll' }}
        footer={
          <div className="flex justify-between">
            <Button
              onClick={() => setSettingsVisible(true)}
              type="text"
              icon={<SettingOutlined />}
            ></Button>
            <Button
              type="primary"
              onClick={() => setNotificationVisible(false)}
            >
              Close
            </Button>
          </div>
        }
      >
        {areAllNotificationsTurnedOff ? (
          <Empty
            description={
              'You have turned off all notifications, to see something here please turn on some notifications '
            }
          >
            <Button onClick={() => setSettingsVisible(true)} type="primary">
              Change notification settings
            </Button>
          </Empty>
        ) : (
          <List
            size="small"
            className="list"
            itemLayout="horizontal"
            dataSource={groupedNotification}
            renderItem={(item: AppNotification) =>
              getNotificationRenderer(item, user as User)
            }
          />
        )}
      </Modal>

      <Modal
        title="Notification settings"
        visible={settingsVisible}
        onCancel={() => setSettingsVisible(false)}
        footer={
          <Button type="primary" onClick={() => setSettingsVisible(false)}>
            Close
          </Button>
        }
      >
        {notificationSettings.map(({ type, label, description }) => {
          return (
            <div key={type} className="flex justify-between mb-4">
              <div>
                <h2>{label}</h2> <p className="text-gray-500">{description}</p>
              </div>
              <div>
                <Switch
                  onChange={(checked) => handleChange(checked, type)}
                  checked={notify[type]}
                />
              </div>
            </div>
          );
        })}
      </Modal>
    </>
  );
}
