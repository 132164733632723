import client from '../../../../api/client';
import { Evidence } from '../../../../types';

export function fetchEvidence(options: any) {
  const { queryKey } = options;
  const [, pagination, packagingId] = queryKey;
  let query = { ...pagination, packagingId, $sort: { createdAt: -1 } };

  return client.service('packaging/evidence').find({ query });
}

export function createEvidence(evidence: Partial<Evidence>) {
  return client.service('packaging/evidence').create(evidence);
}

export function removeEvidence(_id: string) {
  return client.service('packaging/evidence').remove(_id);
}
