import React, { useEffect, useState } from 'react';

import PageContainer from '../Layout';
import ListsContainer from './ListsContainer';

// interface ListsProps {};

const Lists = () => {
  return (
    <PageContainer activeTab="lists">
      <ListsContainer />
    </PageContainer>
  );
};

export default Lists;
