import { PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';

import { ActiveReportingPeriodProvider } from '../hooks/useActiveReportingPeriod';
import { AuthProvider } from '../hooks/useAuth';
import ReauthenticateProvider from './ReAuthenticateProvider';
import { ReportingPeriodProvider } from './ReportingPeriodProvider';

const queryClient = new QueryClient();

export default function AllProviders({ children }: PropsWithChildren<{}>) {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <ReauthenticateProvider>
            <ActiveReportingPeriodProvider>
              <ReportingPeriodProvider>{children}</ReportingPeriodProvider>
            </ActiveReportingPeriodProvider>
          </ReauthenticateProvider>
        </AuthProvider>{' '}
      </BrowserRouter>
      <ReactQueryDevtools position={'bottom-right'} initialIsOpen={false} />{' '}
    </QueryClientProvider>
  );
}
