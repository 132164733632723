import { List } from 'antd';
import moment from 'moment';

import { AppNotification, User } from '../types';
import { webBaseURL } from '../utils';
import { getNotificationTitle } from './getNotificationTitle';
import { getFullName } from './Packaging/getFullName';
import { getUserRoleLabel } from './Users/getUserRoleLabel';

function getSenderName(senderName: string, currentUserName: string) {
  console.log({ senderName, currentUserName });
  return senderName === currentUserName ? 'You' : senderName;
}

export const notificationRenderers = new Map();
notificationRenderers.set('mention', (item: AppNotification, user: User) => {
  const url = webBaseURL(`edit-packaging/${item.packagingId}`);
  return (
    <List.Item>
      <List.Item.Meta
        title={
          <div className="flex justify-between">
            <div>
              {getNotificationTitle(item.type)}{' '}
              {(item.grouped || []).length > 0
                ? 'x' + (item.grouped.length + 1)
                : null}{' '}
            </div>
            <div
              className="text-gray-500"
              title={moment(item.createdAt).format('YYYY-MM-DD HH:mm')}
            >
              {moment(item.createdAt).fromNow()}
            </div>
          </div>
        }
        description={
          <span
            dangerouslySetInnerHTML={{
              __html: `You were mentioned by <b>${
                getSenderName(item.senderName, getFullName(user)) || ''
              }</b> in <a target="__blank" href="${url}">${
                item.packagingId || ''
              }</a> in company ${item.companyId || ''} and reporting period ${
                item.reportingYear || ''
              } (${(item.reportingPeriodId || '').slice(-6)}).`,
            }}
          ></span>
        }
      />
    </List.Item>
  );
});
notificationRenderers.set('newLogin', (item: AppNotification, user: User) => {
  return (
    <List.Item>
      <List.Item.Meta
        title={
          <div className="flex justify-between">
            <div>
              {' '}
              {getNotificationTitle(item.type)}{' '}
              {(item.grouped || []).length > 0
                ? 'x' + (item.grouped.length + 1)
                : null}{' '}
            </div>
            <div
              className="text-gray-500"
              title={moment(item.createdAt).format('YYYY-MM-DD HH:mm')}
            >
              {moment(item.createdAt).fromNow()}
            </div>
          </div>
        }
        description={
          <span
            dangerouslySetInnerHTML={{
              __html: `Your account was logged in.`,
            }}
          ></span>
        }
      />
    </List.Item>
  );
});
notificationRenderers.set('userRole', (item: AppNotification, user: User) => {
  return (
    <List.Item>
      <List.Item.Meta
        title={
          <div className="flex justify-between">
            <div>
              {' '}
              {getNotificationTitle(item.type)}{' '}
              {(item.grouped || []).length > 0
                ? 'x' + (item.grouped.length + 1)
                : null}{' '}
            </div>
            <div
              className="text-gray-500"
              title={moment(item.createdAt).format('YYYY-MM-DD HH:mm')}
            >
              {moment(item.createdAt).fromNow()}
            </div>
          </div>
        }
        description={
          <span
            dangerouslySetInnerHTML={{
              __html: `Your role was changed to <strong>
              ${getUserRoleLabel(item.role)}</strong>`,
            }}
          ></span>
        }
      />
    </List.Item>
  );
});
notificationRenderers.set('product', (item: AppNotification, user: User) => {
  return (
    <List.Item>
      <List.Item.Meta
        title={
          <div className="flex justify-between">
            <div>
              {' '}
              {getNotificationTitle(item.type)}{' '}
              {(item.grouped || []).length > 0
                ? 'x' + (item.grouped.length + 1)
                : null}{' '}
            </div>
            <div
              className="text-gray-500"
              title={moment(item.createdAt).format('YYYY-MM-DD HH:mm')}
            >
              {moment(item.createdAt).fromNow()}
            </div>
          </div>
        }
        description={
          <span
            dangerouslySetInnerHTML={{
              __html: `<strong>
              ${getSenderName(item.senderName, getFullName(user))}
              </strong>} updated a product with id <strong>${
                item.productId
              }</strong>`,
            }}
          ></span>
        }
      />
    </List.Item>
  );
});

const packagingNotificationRenderer = (item: AppNotification, user: User) => {
  let text = '';

  if (item.isBulkPackaging) {
    const url = webBaseURL(`bulk-packagings?searchTerm=${item.packagingName}`);
    text = `<strong>${getSenderName(
      item.senderName,
      getFullName(user),
    )}</strong> updated a bulk packaging with id <a target="__blank" href="${url}">${
      item.packagingId
    }</a>`;
  } else {
    const url = webBaseURL(`edit-packaging/${item.packagingId}`);
    text = `<strong>${getSenderName(
      item.senderName,
      getFullName(user),
    )}</strong> updated a regular packaging with id <a target="__blank" href="${url}">${
      item.packagingId
    }</a> `;
  }

  return (
    <List.Item>
      <List.Item.Meta
        title={
          <div className="flex justify-between">
            <div>
              {getNotificationTitle(item.type)}{' '}
              {(item.grouped || []).length > 0
                ? 'x' + (item.grouped.length + 1)
                : null}{' '}
            </div>
            <div
              className="text-gray-500"
              title={moment(item.createdAt).format('YYYY-MM-DD HH:mm')}
            >
              {moment(item.createdAt).fromNow()}
            </div>
          </div>
        }
        description={
          <div
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          ></div>
        }
      />
    </List.Item>
  );
};
notificationRenderers.set('packaging', packagingNotificationRenderer);
notificationRenderers.set('bulkPackaging', packagingNotificationRenderer);

export function getNotificationRenderer(item: AppNotification, user: User) {
  if (notificationRenderers.has(item.type)) {
    return notificationRenderers.get(item.type)(item, user);
  }

  throw new Error(`No renderer defined for notification type ${item.type}`);
}
