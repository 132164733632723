import { Col, Row, Select, Switch } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import { ColumnProps } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';

import { Packaging } from '../../../types';
import { getRandomAlphaNumericString } from '../../../utils';
import { colMapping } from './ImportPackagings';

interface ComparePackagingsProps {
  userColumns: any[];
  tableColumns: ColumnProps<Packaging>[];
  colMapping: colMapping;
  updateColMapping: (col: colMapping) => void;
  removeMapping: (dataIndex: keyof Packaging) => void;
}

const ComparePackagings = ({
  tableColumns,
  colMapping,
  userColumns,
  updateColMapping,
  removeMapping,
}: ComparePackagingsProps) => {
  const { t } = useTranslation();
  const mapped = Object.keys(colMapping);
  const includedColumns = Object.values(colMapping);

  if (userColumns.length === 0) {
    return (
      <h1 className="my-2 text-lg font-bold text-center">
        {t('No data found')}
      </h1>
    );
  }

  return (
    <div>
      <Row className="mb-2 font-bold">
        <Col span={10}> {t('Database column')}</Col>
        <Col span={9}> {t('Spreadsheet column')}</Col>
        <Col offset={1} span={4}>
          {t('To be imported')}
        </Col>
      </Row>
      {tableColumns.map((col) => {
        const colName = col.title as string;

        return (
          <Row
            key={col.dataIndex as string}
            className="p-2 mb-3 border"
            align="middle"
          >
            <Col span={10}>
              <span className="font-bold">{colName}</span>
            </Col>
            <Col span={9}>
              <Select
                value={
                  colMapping[col.dataIndex as keyof Packaging]
                    ? ({
                        value: colMapping[col.dataIndex as keyof Packaging],
                        label: userColumns.find(
                          (item: string) =>
                            item.trim().toLowerCase() ===
                            colMapping[col.dataIndex as keyof Packaging],
                        ),
                      } as LabeledValue)
                    : undefined
                }
                placeholder={t('Select field...')}
                onChange={(selected: LabeledValue) => {
                  if (selected) {
                    updateColMapping({
                      [col.dataIndex as string]: selected.value,
                    } as colMapping);
                  }
                }}
                className="w-full"
                onClear={() => removeMapping(col.dataIndex as keyof Packaging)}
                allowClear
                labelInValue
              >
                {userColumns
                  .filter(
                    (item: string) =>
                      !includedColumns.includes(item.trim().toLowerCase()),
                  )
                  .map((item) => (
                    <Select.Option
                      key={getRandomAlphaNumericString()}
                      value={item.trim().toLowerCase()}
                    >
                      {item}
                    </Select.Option>
                  ))}
              </Select>
            </Col>
            <Col offset={1} span={4}>
              <Switch
                checked={mapped.includes(col.dataIndex as keyof Packaging)}
                checkedChildren={t('Yes')}
                unCheckedChildren={t('No')}
                onClick={() => removeMapping(col.dataIndex as keyof Packaging)}
              />
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default ComparePackagings;
