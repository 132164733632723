import {
  AppstoreAddOutlined,
  DashboardOutlined,
  OrderedListOutlined,
  ShopOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaBoxes, FaCalendarAlt } from 'react-icons/fa';
import { FiPackage } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { useActiveReportingPeriod } from '../../hooks/useActiveReportingPeriod';
import { useAuth } from '../../hooks/useAuth';
import { isMasterAccount } from '../../utils';

const { Sider } = Layout;

interface SidebarProps {
  activeTab: string;
  sidebarCollapse: boolean;
  setSidebarCollapse: (collapse: boolean) => void;
}

const disableText: string = 'Enable by activating reporting period';
const Sidebar = ({
  activeTab,
  sidebarCollapse,
  setSidebarCollapse,
}: SidebarProps) => {
  const { t } = useTranslation();
  const auth = useAuth() || {};

  const { hasActiveReportingPeriod } = useActiveReportingPeriod();

  return (
    <Sider
      collapsed={sidebarCollapse}
      onCollapse={(isCollapsed) => setSidebarCollapse(isCollapsed)}
      style={{
        marginTop: '60px',
        position: 'fixed',
        minHeight: '100vh',
      }}
      collapsible
    >
      <Menu
        style={{
          paddingLeft: '15px',
        }}
        theme="dark"
        selectedKeys={[activeTab]}
        defaultSelectedKeys={['dashboard']}
        mode="inline"
      >
        {isMasterAccount(auth) && (
          <Menu.Item icon={<DashboardOutlined />} key="dashboard">
            <Link className="text-white" to="/">
              {t('sideBar.dashboard')}
            </Link>
          </Menu.Item>
        )}
        {!isMasterAccount(auth) ? (
          <>
            <Menu.Item
              title={!hasActiveReportingPeriod && disableText}
              disabled={!hasActiveReportingPeriod}
              icon={<DashboardOutlined />}
              key="dashboard"
            >
              <Link className="text-white" to="/">
                {t('sideBar.dashboard')}
              </Link>
            </Menu.Item>
            <Menu.Item
              title={!hasActiveReportingPeriod && disableText}
              disabled={!hasActiveReportingPeriod}
              icon={<AppstoreAddOutlined />}
              key="product"
            >
              <Link className="text-white" to="/products">
                {t('sideBar.products')}
              </Link>
            </Menu.Item>

            <Menu.Item
              title={!hasActiveReportingPeriod && disableText}
              disabled={!hasActiveReportingPeriod}
              icon={<FiPackage />}
              key="packaging"
            >
              <Link className="text-white" to="/packagings">
                {t('sideBar.packaging')}
              </Link>
            </Menu.Item>
          </>
        ) : null}

        {isMasterAccount(auth) ? (
          <Menu.Item icon={<OrderedListOutlined />} key="lists">
            <Link className="text-white" to="/lists">
              {t('sideBar.lists')}
            </Link>
          </Menu.Item>
        ) : null}
        {isMasterAccount(auth) ? (
          <Menu.Item icon={<ShopOutlined />} key="companies">
            <Link className="text-white" to="/companies">
              {t('sideBar.companies')}
            </Link>
          </Menu.Item>
        ) : null}
        {!isMasterAccount(auth) ? (
          <>
            <Menu.Item
              title={!hasActiveReportingPeriod && disableText}
              disabled={!hasActiveReportingPeriod}
              icon={<FaBoxes />}
              key="bulk-packaging"
            >
              <Link className="text-white" to="/bulk-packagings">
                {t('Bulk packaging')}
              </Link>
            </Menu.Item>

            <Menu.Item
              title={!hasActiveReportingPeriod && disableText}
              icon={<FaCalendarAlt />}
              key="reporting-periods"
            >
              <Link className="text-white" to="/reporting-periods">
                {t('Reporting Periods')}
              </Link>
            </Menu.Item>
          </>
        ) : null}
        {!isMasterAccount(auth) && (
          <Menu.Item
            title={!hasActiveReportingPeriod && disableText}
            disabled={!hasActiveReportingPeriod}
            icon={<TeamOutlined />}
            key="users"
          >
            <Link className="text-white" to="/users">
              {t('sideBar.users')}
            </Link>
          </Menu.Item>
        )}
        {isMasterAccount(auth) && (
          <Menu.Item icon={<TeamOutlined />} key="users">
            <Link className="text-white" to="/users">
              {t('sideBar.users')}
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
