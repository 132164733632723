import { useQuery } from 'react-query';

import { ReportingPeriodsService } from '../api/services';
import { Paginated, ReportingPeriod } from '../types';

function getLatestReportingPeriod() {
  return ReportingPeriodsService.find({
    query: { $sort: { createdAt: -1 }, $limit: 1 },
  }).then((res: Paginated<ReportingPeriod>) => res.data[0]);
}

/**
 * This hook returns the latest reporting period created in company,
 * this may not be the activate reporting period, if you
 * want to get the currently active reporting period,
 * then use useActiveReportingPeriod hook instead
 *
 * e.g. if user has following reporting periods
 * 2022, 2021, 2020 (active), 2019
 * it will return 2022 reporting period's data because that is the most
 * recent
 */
export function useLatestReportingPeriod() {
  const { data: reportingPeriod, isLoading } = useQuery(
    ['latest-reporting-period'],
    getLatestReportingPeriod,
  );

  return { reportingPeriod, isLoading };
}
