import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Popconfirm, Table, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { UsersService } from '../../api/services';
import { useAuth } from '../../hooks/useAuth';
import { useCompanyUsers } from '../../hooks/useCompanyUsers';
import { User } from '../../types';
import { isAdmin, isObserverUser } from '../../utils';
import { getUserRoleLabel } from './getUserRoleLabel';
import PasswordUpdate from './PasswordUpdate';
import UserEdit from './UserEdit';

const defaultLimit = +(localStorage.getItem('users-pageSize') || 50);

type userArchive = {
  isArchived: boolean;
};

const UsersContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const auth = useAuth();
  const search = useLocation().search;
  const showArchivedOnly =
    new URLSearchParams(search).get('archived') === 'true';
  const [userEdit, setUserEdit] = useState({
    isEditing: false,
    editedRecord: {} as User,
  });
  const { refetch, archivedOnly, activeOnly } = useCompanyUsers();
  const [passwordReset, setPasswordReset] = useState({
    isEditing: false,
    userId: '',
  });
  const [state, setState] = useState({ loading: false });

  const handleRemove = (userId: string) => {
    setState((old) => ({ ...old, loading: true }));
    UsersService.remove(userId)
      .catch((e: Error) => {
        console.log('Error in deleting user: ', e);
        message.error(t('usersPage.list.messages.delete'));
      })
      .finally(() => setState((old) => ({ ...old, loading: false })));
  };

  const updateIsArchived = (id: string, data: userArchive) => {
    // message.loading({
    //   content: t('usersPage.container.messages.saving'),
    //   key: 'users',
    //   duration: 0,
    // });
    setState({ loading: true });
    UsersService.patch(id, data).then(
      () => {
        message.success({
          content: t('user updated'),
          key: 'users',
        });
      },
      (error: Error) => {
        console.log('Error in archiving user: ', error);
        message.error({
          content: t('users is archiving'),
          key: 'users',
        });
      },
    );
    setState({ loading: false });
  };
  const toggleActivation = (userId: string, isActive: boolean) => {
    console.log(userId);
    UsersService.patch(userId, { isActive }).catch((error: Error) => {
      message.error(
        isActive
          ? t('usersPage.list.messages.couldNotActive')
          : t('usersPage.list.messages.couldNotDeactivate'),
      );
      console.log('Error in updating user: ', error.message);
    });
  };
  const handleClose = () =>
    setUserEdit({ isEditing: false, editedRecord: {} as User });
  let columns: ColumnProps<User>[] = [
    {
      title: t('usersPage.list.table.reference#'),
      dataIndex: '_id',
    },
    {
      title: t('usersPage.list.table.firstName'),
      dataIndex: 'firstName',
      render: (text, record) =>
        record._id === auth?.user?._id ? `${text} (you)` : text,
    },
    { title: t('usersPage.list.table.lastName'), dataIndex: 'lastName' },
    { title: t('usersPage.list.table.email'), dataIndex: 'email' },
    { title: t('usersPage.list.table.phoneNumber'), dataIndex: 'phone' },
    { title: t('usersPage.list.table.jobTitle'), dataIndex: 'title' },
    {
      title: t('usersPage.list.table.role'),
      dataIndex: 'role',
      render: (text) => getUserRoleLabel(text),
    },
    {
      title: t('usersPage.list.table.status'),
      dataIndex: 'isActive',
      render: (text) => (text ? 'Active' : 'Disabled'),
    },
    {
      title: t('usersPage.list.table.action'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <Dropdown
            arrow
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  onClick={() =>
                    setUserEdit({ isEditing: true, editedRecord: record })
                  }
                >
                  {t('usersPage.list.actions.edit')}
                </Menu.Item>
                <Menu.Item
                  key="2"
                  onClick={() =>
                    setPasswordReset({ isEditing: true, userId: record._id })
                  }
                  disabled={!isAdmin(auth) && record._id !== auth.user?._id}
                >
                  {t('usersPage.list.actions.reset')}
                </Menu.Item>
                <Menu.Item
                  key="3"
                  disabled={record._id === auth?.user?._id || !isAdmin(auth)}
                >
                  <Popconfirm
                    title={
                      record.isActive
                        ? t('usersPage.list.messages.popConfirmDeactivateText')
                        : t('usersPage.list.messages.popConfirmActivateText')
                    }
                    okText={t('usersPage.list.okText')}
                    onConfirm={() =>
                      toggleActivation(record._id, !record.isActive)
                    }
                    placement="bottomLeft"
                    disabled={record._id === auth?.user?._id || !isAdmin(auth)}
                  >
                    {record.isActive
                      ? t('usersPage.list.messages.deactivate')
                      : t('usersPage.list.messages.activate')}
                  </Popconfirm>
                </Menu.Item>
                <Menu.Item
                  key="4"
                  disabled={record._id === auth?.user?._id || !isAdmin(auth)}
                >
                  <Popconfirm
                    title={t('usersPage.list.messages.popDeleteConfirmText')}
                    okText={t('usersPage.list.okText')}
                    onConfirm={() => handleRemove(record._id)}
                    disabled={record._id === auth?.user?._id || !isAdmin(auth)}
                  >
                    {t('usersPage.list.actions.delete')}
                  </Popconfirm>
                </Menu.Item>
                {showArchivedOnly ? (
                  <Menu.Item
                    key="5"
                    onClick={() =>
                      updateIsArchived(record?._id, { isArchived: false })
                    }
                  >
                    Unarchived
                  </Menu.Item>
                ) : (
                  <Menu.Item
                    key="6"
                    onClick={() =>
                      updateIsArchived(record._id, { isArchived: true })
                    }
                  >
                    Archive
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <MoreOutlined className="px-2 cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  if (isObserverUser(auth)) {
    columns = columns.filter((item) => item.dataIndex !== 'actions');
  }

  useEffect(() => {
    const handleUpdate = () => {
      refetch();
    };

    UsersService.on('created', handleUpdate);
    UsersService.on('updated', handleUpdate);
    UsersService.on('patched', handleUpdate);
    UsersService.on('removed', handleUpdate);
    return () => {
      UsersService.off('created', handleUpdate);
      UsersService.off('updated', handleUpdate);
      UsersService.off('patched', handleUpdate);
      UsersService.off('removed', handleUpdate);
    };
  }, []);

  const rows = showArchivedOnly ? archivedOnly : activeOnly;

  return (
    <div className="p-4 bg-white">
      <header className="flex items-center justify-between mb-4">
        <h1 className="font-sans text-2xl font-bold md:text-3xl">
          {t('usersPage.list.users')}
        </h1>

        {isObserverUser(auth) ? null : (
          <div>
            <Button
              className="mr-2"
              onClick={() => {
                history.push(`/users?archived=${!showArchivedOnly}`);
              }}
            >
              Show {!showArchivedOnly ? 'archived' : 'unarchived'} users
            </Button>
            <Button
              type="primary"
              className="font-bold text-white"
              onClick={() =>
                setUserEdit({ isEditing: true, editedRecord: {} as User })
              }
            >
              {t('usersPage.list.btnTxt')}
            </Button>
          </div>
        )}
      </header>
      <main>
        <Table
          rowKey="_id"
          columns={columns}
          dataSource={rows}
          pagination={{
            total: rows?.length,
            pageSize: defaultLimit,
            showSizeChanger: true,
            pageSizeOptions: ['50', '100', '250', '500'],
            onShowSizeChange: (page, size) => {
              localStorage.setItem('users-pageSize', size.toString());
            },
          }}
          loading={state.loading}
          scroll={{ x: true }}
        />
      </main>

      <UserEdit {...userEdit} handleClose={handleClose} />
      <PasswordUpdate
        {...passwordReset}
        handleClose={() => setPasswordReset({ isEditing: false, userId: '' })}
      />
    </div>
  );
};

export default UsersContainer;
