import { useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { ReportingPeriodsService } from '../../api/services';
import { getCurrentCompanyId } from '../../components/getCurrentCompanyId';
import { PageProps } from '../../types';
import PageContainer from '../Layout';
import { getAllReportingPeriods } from './getAllReportingPeriods';
import { LoadingPage } from './LoadingPage';
import { ReportingPeriodsList } from './ReportingPeriodsList';

const ReportingPeriods = (props: PageProps) => {
  const { user } = props.auth;
  const currentCompanyId = getCurrentCompanyId(user);
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery(
    ['reporting-periods', currentCompanyId],
    getAllReportingPeriods,
  );

  useEffect(() => {
    const handleChange = () => {
      queryClient.invalidateQueries(['reporting-periods', currentCompanyId]);
    };

    ReportingPeriodsService.on('created', handleChange);
    ReportingPeriodsService.on('patched', handleChange);
    ReportingPeriodsService.on('removed', handleChange);
    return () => {
      ReportingPeriodsService.off('created', handleChange);
      ReportingPeriodsService.off('patched', handleChange);
      ReportingPeriodsService.off('removed', handleChange);
    };
  }, []);

  if (isError) {
    //TODO: add a proper error page
    throw new Error('Oops! there was some error in fetching data!');
  }

  return (
    <PageContainer activeTab="reporting-periods">
      {isLoading ? (
        <LoadingPage />
      ) : (
        <ReportingPeriodsList reportingPeriods={data.data} {...props} />
      )}
    </PageContainer>
  );
};

export default ReportingPeriods;
