import { Empty, Spin, message } from 'antd';
import { useQuery } from 'react-query';

import { fetchPackagingComments } from './api';
import CommentItem from './CommentItem';

const CommentsList = ({ packagingId }: { packagingId: string }) => {
  const { isLoading, data: comments } = useQuery(
    ['packaging/comments', packagingId],
    fetchPackagingComments,
    {
      onError: (e: Error) => {
        message.error('Error in fetching comments');
        console.log('Error in fetching comments: ', e);
      },
    },
  );

  return (
    <div style={{ height: '8.65rem', overflowY: 'auto' }}>
      <Spin spinning={isLoading}>
        {comments?.data?.length === 0 && !isLoading ? (
          <Empty
            className="text-gray-400 font-semibold"
            description="No comments to show"
          />
        ) : (
          comments?.data?.map((item) => (
            <CommentItem data={item} key={item._id} />
          ))
        )}
      </Spin>
    </div>
  );
};

export default CommentsList;
