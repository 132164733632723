import { SortOrder, SorterResult } from 'antd/lib/table/interface';
import i18next from 'i18next';

import { AuthState } from '../hooks/useAuth';
import { CategoryType, SortState } from '../types';

export function apiBaseURL(path = '') {
  let url = 'http://localhost:3030';

  if (isEnv('production')) {
    url = 'https://mpr.zerowastecity.com/api';
  }

  if (process.env.REACT_APP_ENV === 'test') {
    url = 'https://devmpr.zerowastecity.com/api';
  }

  if (path.trim().length) {
    return `${url}/${path}`;
  }

  return `${url}`;
}

export function webBaseURL(path = '') {
  let url = `http://localhost:${process.env.PORT || 3050}`;

  if (isEnv('production')) {
    url = 'https://mpr.zerowastecity.com';
  }

  if (process.env.REACT_APP_ENV === 'test') {
    url = 'https://devmpr.zerowastecity.com';
  }

  if (path.trim().length) {
    return `${url}/${path}`;
  }

  return `${url}`;
}

export function getBase64(file: File | Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function currentEnv() {
  return process.env.REACT_APP_ENV;
}

export function isEnv(env: string) {
  return currentEnv() === env;
}

export function getRandomAlphaNumericString(length = 10) {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const isMasterUser = (auth = {} as AuthState) =>
  auth?.user?.role?.startsWith('zwc');
export const isClientUser = (auth = {} as AuthState) =>
  auth?.user?.role?.startsWith('company');
export const isAdmin = (auth = {} as AuthState) =>
  auth?.user?.role === 'zwc-admin' || auth?.user?.role === 'company-admin';
export const isMasterAccount = (auth = {} as AuthState) =>
  isMasterUser(auth) && !localStorage.getItem('view-as');
export const isObserverUser = (auth = {} as AuthState) =>
  auth?.user?.role === 'company-observer';

export const switchedMasterAccount = (auth = {} as AuthState) =>
  isMasterUser(auth) && !!localStorage.getItem('view-as');

export const getAccountTitle = (auth = {} as AuthState) => {
  if (switchedMasterAccount(auth)) {
    return localStorage.getItem('company-name') || 'Company Account';
  } else if (isMasterUser(auth)) {
    return 'ZWC Master Account';
  } else {
    return auth?.user?.company?.businessName || 'Company Account';
  }
};

export const MaterialCategories: CategoryType[] = [
  i18next.t('utils.paper'),
  i18next.t('utils.plastic'),
  i18next.t('utils.glass'),
  i18next.t('utils.metal'),
  i18next.t('utils.composite'),
  i18next.t('utils.wood'),
  i18next.t('utils.otheri'),
];

export const PackagingStatuses = [
  {
    label: i18next.t('utils.packagingStatus.redFlag'),
    value: 'redFlag',
  },
  {
    label: i18next.t('utils.packagingStatus.proxy'),
    value: 'proxy',
  },
  {
    label: i18next.t('utils.packagingStatus.assumptions'),
    value: 'assumptions',
  },
];

export const getNEACategories = (category: CategoryType) => {
  const map = new Map();

  map.set('composite', [
    i18next.t('utils.composite_packs'),
    'Beverage carton',
    i18next.t('utils.composite_productPackaging'),
    i18next.t('utils.other'),
  ]);

  map.set('glass', [
    i18next.t('utils.beverageCan'),
    i18next.t('utils.product_excluding'),
    i18next.t('utils.other'),
  ]);

  map.set('metal', [
    i18next.t('utils.beverageCan'),
    i18next.t('utils.metal_disposables'),
    i18next.t('utils.metal_foodCan'),
    i18next.t('utils.metal_productPackaging'),
    i18next.t('utils.other'),
  ]);

  map.set('paper', [
    i18next.t('utils.Carrier_bag'),
    i18next.t('utils.paper_Disposables'),
    i18next.t('utils.Product_packaging'),
    i18next.t('utils.transportProtective'),
    i18next.t('utils.other'),
  ]);

  map.set('plastic', [
    i18next.t('utils.Carrier_bag'),
    i18next.t('utils.plastic_Disposables'),
    i18next.t('utils.beverage_bottle'),
    i18next.t('utils.product_'),
    i18next.t('utils.pckg_flexible'),
    i18next.t('utils.transport'),
    i18next.t('utils.other'),
  ]);

  map.set('wood', [
    i18next.t('utils.wood_create'),
    i18next.t('utils.wood_pallet'),
    i18next.t('utils.wood_productPackaging'),
    i18next.t('utils.wood_transportPackaging'),
    i18next.t('utils.other'),
  ]);

  map.set('other', ['Carrier bag', 'Others']);
  map.set('others', ['Carrier bag', 'Others']);

  return map.get(category) || [];
};

export const getNEAMaterialTypesMap = () => {
  const types = new Map();

  types.set('Plastic', [
    i18next.t('utils.pet'),
    i18next.t('utils.HDPE'),
    i18next.t('utils.LDPE'),
    i18next.t('utils.Unknown'),
    i18next.t('utils.PVC'),
    i18next.t('utils.PP'),
    i18next.t('utils.PS'),
    i18next.t('utils.EPS'),
    i18next.t('utils.other'),
  ]);

  types.set('Paper', ['Paper', 'Paperboard', 'Corrugated', 'Others']);

  types.set('Metal', ['Aluminium', 'Steel', 'Tin', 'Others']);

  types.set('Glass', ['Clear', 'Green', 'Brown', 'Other colours']);

  types.set('Wood', ['Wood']);

  types.set('Composite', ['Composite']);

  types.set('Others', [
    'Oxo-degradable/oxo-biodegradable',
    'Biodegradable/compostable',
    'Others',
  ]);

  return types;
};

export const getSortOrder = (
  sort = {} as SortState,
  key: string,
): SortOrder => {
  if (sort[key] === -1) {
    return 'descend';
  } else if (sort[key] === 1) {
    return 'ascend';
  } else {
    return null;
  }
};

export const setUpSorting = (
  sorts: SortState,
  fieldName: string,
  priority: number,
) => ({
  sorter: { multiple: priority },
  sortOrder: getSortOrder(sorts, fieldName),
});

export const handleSorting = <T>(
  sort = [] as SorterResult<T> | SorterResult<T>[],
  sorts = {} as SortState,
  setSorts: (sortingData: SortState) => void,
) => {
  let data = Array.isArray(sort) ? sort : [sort];
  const sortHold = data.reduce((acc, curr) => {
    const { field = '', order = '' } = curr;
    const fieldName = Array.isArray(field)
      ? field.join('.')
      : (field as string);
    return !order || !fieldName
      ? acc
      : ({
          [fieldName]: curr.order === 'ascend' ? 1 : -1,
        } as SortState);
  }, {} as SortState);

  setSorts(sortHold);
};

export const getPercentage = (partial = 0, total = 0) =>
  !total ? 0 : Number(((partial / total) * 100).toFixed(2));
