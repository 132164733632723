import { Button, Popconfirm, Table, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillTrashFill } from 'react-icons/bs';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { BulkPackaging, Evidence } from '../../../../../types';
import { getFullName } from '../../../../Packaging/getFullName';
import { fetchEvidence, removeEvidence } from '../evidence.api';

interface EvidenceFileListProps {
  packaging: BulkPackaging;
  handleSave: any;
  readOnly: boolean;
}
const EvidenceFileList = ({
  packaging,
  handleSave,
  readOnly = true,
}: EvidenceFileListProps) => {
  const { _id: packagingId } = packaging;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [pagination, setPagination] = useState({ $limit: 10, $skip: 0 });
  const { $limit, $skip } = pagination;

  const { data: evidence, isLoading } = useQuery(
    ['evidence', pagination, packagingId],
    fetchEvidence,
  );

  const removeMutation = useMutation(removeEvidence);

  const columns: ColumnProps<Evidence>[] = [
    {
      title: t('packagingEditor.evidenceAudit.fileName'),
      dataIndex: 'originalName',
      render: (text, record) => {
        return (
          <a
            rel={'noreferrer'}
            target={'_blank'}
            download={text}
            href={record.url}
          >
            {text}
          </a>
        );
      },
    },
    {
      title: t('packagingEditor.evidenceAudit.timeOfUpload'),
      render: (value, record) => {
        let time = moment(record.createdAt);

        return <div>{time.format('DD MMM YYYY - HH:mm')}</div>;
      },
    },
    {
      title: t('packagingEditor.evidenceAudit.uploadedBy'),
      dataIndex: 'userId',
      render: (_, record: Evidence) =>
        `${getFullName(record?.user)} (${record?.user?._id})`,
    },
    {
      title: t('packagingEditor.evidenceAudit.actions'),
      dataIndex: 'actions',
      render: (text: string, record: Evidence) => (
        <div className="text-center">
          <Popconfirm
            title={t('packagingEditor.evidenceAudit.popText')}
            okText={t('packagingEditor.evidenceAudit.onOk')}
            onConfirm={() =>
              removeMutation.mutate(record._id, {
                onSuccess: () => {
                  handleSave({
                    evidenceCount: (packaging.evidenceCount || 0) - 1,
                  } as BulkPackaging);
                  message.success(
                    t('packagingEditor.evidenceAudit.successMsg'),
                  );
                  queryClient.invalidateQueries('evidence');
                },
                onError: () => {
                  message.error(t('packagingEditor.evidenceAudit.errorMsg'));
                },
              })
            }
            disabled={readOnly}
            placement="topLeft"
          >
            <Button style={{ padding: 0 }} type="text" disabled={readOnly}>
              <BsFillTrashFill />
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div>
        <Table
          columns={columns}
          dataSource={evidence && evidence.data}
          loading={isLoading}
          pagination={{
            hideOnSinglePage: true,
            pageSize: $limit,
            current: $skip / $limit + 1,
            total: evidence && evidence.total,
            onChange: (pageNum) =>
              setPagination((old) => ({
                ...old,
                $skip: (pageNum - 1) * $limit,
              })),
          }}
        />
      </div>
    </div>
  );
};

export default EvidenceFileList;
