import { Button, Form, Input, message } from 'antd';
import { useMutation, useQueryClient } from 'react-query';

import { PackagingComment } from '../../../../../types';
import { createPackagingComment } from './api';

const CommentsEditor = ({ packagingId }: { packagingId: string }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate: addNewComment, isLoading } = useMutation(
    'createComment',
    createPackagingComment,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('packaging/comments');
        form.resetFields();
      },
      onError: (e) => {
        console.log('Error in adding the comment: ', e);
        message.error('Error in adding the comment');
      },
    },
  );

  const handleSubmit = () => {
    form
      .validateFields()
      .then((comment) => addNewComment({ ...comment, packagingId }));
  };

  return (
    <div>
      <Form
        name="packaging-comments-form"
        onFinish={handleSubmit}
        form={form}
        autoComplete="off"
      >
        <Form.Item
          name="text"
          rules={[
            {
              required: true,
              message: 'Please input your comment!',
              whitespace: true,
            },
          ]}
          style={{ marginBottom: '0.5rem' }}
        >
          <Input.TextArea
            placeholder={'Post a comment'}
            onKeyPress={(e) => {
              if (e.code === 'Enter' && e.ctrlKey && !isLoading) {
                handleSubmit();
              }
            }}
          />
        </Form.Item>

        <div className="flex justify-end">
          <Button
            disabled={!packagingId || isLoading}
            type="primary"
            htmlType="submit"
          >
            Post Comment
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default CommentsEditor;
