import { Button, Input, Modal, Switch, Table, Tag, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';

import {
  ReportingPeriodsService,
  UserReportingPeriodsService,
} from '../../api/services';
import { getCurrentCompanyId } from '../../components/getCurrentCompanyId';
import {
  useActiveReportingPeriod,
  useActiveReportingPeriodDispatch,
} from '../../hooks/useActiveReportingPeriod';
import { useAuth } from '../../hooks/useAuth';
import { PageProps, ReportingPeriod } from '../../types';

interface ReportingPeriodListProps extends PageProps {
  reportingPeriods: ReportingPeriod[];
}

function patchReportingPeriod(reportingPeriod: Partial<ReportingPeriod>) {
  return ReportingPeriodsService.patch(reportingPeriod._id, reportingPeriod);
}

function removeReportingPeriod(reportingPeriodId: string) {
  return ReportingPeriodsService.remove(reportingPeriodId);
}

type CopyStatus =
  | 'pending'
  | 'started'
  | 'copying-products'
  | 'copying-links'
  | 'copying-packagings'
  | 'copying-items'
  | 'copying-activities'
  | 'copying-evidence'
  | 'copying-comments'
  | 'done'
  | 'errored';

function getFormattedCopyStatus(text: CopyStatus) {
  switch (text) {
    case 'pending':
      return 'Pending';
    case 'started':
      return 'Started';
    case 'copying-products':
      return 'Copying products';
    case 'copying-links':
      return 'Copying links';
    case 'copying-packagings':
      return 'Copying packagings';
    case 'copying-items':
      return 'Copying items';
    case 'copying-activities':
      return 'Copying activities';
    case 'copying-evidence':
      return 'Copying evidence';
    case 'copying-comments':
      return 'Copying comments';
    case 'done':
      return 'Done';
    case 'errored':
      return 'Errored';
  }
}

const ReportingPeriodsList = (props: ReportingPeriodListProps) => {
  const {
    reportingPeriods,
    auth: { user },
  } = props;
  const { t } = useTranslation();
  const sessionDispatch = useActiveReportingPeriodDispatch();
  const { reportingPeriod, hasActiveReportingPeriod } =
    useActiveReportingPeriod();

  const { isObserver } = useAuth();

  const [state, setState] = useState({
    editAddress: '',
    editValue: '',
  });

  const queryClient = useQueryClient();

  const cancelFieldEditing = () =>
    setState((old) => ({ ...old, editAddress: '', editValue: '' }));
  const editField = (editAddress: string, editValue = '') =>
    setState((old) => ({ ...old, editAddress, editValue }));

  const patchLastAcitveReportingPeriod = (reportingPeriod: ReportingPeriod) => {
    const _id = `${getCurrentCompanyId(user)}:${user?._id}`;
    return UserReportingPeriodsService.patch(_id, {
      reportingPeriodId: reportingPeriod._id,
      action: 'updateLastActiveReportingPeriod',
    });
  };

  const patchLARPMutation = useMutation(patchLastAcitveReportingPeriod);
  const updateMutation = useMutation(patchReportingPeriod);
  const deleteMutation = useMutation(removeReportingPeriod);

  let columns: ColumnProps<ReportingPeriod>[] = [
    {
      title: t('ID #'),
      dataIndex: '_id',
      width: '10%',
      render: (_text, record) => <div>{record._id.slice(-6)}</div>,
    },
    {
      title: t('Reporting year'),
      dataIndex: 'period',
      width: '10%',
      render: (text, record) => (
        <div className="w-28">{record ? record.reportingYear : 'N/A'}</div>
      ),
    },
    {
      title: t('Date created'),
      dataIndex: 'createdAt',
      width: '10%',

      render: (text, record) => (
        <div className="w-28">
          {record ? moment(record.createdAt).format('DD MMM YYYY') : 'N/A'}
        </div>
      ),
    },
    {
      title: t('Remarks'),
      dataIndex: ['internalFields', 'remarks'],
      width: '15%',

      render: (text, record) =>
        state.editAddress === `remarks-${record._id}` && !record.isArchived ? (
          <Input
            placeholder={t('Enter Remarks')}
            onChange={(e) =>
              setState((old) => ({
                ...old,
                editValue: e?.target?.value,
              }))
            }
            value={state.editValue}
            onPressEnter={() => {
              const { editValue = '' } = state;
              updateMutation.mutate(
                { remarks: editValue, _id: record._id },
                {
                  onSuccess: (res: any) => {
                    console.log('onSuccess res: ', res);
                    cancelFieldEditing();
                    if (record._id === reportingPeriod?._id) {
                      sessionDispatch({
                        type: 'set_session',
                        payload: { reportingPeriod: res },
                      });
                    }
                    message.success('Remarks has been edited');
                    queryClient.invalidateQueries([
                      'reporting-periods',
                      getCurrentCompanyId(user),
                    ]);
                  },
                },
              );
            }}
            onBlur={cancelFieldEditing}
            autoFocus
          />
        ) : (
          <div
            className="w-60"
            onClick={() => {
              editField(`remarks-${record._id}`, text);
            }}
          >
            {record?.remarks || 'N/A'}
          </div>
        ),
    },
    {
      title: t('Archive date'),
      dataIndex: 'archivedAt',
      width: '10%',

      render: (text, record) => (
        <div className="w-28">
          {record?.isArchived
            ? moment(record.archivedAt).format('DD MMM YYYY')
            : 'N/A'}
        </div>
      ),
    },
    {
      title: t('Select reporting year to display'),
      dataIndex: 'yearToDisplay',
      width: '15%',
      render: (text, record) => (
        <div className="w-60">
          <Switch
            disabled={reportingPeriod?._id === record._id}
            checked={reportingPeriod?._id === record._id}
            onChange={() => {
              patchLARPMutation.mutate(record, {
                onSuccess: () => {
                  sessionDispatch({
                    type: 'set_session',
                    payload: { reportingPeriod: record },
                  });
                },
              });
            }}
          />
        </div>
      ),
    },
    {
      title: t('Copy status'),
      dataIndex: 'copyStatus',
      width: '10%',
      render: (text, record) => {
        if (record.copyFromId) {
          return (
            <Tag title={getFormattedCopyStatus(text)}>
              {getFormattedCopyStatus(text)}
            </Tag>
          );
        } else {
          return <div className="w-28">NA</div>;
        }
      },
    },
    {
      title: t('Actions'),
      dataIndex: 'actions',
      width: '10%',

      render: (text, record) => {
        return (
          <>
            <div className="w-28 mr-5 flex align-items">
              {record?.isArchived ? (
                <Button type="link" onClick={() => unArchive(record)}>
                  Unarchive
                </Button>
              ) : (
                <Button type="link" onClick={() => archive(record)}>
                  Archive
                </Button>
              )}

              {record._id !== reportingPeriod?._id && (
                <Button type="link" onClick={() => deleteModal(record)}>
                  Delete
                </Button>
              )}
            </div>
          </>
        );
      },
    },
  ];

  if (isObserver) {
    columns = columns.filter((i) => i.dataIndex !== 'actions');
  }

  const deleteModal = (record: any) => {
    Modal.error({
      title: t('Are you sure you want to delete this reporting Period?'),
      okText: 'Yes',
      maskClosable: true,
      width: 500,
      closable: true,
      onOk: () => {
        deleteMutation.mutate(record._id, {
          onSuccess: () => {
            if (record._id === reportingPeriod?._id) {
              sessionDispatch({
                type: 'set_session',
                payload: { reportingPeriod: undefined },
              });
            }
            message.success('Reporting period has been deleted');

            queryClient.invalidateQueries([
              'reporting-periods',
              getCurrentCompanyId(user),
            ]);
            //TODO: update session
          },
        });
      },
    });
  };

  const archive = (record: any) => {
    Modal.warning({
      title: t(
        'You are about to archive the reporting year. The data will be accessable in a view only mode for all users.',
      ),
      content: t('Are you sure you want to continue?'),
      okText: 'Yes',
      maskClosable: true,
      width: 500,
      closable: true,
      onOk: () => {
        updateMutation.mutate(
          { isArchived: true, _id: record._id },
          {
            onSuccess: (res: any) => {
              if (record._id === reportingPeriod?._id) {
                sessionDispatch({
                  type: 'set_session',
                  payload: { reportingPeriod: res },
                });
              }
              message.success('Reporting period has been archived');

              queryClient.invalidateQueries([
                'reporting-periods',
                getCurrentCompanyId(user),
              ]);
              //TODO: update session
            },
          },
        );
      },
    });
  };

  const unArchive = (record: any) => {
    Modal.warning({
      title: t(
        'You are about to Unarchive the reporting year. The data will be editible for all users.',
      ),
      content: t('Are you sure you want to continue?'),
      okText: 'Yes',
      width: 500,
      maskClosable: true,
      closable: true,
      onOk: () => {
        updateMutation.mutate(
          { isArchived: false, _id: record._id },
          {
            onSuccess: (res: any) => {
              console.log('onSuccess res: ', res);
              if (record._id === reportingPeriod?._id) {
                sessionDispatch({
                  type: 'set_session',
                  payload: { reportingPeriod: res },
                });
              }
              message.success('Reporting period has been unarchived');
              queryClient.invalidateQueries([
                'reporting-periods',
                getCurrentCompanyId(user),
              ]);
              //TODO: update session
            },
          },
        );
      },
    });
  };

  const history = useHistory();

  return (
    <div className="p-4 bg-white">
      <>
        {!hasActiveReportingPeriod && (
          <div className="p-5 mb-6 bg-red-500 text-white">
            {t(
              'Your access to portal is limited until you create/activate a reporting period',
            )}
          </div>
        )}

        <header className="mb-4">
          <div className="flex items-center">
            <div className="font-sans text-2xl font-bold md:text-3xl mr-auto">
              {t('Reporting Periods')}
            </div>
            <div>
              {!isObserver && (
                <Button
                  type="primary"
                  className="font-bold text-white"
                  onClick={() => history.push('/create-reporting-period')}
                >
                  {t('Create a new reporting period')}
                </Button>
              )}
            </div>
          </div>
        </header>
        <main>
          <Table
            rowKey="_id"
            columns={columns}
            dataSource={reportingPeriods}
            scroll={{
              x: true,
              y: 'calc(100vh - 386px)',
            }}
          />
        </main>
      </>
    </div>
  );
};

export { ReportingPeriodsList };
