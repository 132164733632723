import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import AssessmentMethodologies from './AssessmentMethodologies';
import MaterialsCategories from './MaterialsCategories';

interface ListsContainerProps {}

const ListsContainer = ({}: ListsContainerProps) => {
  const { t } = useTranslation();

  return (
    <div className="p-4 bg-white">
      <header className="flex items-center justify-between">
        <span className="font-sans text-2xl font-bold md:text-3xl">
          {t('list.title')}
        </span>
      </header>
      <main>
        <Tabs>
          <Tabs.TabPane
            tab={t('list.tabs.assessmentMethodologies')}
            key="methods"
          >
            <AssessmentMethodologies />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={t('list.tabs.materialCategories')}
            key="categories"
          >
            <MaterialsCategories />
          </Tabs.TabPane>
        </Tabs>
      </main>
    </div>
  );
};

export default ListsContainer;
