import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { AuditTrailService } from '../../../../../api/services';
import { AuditTrail } from '../../../../../types';
import { getFullName } from '../../../getFullName';

function findAuditTrail({ queryKey }: { queryKey: any }) {
  const [, packagingId] = queryKey;

  let query = { packagingId, $limit: 500, $sort: { createdAt: -1 } };

  return AuditTrailService.find({ query });
}
interface AuditTrailTableProps {
  packagingId: string;
}

const AuditTrailTable = ({ packagingId }: AuditTrailTableProps) => {
  const { t } = useTranslation();

  const columns: ColumnProps<AuditTrail>[] = [
    {
      title: t('packagingEditor.auditTrail.user'),
      dataIndex: 'userId',
      render: (_, record: AuditTrail) =>
        `${getFullName(record?.user)} (${record.user._id})`,
    },
    { title: t('packagingEditor.auditTrail.change'), dataIndex: 'text' },
    {
      title: t('packagingEditor.auditTrail.date'),
      dataIndex: 'createdAt',
      render: (text) =>
        text ? moment(text).format('DD MMM YYYY - HH:mm') : '',
    },
  ];

  const {
    data: auditTrail,
    isLoading,
    isError,
  } = useQuery(['audit-trail', packagingId], findAuditTrail);

  if (isError) {
    return <h1>{t('packagingEditor.evidenceAudit.someErr')}</h1>;
  }

  return (
    <div>
      <h2 className="text-sm font-bold">
        {t('packagingEditor.auditTrail.title')}
      </h2>
      <Table
        dataSource={isLoading ? [] : auditTrail?.data || []}
        columns={columns}
        loading={isLoading}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </div>
  );
};

export default AuditTrailTable;
