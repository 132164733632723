import { useQuery } from 'react-query';

import { NotificationsService } from '../api/services';
import { useAuth } from '../hooks/useAuth';
import { AppNotification, NotifyType, Paginated, User } from '../types';

export function useNotificationsCount() {
  const { user } = useAuth();
  const { notify = {} as NotifyType } = user as User;
  const preferredTypes = Object.entries(notify)
    .filter(([key, val]) => !!val)
    .map(([key]) => key);

  return useQuery(['notificationsCOunt'], () =>
    NotificationsService.find({
      query: {
        $limit: 0,
        type: { $in: preferredTypes },
        createdAt: {
          $gte: user?.notificationReadAt
            ? new Date(user.notificationReadAt)
            : new Date(),
        },
      },
    }).then((res: Paginated<AppNotification>) => res.total),
  );
}
