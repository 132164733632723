import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Table, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFillTrashFill, BsPencil } from 'react-icons/bs';
import { FiLogIn } from 'react-icons/fi';

import { CompaniesService } from '../../api/services';
import { useAuth } from '../../hooks/useAuth';
import { Company, Paginated } from '../../types';
import {
  handleSorting,
  isAdmin,
  isMasterAccount,
  setUpSorting,
} from '../../utils/index';
import CompanyEdit from './CompanyEdit';
import CompanyFilters, { CompanyListFilters } from './CompanyFilters';

const getFiltersQuery = (filters: CompanyListFilters) => {
  let query = {};
  const { searchTerm = '' } = filters;
  if (searchTerm.trim()) {
    query = Object.assign({}, query, {
      $multi_match: { $query: searchTerm.trim() },
    });
  }

  return query;
};
const defaultLimit = +(localStorage.getItem('companies-pageSize') || 50);
const CompaniesContainer = () => {
  const { t } = useTranslation();

  const auth = useAuth();

  const [state, setState] = useState({
    loading: false,
    isEditingCompany: false,
    editedCompany: {} as Company,
    filters: {} as CompanyListFilters,
    sorts: {},
  });
  const [companies, setCompanies] = useState({
    data: [],
    skip: 0,
    limit: defaultLimit,
    total: 0,
  } as Paginated<Company>);
  const { data = [], total = 0, limit = defaultLimit, skip = 0 } = companies;

  const handleClose = () =>
    setState((old) => ({
      ...old,
      isEditingCompany: false,
      editedCompany: {} as Company,
    }));
  const handleRemove = (companyId: string) => {
    setState((old) => ({ ...old, loading: true }));
    CompaniesService.remove(companyId)
      .catch((e: Error) => {
        console.log('Error in deleting company: ', e);
        message.error(t('companyPage.errDelCompany'));
      })
      .finally(() => setState((old) => ({ ...old, loading: false })));
  };
  const confirmDelete = (companyId: string) => {
    Modal.confirm({
      title: (
        <span className="font-bold">{t('companyPage.modalDelTitle')}</span>
      ),
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <div>
            {t('companyPage.modalDelInfo')}{' '}
            <strong>{t('companyPage.modalDelInfo-2')} </strong>{' '}
            {t('companyPage.modalDelInfo-3')}
          </div>
          {t('companyPage.modalDelMsg')}
        </>
      ),
      okText: t('companyPage.deleteTxt'),
      cancelText: t('companyPage.CancelTxt'),
      okButtonProps: { danger: true },
      onOk: () => handleRemove(companyId),
    });
  };
  const columns: ColumnProps<Company>[] = [
    {
      title: t('companyPage.list.table.reference#'),
      dataIndex: '_id',
    },
    {
      title: t('companyPage.list.table.businessName'),
      dataIndex: 'businessName',
      ...setUpSorting(state.sorts, 'businessName', 1),
    },
    {
      title: t('companyPage.list.table.login'),
      dataIndex: 'deleteAction',
      className: 'bg-blue-500 tw-text-center',
      render: (_, record) => (
        <Button
          type="text"
          onClick={() => {
            localStorage.setItem('view-as', record._id);
            localStorage.setItem('company-name', record.businessName);
            window.location.reload();
          }}
          hidden={!isMasterAccount(auth)}
        >
          <FiLogIn className="text-white m-delete-hover" />
        </Button>
      ),
    },
    {
      title: t('companyPage.list.table.description'),
      dataIndex: 'description',
      ...setUpSorting(state.sorts, 'description', 2),
    },
    {
      title: t('companyPage.list.table.country'),
      dataIndex: 'country',
      ...setUpSorting(state.sorts, 'country', 3),
    },
    {
      title: t('companyPage.list.table.category'),
      dataIndex: 'category',
      className: 'capitalize',
      ...setUpSorting(state.sorts, 'category', 4),
    },
    {
      title: t('companyPage.list.table.actions'),
      dataIndex: 'actions',
      width: '110px',
      render: (text, record) => (
        <div className="flex justify-end">
          <Button
            type="text"
            onClick={() =>
              setState((old) => ({
                ...old,
                isEditingCompany: true,
                editedCompany: record,
              }))
            }
          >
            <BsPencil />
          </Button>
          <Button
            type="text"
            hidden={!isAdmin(auth)}
            onClick={() => confirmDelete(record._id)}
          >
            <BsFillTrashFill />
          </Button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setState((old) => ({ ...old, loading: true }));
    const filtersQuery = getFiltersQuery(state.filters);
    const defaultSorting = { businessName: 1 };
    const $sort =
      Object.keys(state.sorts)?.length > 0 ? state.sorts : defaultSorting;
    CompaniesService.find({
      query: {
        $sort,
        $limit: limit,
        $skip: skip,
        ...filtersQuery,
      },
    })
      .then(
        (res: Paginated<Company>) => setCompanies(res),
        (e: Error) => {
          console.log('Error in fetching companies: ', { error: e });
          message.error(t('companyPage.fetchingErrCompany'));
        },
      )
      .finally(() => setState((old) => ({ ...old, loading: false })));
  }, [limit, skip, state.filters, state.sorts]);
  useEffect(() => {
    const handleCreate = (res: Company) =>
      setCompanies((old) => ({
        ...old,
        data: [res, ...old.data],
        total: old.total + 1,
      }));
    const handlePatch = (res: Company) =>
      setCompanies((old) => ({
        ...old,
        data: old.data.map((item) => (item._id === res._id ? res : item)),
      }));

    const handleRemove = (res: Company) =>
      setCompanies((old) => ({
        ...old,
        data: old.data.filter((item) => item._id !== res._id),
      }));

    CompaniesService.on('created', handleCreate);
    CompaniesService.on('updated', handlePatch);
    CompaniesService.on('patched', handlePatch);
    CompaniesService.on('removed', handleRemove);
    return () => {
      CompaniesService.off('created', handleCreate);
      CompaniesService.off('updated', handlePatch);
      CompaniesService.off('patched', handlePatch);
      CompaniesService.off('removed', handleRemove);
    };
  }, []);

  return (
    <div className="p-4 bg-white">
      <header className="mb-4">
        <div className="flex items-center justify-between">
          <div className="mb-2 font-sans text-2xl font-bold md:text-3xl">
            {t('companyPage.list.companies')}
          </div>
          <Button
            type="primary"
            onClick={() =>
              setState((old) => ({
                ...old,
                isEditingCompany: true,
                editedCompany: {} as Company,
              }))
            }
            className="font-bold text-white"
          >
            {t('companyPage.list.btnText')}
          </Button>
        </div>
        <CompanyFilters
          filters={state.filters}
          setFilters={(filters) => setState((old) => ({ ...old, filters }))}
        />
      </header>
      <main>
        <Table
          rowKey="_id"
          columns={columns}
          dataSource={data}
          pagination={{
            total,
            pageSize: limit,
            current: skip / limit + 1,
            showSizeChanger: true,
            pageSizeOptions: ['50', '100', '250', '500'],
            onShowSizeChange: (page, size = limit) => {
              localStorage.setItem('companies-pageSize', size.toString());
              setCompanies((old) => ({
                ...old,
                skip: (page - 1) * size,
                limit: size,
              }));
            },
            onChange: (page, size = limit) =>
              setCompanies((old) => ({
                ...old,
                skip: (page - 1) * size,
                limit: size,
              })),
          }}
          onChange={(a, b, sort) =>
            handleSorting(
              sort as SorterResult<Company>[],
              state.sorts,
              (sorts) => setState((old) => ({ ...old, sorts })),
            )
          }
          loading={state.loading}
          scroll={{ x: true }}
        />
      </main>

      <CompanyEdit
        editedRecord={state.editedCompany}
        isEditing={state.isEditingCompany}
        handleClose={handleClose}
      />
    </div>
  );
};

export default CompaniesContainer;
