import { NotificationType } from '../types';

export const getNotificationTitle = (type: NotificationType) => {
  switch (type) {
    case 'mention':
      return 'Mentioned';
    case 'packaging':
      return 'Packaging updated';
    case 'bulkPackaging':
      return 'Bulk packaging updated';
    case 'product':
      return 'Product updated';
    case 'newLogin':
      return 'New login';
    case 'userRole':
      return 'User role updated';
  }
};
