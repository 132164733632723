import { Button, Col, Form, Input, Row, message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';

import { UserAccountService } from '../../api/services';
import logo from '../../assets/logo/zwc_logo.svg';
import { useAuth } from '../../hooks/useAuth';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const auth = useAuth();

  if (auth.isLoggedIn) {
    return <Redirect to="/" />;
  }

  const handleSubmit = (values: { email: string }) => {
    const { email } = values;
    setIsLoading(true);
    UserAccountService.create({
      action: 'forgotPassword',
      email,
    })
      .then(
        () => {
          message.success(`${t('forgetPassword.message.success')}: ${email}`);
        },
        (error: Error) => {
          message.error(t('forgetPassword.message.error'));
          console.log('Error in sending password reset email: ', error);
        },
      )
      .finally(() => setIsLoading(false));
  };

  return (
    <div className="relative min-h-screen">
      <Row align="middle" justify="center" style={{ height: '100%' }}>
        <Col span={24}>
          <Row justify="center">
            <Col
              xs={23}
              md={18}
              xl={16}
              style={{ margin: '2rem 0', textAlign: 'center' }}
            >
              <Row align="middle">
                <Col span={4}>
                  <Link to="/">
                    <img
                      src={logo}
                      alt="ZWC Logo"
                      width="100px"
                      height="100px"
                    />
                  </Link>
                </Col>
                <Col span={20}>
                  <h1
                    className="font-sans font-bold uppercase sm:text-base md:text-2xl lg:text-3xl"
                    style={{ color: '#5d96e9' }}
                  >
                    {t('forgetPassword.title')}
                  </h1>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={20} md={12} xl={7}>
              <div
                className="p-4 my-10 text-white rounded"
                style={{
                  backgroundColor: '#6ac7bd',
                  boxShadow:
                    'rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px',
                }}
              >
                <h1 className="mb-4 text-3xl font-bold text-center text-white">
                  {t('forgetPassword.form.title')}
                </h1>
                <Form
                  onFinish={handleSubmit}
                  layout="vertical"
                  requiredMark={false}
                >
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Email is required' }]}
                    label={
                      <span className="font-semibold tracking-wider text-white">
                        {t('forgetPassword.form.email')}
                      </span>
                    }
                  >
                    <Input
                      type="email"
                      placeholder={t('forgetPassword.form.emailPlaceholder')}
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="mt-4 font-bold uppercase"
                    loading={isLoading}
                    disabled={isLoading}
                    block
                  >
                    {t('forgetPassword.form.btnText')}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        style={{
          bottom: '10px',
          position: 'absolute',
          color: '#989898',
          width: '100%',
        }}
        justify="center"
      >
        <Col xs={8} md={4} lg={2}>
          &copy; ZWC {new Date().getFullYear()}
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
