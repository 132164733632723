import { Comment, Tooltip } from 'antd';
import moment from 'moment';

import useAllUsers from '../../../../../hooks/useAllUsers';
import { PackagingComment } from '../../../../../types';
import { findAndReplaceMentions } from '../../../../../utils/findAndReplaceMentions';
import { getFullName } from '../../../../Packaging/getFullName';

const CommentItem = ({ data }: { data: PackagingComment }) => {
  const { text, createdAt, userId } = data;
  const { usersById } = useAllUsers();
  return (
    <Comment
      author={usersById[userId] ? getFullName(usersById[userId]) : userId}
      content={
        <p className="-my-1">{findAndReplaceMentions(text, usersById)}</p>
      }
      className="mt-2 remove-padding"
      datetime={
        <Tooltip title={moment(createdAt).fromNow()}>
          <span>{moment(createdAt).format('DD MMM YYYY HH:mm')}</span>
        </Tooltip>
      }
    />
  );
};

export default CommentItem;
