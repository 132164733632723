import client from './client';

export const USERS_SERVICE_PATH = 'users';
export const COMPANIES_SERVICE_PATH = 'companies';
export const USER_ACCOUNT_SERVICE_PATH = 'user/account';
export const EXPORT_SERVICE_PATH = 'data-export';
export const ASSESSMENT_METHODS_SERVICE_PATH = 'assessment-methods';
export const MaterialItems = 'material-items';
export const PACKAGINGS_SERVICE_PATH = 'packagings';
export const PACKAGING_ITEMS_SERVICE_PATH = 'packaging/items';
export const AUDIT_TRAIL_SERVICE_PATH = 'packaging/activities';
export const PACKAGING_LINK_SERVICE_PATH = 'product/packagings';
export const PRODUCTS_SERVICE_PATH = 'products';
export const PACKAGING_COMMENTS_SERVICE_PATH = 'packaging/comments';
export const DASHBOARD_REPORTING_SERVICE_PATH = 'dashboard';
export const REPORTING_PERIODS_SERVICE_PATH = 'reporting-periods';
export const USER_REPORTING_PERIODS_SERVICE_PATH = 'user/reporting-periods';
export const UserAccountService = client.service(USER_ACCOUNT_SERVICE_PATH);

export const UsersService = client.service(USERS_SERVICE_PATH);
export const CompaniesService = client.service(COMPANIES_SERVICE_PATH);
export const NOTIFICATIONS_SERVICE_PATH = 'notifications';
export const ProductsService = client.service(PRODUCTS_SERVICE_PATH);
export const ExportService = client.service(EXPORT_SERVICE_PATH);
export const AssessmentMethodsService = client.service(
  ASSESSMENT_METHODS_SERVICE_PATH,
);
export const MaterialItemsService = client.service(MaterialItems);
export const SUPPLIERS_SERVICE_PATH = 'suppliers';
export const PackagingsService = client.service(PACKAGINGS_SERVICE_PATH);
export const PackagingItemsService = client.service(
  PACKAGING_ITEMS_SERVICE_PATH,
);
export const AuditTrailService = client.service(AUDIT_TRAIL_SERVICE_PATH);
export const PackagingLinkService = client.service(PACKAGING_LINK_SERVICE_PATH);
export const DashboardReportingService = client.service(
  DASHBOARD_REPORTING_SERVICE_PATH,
);
export const PackagingCommentsService = client.service(
  PACKAGING_COMMENTS_SERVICE_PATH,
);
export const ReportingPeriodsService = client.service(
  REPORTING_PERIODS_SERVICE_PATH,
);

export const UserReportingPeriodsService = client.service(
  USER_REPORTING_PERIODS_SERVICE_PATH,
);

export const NotificationsService = client.service(NOTIFICATIONS_SERVICE_PATH);
