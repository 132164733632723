import { Paginated } from '@feathersjs/feathers';
import { Button, Col, Form, Input, InputNumber, Row } from 'antd';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductsService } from '../../api/services';
import { Product } from '../../types';
import { CustomAutoComplete } from './CustomAutoComplete';

export interface ProductListFilters {
  ourSupplier: string;
  packagingGroup: string;
  packagingsCount: number;
  searchTerm: string;
}
export const areProductFiltersEmpty = (
  {
    ourSupplier,
    packagingsCount,
    packagingGroup,
    searchTerm,
  } = {} as ProductListFilters,
) => {
  return (
    !ourSupplier &&
    !packagingGroup &&
    !searchTerm &&
    (packagingsCount === null || packagingsCount === undefined)
  );
};
interface ProductFiltersProps {
  filters: ProductListFilters;
  selectAll: boolean;
  setFilters: (filters: ProductListFilters) => void;
  onSelectAll: () => void;
  selectionCount: number;
  className?: string;
  showFilter?: boolean;
  setShowFilter?: any;
}

function findProducts({ queryKey }: any) {
  const [, searchTerm] = queryKey;
  return ProductsService.find({
    query: { $multi_match: { $query: '' }, ourSupplier: searchTerm },
  });
}

const ProductFilters = ({
  filters,
  setFilters,
  selectAll,
  selectionCount,
  className = 'py-1 mt-1 border-t border-b',
  onSelectAll,
  showFilter,
  setShowFilter,
}: ProductFiltersProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ProductListFilters>();

  const clearFilters = () => {
    form.resetFields();
    setFilters({} as ProductListFilters);
  };
  useEffect(() => {
    const { ourSupplier, packagingGroup, packagingsCount, searchTerm } =
      filters;
    form.setFieldsValue({
      ourSupplier,
      packagingGroup,
      packagingsCount,
      searchTerm,
    });
  }, [filters]);

  const queryFn = (args: any) => {
    return findProducts(args)
      .then((res: Paginated<Product>) => res.data)
      .then((products: Array<Product>) => {
        return products.map((item: Product) => ({
          id: item.internalFields.supplier,
          value: item.internalFields.supplier,
        }));
      });
  };

  return (
    <div className={className}>
      <Form
        form={form}
        onValuesChange={debounce(
          (change) => setFilters({ ...filters, ...change }),
          300,
        )}
        layout="horizontal"
      >
        <Row gutter={8}>
          <Col span={4}>
            <Form.Item className="label-mb-0" name="searchTerm">
              <Input
                placeholder={t('productPage.productFilters.search')}
                allowClear
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item className="label-mb-0" name="ourSupplier">
              <CustomAutoComplete
                placeholder={t('productPage.productFilters.searchBySupplier')}
                queryFn={queryFn}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item className="label-mb-0" name="packagingGroup">
              <Input
                placeholder={t('productPage.productFilters.searchByPkgGroup')}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item className="label-mb-0" name="packagingsCount">
              <InputNumber
                style={{ width: '100%' }}
                placeholder={t(
                  'productPage.productFilters.searchByPackageCount',
                )}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Button
              ghost={showFilter}
              type="primary"
              className="mr-2"
              onClick={() => setShowFilter(false)}
            >
              Hide
            </Button>
            <Button
              type="primary"
              onClick={clearFilters}
              disabled={areProductFiltersEmpty(filters)}
              className="mr-2"
              danger
            >
              {t('productPage.productFilters.clearFilters')}
            </Button>
            <Button type="primary" onClick={onSelectAll} ghost={!selectAll}>
              {selectAll
                ? t('productPage.productFilters.unSelectAll')
                : t('productPage.productFilters.selectAll')}{' '}
              &nbsp;
              {selectionCount}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ProductFilters;
